import i18n from "../../../i18n";

/**
 * Init the location map for walkways definition mode
 * @param self
 */
export function initWalkways(self) {

    console.log("Component(LocationMap)::initWalkways()) - called");

    self.initActionsBar(["centerMapButton"]);
    self.setMapTilesLayer("OpenStreetMap");
    self.initDrawControl();
    // Force set walkways displayed because if we are in the walkways page
    self.setWalkwaysLayerDisplayed(true);

    // Hide icons/buttons automatically added by Leaflet Draw library
    $(".leaflet-draw").hide();

    // Manage Escape button for cancelling creation/edition of walkway
    let onKeyDown = e => {
        if (e.keyCode == 27) {
            // esc button
            if (self.currentWalkway.state === "START_CREATION") {
                self.setCurrentWalkway({
                    id: "",
                    state: "CANCEL_CREATION"
                });
            } else if (self.currentWalkway.state === "EDIT") {
                self.setCurrentWalkway({
                    id: self.currentWalkway.id,
                    state: "CANCEL_EDIT"
                });
            } else if (self.currentWalkway.state === "FINALIZE_EDITION") {
                self.setCurrentWalkway({
                    id: "",
                    state: "CANCEL_EDIT"
                });
            } else if (self.currentWalkway.state === "START_MEASURE") {
                self.setCurrentWalkway({
                    id: "",
                    state: "CANCEL_MEASURE"
                });
            }
        }
    };
    L.DomEvent.addListener(document, "keydown", onKeyDown, self.leafletMap);
}

/**
 * Method called to init draw controls that will be used at walkway creation / edition
 * @param self
 */
export function initDrawControlForWalkway(self) {
    var options = {
        position: "topleft",
        draw: {
            polygon: {
                allowIntersection: false, // Restricts shapes to simple polygons
                drawError: {
                    color: "#e1e100", // Color the shape will turn when intersects
                    message: i18n.t("area_drawingIntersectionsError") // Message that will show when intersect
                },
                shapeOptions: {
                    dashArray: "10, 10",
                    fill: true,
                    fillColor: "#fe57a1",
                    fillOpacity: 0.1,
                    maintainColor: false
                }
            },
            polyline: false,
            circle: false,
            rectangle: false,
            marker: false,
            circlemarker: false
        },
        edit: {
            featureGroup: self.editableLayers,
            remove: false
        }
    };

    return options;
}

export function registerEvents(self) {
    // This listener is only used when user has clicke don start measure button
    self.leafletMap.on(L.Draw.Event.DRAWSTART, e => {
        if (self.currentWalkway.state !== "START_CREATION") {
            if (e && e.layerType && e.layerType === "polyline") {
                self.setCurrentWalkway({ id: "", state: "START_MEASURE" });
            }
        }
    });

    self.leafletMap.on(L.Draw.Event.CREATED, e => {
        // First check that drawn polygon is not covering another existing walkway
        let geometry = e.layer.toGeoJSON().geometry;
        let distance = Math.round(Math.round(L.GeometryUtil.length(e.layer.getLatLngs()) * 1000) / 1000);
        var walkwayData = {
            id: self.currentWalkway.id,
            geometry: geometry,
            distance: distance,
            state: "SAVE_CREATION"
        };
        self.setCurrentWalkway(walkwayData);
    });
}

/**
 * Method called to do specific actions following the current state of currentWalkway
 * @param self
 * @param action
 */
export function updateCurrentWalkway(self, walkway) {
    if (!walkway.state) {
        // show again measure control
        self.showMeasureControl();
        resetAllLayersToDefaultColor(self);
        if (self.leafletMap) {
            self.leafletMap.closePopup();
        }
    } else {
        if (walkway.state === "START_CREATION") {
            startWalkwayCreation(self);
            self.hideMeasureControl();
        } else if (walkway.state === "SAVE_CREATION") {
            saveNewWalkwayAction(self);
        } else if (walkway.state === "CANCEL_CREATION") {
            cancelNewWalkwayAction(self);
        } else if (walkway.state === "EDIT") {
            startEditWalkwayAction(self);
            self.hideMeasureControl();
        } else if (walkway.state === "SAVE_EDIT") {
            saveEditWalkwayAction(self);
        } else if (walkway.state === "CANCEL_EDIT") {
            cancelEditWalkwayAction(self);
        } else if (walkway.state === "HIGHLIGHT") {
            highlightWalkwayAction(self);
        } else if (walkway.state === "START_MEASURE") {
            startMeasureAction(self);
        } else if (walkway.state === "CANCEL_MEASURE") {
            cancelMeasureAction(self);
        }
    }
}

/**
 * Method called when user start measure action
 * @param self
 */
export function startMeasureAction(self) {
    self.hideMeasureControl();
}

/**
 * Method called when user cancel measure action
 * @param self
 */
export function cancelMeasureAction(self) {
    if (self.measureHandler && self.measureHandler.handler) {
        self.measureHandler.handler.disable();
    }
    self.showMeasureControl();
    self.setCurrentWalkway({
        id: "",
        state: ""
    });
}

/**
 * Method called at page loading or floor selection, to display all walkways of the selected floor
 * @param self
 * @param action
 */
export function generateWalkwaysLayer(self) {
    // If walkwaysLayer is already defined, clear all layers already existing inside
    if (self.walkwaysLayer) {
        self.walkwaysLayer.clearLayers();
    } else {
        self.walkwaysLayer = new L.FeatureGroup();
    }

    // Get current selected building and floor id
    let currentSelectedBuildingId = self.getSelectedBuildingId;
    let currentSelectedFloorId = self.getSelectedFloorId;

    // Then, for each walkway, create a polyline layer and add it to the global walkways layer
    _.forEach(self.siteWalkways, function(walkway) {
        // Display polylines only for walkways that are actually on the selected building and floor
        if (walkway.building.id === currentSelectedBuildingId && walkway.floor.id === currentSelectedFloorId) {
            // Convert geoJson coordinates to leaflet polyline format
            let polylineCoords = [];
            for (var i = 0; i < walkway.geometry.coordinates.length; i++) {
                polylineCoords.push([walkway.geometry.coordinates[i][1], walkway.geometry.coordinates[i][0]]);
            }

            let walkwayPolyline = L.polyline(polylineCoords);

            // Add popup and tooltip to generated polyline
            let areaUnit = walkway.distanceUnit === "meters" ? "m" : "ft";
            var popup = new L.Popup().setContent("<strong>" + walkway.name + "</strong><br>" + walkway.distance + " " + areaUnit + '<br><span style="color: #999"></span>');
            walkwayPolyline.bindPopup(popup);
            walkwayPolyline.bindTooltip(walkway.name, { className: "customTooltipWalkwayClass", permanent: true, direction: "center" });

            // Define click listener on area and change color of this area when user click on it
            registerClickEventsOnWalkwayPolyline(self, walkwayPolyline);

            // This line is important: we are setting the id of the walkway polyline layer using walkway id.
            // It will be usefull later to find a layer by id.
            walkwayPolyline.options.id = walkway.id;

            // Define polyline display color
            let polylineColor = "#3388ff";
            if (walkway.color) {
                polylineColor = "#" + walkway.color;
            }
            walkwayPolyline.setStyle({
                color: polylineColor,
                fillColor: polylineColor,
                className: "walkway_" + walkway.id
            });

            self.walkwaysLayer.addLayer(walkwayPolyline);
        }
    });
}

/*
 * Function used to reset all markers icon to default icon
 */
function resetAllLayersToDefaultColor(self) {
    if (self.walkwaysLayer) {
        // Reset all areas to their original color
        for (var i in self.walkwaysLayer._layers) {
            if (self.walkwaysLayer._layers.hasOwnProperty(i)) {
                var currentWalkwayPolyline = self.walkwaysLayer._layers[i];
                // Get style from store
                var walkwayId = currentWalkwayPolyline.options.id;
                var walkwayInStore = self.getWalkwayById(walkwayId);
                if (walkwayInStore && walkwayInStore.color) {
                    currentWalkwayPolyline.setStyle({
                        color: "#" + walkwayInStore.color,
                        fillColor: "#" + walkwayInStore.color
                    });
                }
            }
        }
    }
}

/*
 * Function used to highlight one area layer on the map
 */
function highlightArea(areaLayer) {
    areaLayer.setStyle({
        color: "#734abd",
        fillColor: "#734abd"
    });
}

/*
 * Function used to register click on area polygon
 */
function registerClickEventsOnWalkwayPolyline(self, walkwayPolyline) {
    // Register click event on area layer
    walkwayPolyline.on("click", function(e) {
        // Reset all area layers
        resetAllLayersToDefaultColor(self);
        // Set selected area
        var walkwayId = e.target.options.id;
        self.setCurrentWalkway({ id: walkwayId, state: "HIGHLIGHT", isHighlightFromMap: true });
        // Highlight the selected one
        var areaLayer = e.target;
        highlightArea(areaLayer);
    });

    // Register click event on close popup
    walkwayPolyline.getPopup().on("remove", () => {
        if (self.leafletMap.hasLayer(walkwayPolyline) && self.currentWalkway.state === "HIGHLIGHT") {
            // Reset selected area to empty
            self.setCurrentWalkway({ id: "", state: "" });
        }
        // Reset default color only if the marker is always on the map
        // It could be deleted if the remove event has been fired after delete location
        if (self.leafletMap.hasLayer(walkwayPolyline)) {
            var walkwayId = walkwayPolyline.options.id;
            var walkwayInStore = self.getWalkwayById(walkwayId);
            walkwayPolyline.setStyle({
                color: "#" + walkwayInStore.color,
                fillColor: "#" + walkwayInStore.color
            });
        }
    });

    // Register click event on popup open to force again selection of area
    walkwayPolyline.on("popupopen", e => {
        // Set selected area
        var walkwayId = e.target.options.id;
        self.setCurrentWalkway({ id: walkwayId, state: "HIGHLIGHT" });
    });
}

export function showOrHideWalkwaysLayer(self) {

    console.log("Component(LocationMap)::showOrHideWalkwaysLayer()) - called");

    if (self.leafletMap && self.walkwaysLayer) {
        if (self.isWalkwaysLayerDisplayed) {
            self.leafletMap.addLayer(self.walkwaysLayer);
        } else {
            self.leafletMap.removeLayer(self.walkwaysLayer);
        }
    }
}

/**
 * Method called to start creation of an walkway on the map
 * @param self
 */
export function startWalkwayCreation(self) {
    console.log("Component(LocationMap)::startWalkwayCreation()) - Start drawing polygon !");

    // Close all opened popups on the map
    self.hideAllPopupOnMap();

    self.drawHandler = new L.Draw.Polyline(self.leafletMap, self.drawControl.options.draw.polyline);
    self.drawHandler.enable();
}

/**
 * Method called to save new walkway on the map
 * @param self
 */
export function saveNewWalkwayAction(self) {
    console.log("Component(LocationMap)::saveNewWalkwayAction()) - Start saving new walkway !");
    let walkwayData = {
        id: self.currentWalkway.id,
        name: "",
        color: "3388ff", // blue by default
        siteId: self.siteId,
        buildingId: self.getSelectedBuildingId,
        floorId: self.getSelectedFloorId,
        geometry: self.currentWalkway.geometry,
        distance: self.currentWalkway.distance,
        distanceUnit: "meters",
        state: "FINALIZE_CREATION"
    };

    // Will open confirmation pop-up from walkwayModal.vue component
    if (self.isInFullscreen === true) {
        self.quitFullscreenButton();
    }
    self.setCurrentWalkway(walkwayData);
}

/**
 * Method called to cancel new walkway on the map
 * @param self
 */
export function cancelNewWalkwayAction(self) {
    console.log("Component(LocationMap)::cancelNewWalkwayAction()) - Cancelling new walkway creation !");

    self.setCurrentWalkway({
        id: "",
        state: ""
    });

    self.drawHandler.disable();
    self.drawHandler = null;

    // Show again floor selector on the map
    self.showFloorMapSelector();
}

/**
 * Method called to start edit of an autocalibration tag on the map
 * @param self
 */
export function startEditWalkwayAction(self) {
    console.log("Component(LocationMap)::startEditWalkwayAction()) - Start editing walkway !");

    // Get full walkway object
    let walkwayObject = self.getWalkwayById(self.currentWalkway.id);
    // Hide floor selector on the map
    self.hideFloorMapSelector();
    // Close all opened popups on the map
    self.hideAllPopupOnMap();
    // Check if the walkway is located on the current selected floor
    if (walkwayObject.building.id === self.getSelectedBuildingId && walkwayObject.floor.id === self.getSelectedFloorId) {
        // Get layer of selected walkway by id
        let currentEditedWalkwayLayer = self.walkwaysLayer.getLayerById(self.currentWalkway.id);
        // Center map on highlighted walkway
        self.centerMapOnPolygon(currentEditedWalkwayLayer);
        // First remove all layers in editable Layers because we are able to edit one layer once a time
        self.editableLayers.clearLayers();
        // Add only selected AutoCalibrationTag layer to the list of edited layers
        self.editableLayers.addLayer(currentEditedWalkwayLayer);
        // Force activation of edit mode now ! Only layers available in the editableLayers array will now goes in edit mode in location map.
        self.editHandler.enable();
    } else {
        // We need to switch to the good floor and then edit the walkway
        self.handleSelectFloorMapById(walkwayObject.floor.id);
        // No need to implement callback here,just wait few ms before leaflet update the map.
        setTimeout(() => {
            startEditWalkwayAction(self);
        }, 800);
    }
}

/**
 * Method called to save edit of a walkway on the map
 * @param self
 */
export function saveEditWalkwayAction(self) {
    console.log("Component(LocationMap)::saveEditWalkwayAction()) - Start saving edited walkway !");

    // Get last version of walkway
    let walkway = self.getWalkwayById(self.currentWalkway.id);

    // Generate polygon and area from edited Leaflet layer
    let currentEditedWalkwayLayer = self.walkwaysLayer.getLayerById(self.currentWalkway.id);
    let geometry = currentEditedWalkwayLayer.toGeoJSON().geometry;
    let distance = Math.round(Math.round(L.GeometryUtil.length(currentEditedWalkwayLayer.getLatLngs()) * 1000) / 1000);

    var walkwayData = {
        id: walkway.id,
        name: walkway.name,
        color: walkway.color,
        siteId: walkway.site,
        buildingId: walkway.building.id,
        floorId: walkway.floor.id,
        geometry: geometry,
        distance: distance,
        distanceUnit: "meters",
        state: "FINALIZE_EDITION"
    };

    // Will open confirmation pop-up from walkwayModal.vue component
    self.setCurrentWalkway(walkwayData);

    self.editHandler.disable();
    // Show again floor selector on the map
    self.showFloorMapSelector();
}

/**
 * Method called to cancel edition of an walkway on the map
 * @param self
 */
export function cancelEditWalkwayAction(self) {
    self.editableLayers.clearLayers();
    // revert layers
    self.editHandler.revertLayers();
    // disable layer editing
    self.editHandler.disable();
    // reinit of current walkway
    self.setCurrentWalkway({ id: "", state: "" });
    // CTC-538 Display initial walkways of the selected floor
    generateWalkwaysLayer(self);
    // Show again floor selector on the map
    self.showFloorMapSelector();
    // Display back the edited walkway
    showOrHideWalkwaysLayer(self);
}

/**
 * Method called to highlight walkway on the map
 * @param self
 */
export function highlightWalkwayAction(self) {
    console.log("Component(LocationMap)::highlightWalkwayAction()) - Highlight walkway !");

    // Defense: If user is currently creating a walkway and click on another one into the list,
    // the highlight feature will be called. In that case, we need to disable the draw handler.
    if (self.drawHandler) {
        self.drawHandler.disable();
        self.drawHandler = null;
    }

    // Defense: If user is currently measuring a distance and click to highlight an area
    if (self.measureHandler && self.measureHandler.handler) {
        self.measureHandler.handler.disable();
    }

    // Defense: If user is currently updating a polygon of an existing walkway and click on another one into the list,
    // the highlight feature will be called. In that case, we need to disable the edit draw handler.
    if (self.editHandler) {
        // revert layers
        self.editHandler.revertLayers();
        // disable layer editing
        self.editHandler.disable();
        // Show again the measure control
        self.showMeasureControl();
    }

    let walkwayObject = self.getWalkwayById(self.currentWalkway.id);
    // Reset all markers icons to default one
    resetAllLayersToDefaultColor(self);
    // Check if the walkway is located on the current selected floor
    if (walkwayObject.building.id === self.getSelectedBuildingId && walkwayObject.floor.id === self.getSelectedFloorId) {
        // Map currently display the good floor, we just need to highlight the walkway
        // Get layer of selected walkway by id
        let walkwayLayer = self.walkwaysLayer.getLayerById(self.currentWalkway.id);
        walkwayLayer.openPopup();
        // Highlight selected area
        highlightArea(walkwayLayer);
        // Center map on highlighted area
        self.centerMapOnPolygon(walkwayLayer);
    } else {
        // We need to switch to the good floor and then highlight the walkway
        self.handleSelectFloorMapById(walkwayObject.floor.id);
        // No need to implement callback here,just wait few ms before leaflet update the map.
        setTimeout(() => {
            let walkwayLayer = self.walkwaysLayer.getLayerById(self.currentWalkway.id);
            walkwayLayer.openPopup();
            // Highlight selected area
            highlightArea(walkwayLayer);
            // Center map on highlighted area
            self.centerMapOnPolygon(walkwayLayer);
        }, 800);
    }
}
