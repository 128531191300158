<template>
    <div>
        <div id="map" class="mapDiv leaflet-touch" :class="mapClass">
            <div v-if="this.distanceBetweenLastAddedObjectAndCurrentPoint && this.lastAddedObjectType === 'GATEWAY'" class="lastAddedBloc">{{ $t("common_distanceFromLastGateway") }} ({{ this.lastAddedObjectName }}): <b>{{ this.distanceBetweenLastAddedObjectAndCurrentPoint }}m</b></div>
            <div v-else-if="this.distanceBetweenLastAddedObjectAndCurrentPoint && this.lastAddedObjectType === 'ACTAG'" class="lastAddedBloc">{{ $t("common_distanceFromLastAct") }} ({{ this.lastAddedObjectName }}): <b>{{ this.distanceBetweenLastAddedObjectAndCurrentPoint }}m</b></div>
            <app-mapalert v-bind="displayMapAlertWindow"></app-mapalert>
            <button v-show="isInFullscreen && mode === 'floorMapping'" @click="quitFullscreenButton" type="button" class="finishButtonFullscreen btn btn-lg btn-brand btn-lg">
                <i class="fa fa-check"></i> Finish
            </button>
            <app-mapsidebar :mode="this.mode" v-if="(this.mode === 'assetsTracking' || this.mode === 'assetlocationhistory' || this.mode==='troubleshootingHeatmaps') && this.isMapSideBarDisplayed"></app-mapsidebar>
            <app-mapsideswitch :mode="this.mode" v-if="(this.mode === 'wallsDefinition' || this.mode === 'indoorAreasDefinition' || this.mode === 'geofencesDefinition' || this.mode === 'gatewaysLocation' || this.mode === 'autocalibrationtagsLocation')"></app-mapsideswitch>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import L from "leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import {} from "leaflet-imageoverlay-rotated";
import {} from "leaflet-search";
import {} from "leaflet.fullscreen";
import {} from "leaflet-draw";
import {} from "leaflet-measure";
import {} from "leaflet.markercluster"
import {} from 'leaflet.markercluster.layersupport';
import {} from "leaflet-geometryutil";
import lookup from "country-code-lookup";
import drawLocales from "leaflet-draw-locales";
import * as floorsUtil from "./js/floors.js";
import * as autocalibrationTagsUtil from "./js/autocalibrationtags.js";
import * as gatewaysUtil from "./js/gateways.js";
import * as indoorAreasUtil from "./js/indoorareas.js";
import * as geofencesUtil from "./js/geofences.js";
import * as walkwaysUtil from "./js/walkways.js";
import * as wallsUtil from "./js/walls.js";
import * as assetsTrackingUtil from "./js/assetstracking.js";
import * as analyticsUtil from "./js/analyticsheatmap.js";
import * as troubleshootingHeatmapsUtil from "./js/troubleshootingheatmap.js";
import mapSideBar from "./sidebar/mapsidebar.vue";
import mapSideSwitch from "./switch/mapsideswitch.vue";
import mapAlert from "./alert/mapalert.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
const _countries = require("../../constants/countrygeo");

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            isMapSideBarDisplayed: false,
            mapOffsetFromTop: {
                siteLocation: 130,
                others: 20
            },
            leafletMap: null,
            currentMapLayer: null,
            mapOptions: {
                attributionControl: false
            },
            mapInitialized: false,
            tileLayer: null,
            circle: null,
            defaultLatitude: 26.689183497156236,
            defaultLongitude: -96.46957397460938,
            defaultZoom: 2,
            countriesByAlpha2: _.keyBy(_countries.countries, "country"),
            floorImageOverlayOpacity: 50,
            markers: [],
            floorImageOverlay: null,
            displayMapAlertWindow: { visible: false },
            isInFullscreen: false,
            displayedFloorsMapLayers: [],
            drawControl: null,
            drawHandler: null,
            measureHandler: null,
            editHandler: null,
            availableActions: {
                centerMapButton: false,
                opacitySlider: false
            },
            gatewaysLayer: null,
            autocalibrationtagsLayer: null,
            autocalibrationtagsCircleRadiusLayer: null,
            indoorAreasLayer: null,
            geofencesLayer: null,
            assetsHeatmapLayer: null,
            troubleshootHeatmapLayer: null,
            walkwaysLayer: null,
            wallsLayer: null,
            editableLayers: null,
            assetsLayer: null,
            backgroundImageLayer: null,
            isLeafletControlMouseOver: false,
            currentHeatmapZoomLevel: null,
            assetsShowLimit: 200,
            viewAssets: [],
            showInClusterMode: false,
            withScreen: 0,
        };
    },
    created: function() {
        console.log("Component(LocationMap)::created() - called");
        this.resetGatewaysLayerDisplayed();
        this.resetHeatmapsLayerDisplayed();
        this.resetAutocalibrationTagsLayerDisplayed();
        this.resetAssetsLayerDisplayed();
        this.resetIndoorAreasLayerDisplayed();
        this.resetGeofencesLayerDisplayed();

        if (this.mode === "assetsTracking") {
            // When asset tracking dashboard is created, check if there is preferences already saved in local storage.
            // It is usefull to address the case where user has refreshed the page and wants to have the same data when he display the asset tracking dashboard again.
            this.clearAllActiveFilters();
            this.getDashboardPreferencesFromLocalStorage(this.siteId);
        }

        this.setMapMode(this.mode);
    },
    mounted: function() {
        console.log("Component(LocationMap)::mounted() - Init metronic layout");
        // Init Metronic layout
        KTLayout.init();
        // Load site
        this.getSiteById(this.siteId);
        // Init Leaflet Draw locales
        if (this.user) {
            drawLocales(this.user.preferredLanguage);
        }
        // Init tooltips on page
        $('[data-toggle="kt-tooltip"]').tooltip();
        if (this.mode === "assetsTracking") {
            this.withScreen = $(window).width();
            window.addEventListener("resize", this.onResize);
        }
    },
    beforeDestroy() {
        console.log("Component(LocationMap)::beforeDestroy() - called");
        this.drawControl = null;
        this.drawHandler = null;
        this.measureHandler = null;
        this.editHandler = null;
        this.markers = [];
        this.mapOptions = {}
        window.leafletMap = null;
    },
    destroyed: function() {
        console.log("Component(LocationMap)::destroyed() - called");
        // Reset location when map is destroyed
        this.notifyLocation(0.01, 0.01, null);
        this.setHasMovedFromInitialInit(false);
        this.setCurrentSelectedBuildingId("");
        this.setCurrentSelectedFloorId("");
        this.currentHeatmapZoomLevel = null;
        window.removeEventListener("resize", this.onResize);

        if (this.leafletMap){
            if (this.tileLayer){
                this.leafletMap.removeLayer(this.tileLayer);
                this.tileLayer = null;
            }
            if (this.circle){
                this.leafletMap.removeLayer(this.circle);
                this.circle = null;
            }
            if (this.floorImageOverlay){
                this.leafletMap.removeLayer(this.floorImageOverlay);
                this.floorImageOverlay = null;
            }
            if (this.currentMapLayer){
                this.leafletMap.removeLayer(this.currentMapLayer);
                this.currentMapLayer = null;
            }
            if (this.gatewaysLayer){
                this.leafletMap.removeLayer(this.gatewaysLayer);
                this.gatewaysLayer = null;
            }
            if (this.autocalibrationtagsLayer){
                this.leafletMap.removeLayer(this.autocalibrationtagsLayer);
                this.autocalibrationtagsLayer = null;
            }
            if (this.autocalibrationtagsCircleRadiusLayer){
                this.leafletMap.removeLayer(this.autocalibrationtagsCircleRadiusLayer);
                this.autocalibrationtagsCircleRadiusLayer = null;
            }
            if (this.indoorAreasLayer){
                this.leafletMap.removeLayer(this.indoorAreasLayer);
                this.indoorAreasLayer = null;
            }
            if (this.geofencesLayer){
                this.leafletMap.removeLayer(this.geofencesLayer);
                this.geofencesLayer = null;
            }
            if (this.assetsHeatmapLayer){
                this.leafletMap.removeLayer(this.assetsHeatmapLayer);
                this.assetsHeatmapLayer = null;
            }
            if (this.troubleshootHeatmapLayer){
                this.leafletMap.removeLayer(this.troubleshootHeatmapLayer);
                this.troubleshootHeatmapLayer = null;
            }
            if (this.walkwaysLayer){
                this.leafletMap.removeLayer(this.walkwaysLayer);
                this.walkwaysLayer = null;
            }
            if (this.wallsLayer){
                this.leafletMap.removeLayer(this.wallsLayer);
                this.wallsLayer = null;
            }
            if (this.editableLayers){
                this.leafletMap.removeLayer(this.editableLayers);
                this.editableLayers = null;
            }
            if (this.assetsLayer){
                this.leafletMap.removeLayer(this.assetsLayer);
                this.assetsLayer = null;
            }
            if (this.backgroundImageLayer){
                this.leafletMap.removeLayer(this.backgroundImageLayer);
                this.backgroundImageLayer = null;
            }
            this.leafletMap = null;
        }
    },
    props: {
        // -- props -- Properties passed from parent component
        // --
        mode: String
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        isInFullscreen: function() {
            switch (this.mode) {
                case "troubleshootingHeatmaps":
                    if (this.isInFullscreen) {
                        let mapSideSwitch = document.getElementById(
                            "blocSwitchSpan"
                        );
                        L.DomEvent.on(
                            mapSideSwitch,
                            "mousedown mouseup touchstart",
                            L.DomEvent.stopPropagation
                        );
                        L.DomEvent.on(mapSideSwitch, "mouseenter", () => {
                            this.leafletMap.dragging.disable();
                        });
                        L.DomEvent.on(mapSideSwitch, "mouseleave", () => {
                            this.leafletMap.dragging.enable();
                        });
                        this.isMapSideBarDisplayed = true;
                        $("#blocHeatmapScaleBar").addClass("leaflet-bottom leaflet-left marginLeft100Heatmap");
                        $("#blocSwitchSpan").addClass("leaflet-top leaflet-left marginLeft60Heatmap heatmapControl");
                        $("#blocSelect2OptionHeatmap").addClass("leaflet-top leaflet-right select2OptionHeatmap");
                        $("#map").append($("#blocHeatmapScaleBar, #blocSwitchSpan, #blocSelect2OptionHeatmap"));
                    } else {
                        this.isMapSideBarDisplayed = false;
                        $("#blocHeatmapScaleBar").removeClass("leaflet-bottom leaflet-left marginLeft100Heatmap");
                        $("#blocSwitchSpan").removeClass("leaflet-top leaflet-left marginLeft60Heatmap heatmapControl");
                        $("#blocSelect2OptionHeatmap").removeClass("leaflet-top leaflet-right select2OptionHeatmap");
                        $("#rowHeatmapScaleBar").append($("#blocHeatmapScaleBar"));
                        $("#rowSwitchSpan").prepend($("#blocSwitchSpan"));
                        $("#select2OptionHeatmap").append($("#blocSelect2OptionHeatmap"));
                    }
                    break;
                case "wallsDefinition":
                    if (this.isInFullscreen) {
                        $(".map-side-switch").addClass("marginLeft380p");
                        $(".leaflet-control-container .leaflet-top.leaflet-left").addClass("marginLeft380p");
                        $("#blocWallsList").addClass("leaflet-top leaflet-left width380P marginT10");
                        $("#map").append($("#blocWallsList, #wallModal, #deleteModal"));
                    } else {
                        $(".leaflet-control-container .leaflet-top.leaflet-left").removeClass("marginLeft380p");
                        $("#blocWallsList").removeClass("leaflet-top leaflet-left width380P marginT10");
                        $("#app").prepend($("#wallModal, #deleteModal"));
                        $("#rowWallsList").append($("#blocWallsList"));
                        $(".map-side-switch").removeClass("marginLeft380p");
                    }

                    break;
                case "geofencesDefinition":

                    if (this.isInFullscreen) {
                        $(".map-side-switch").addClass("marginLeft380p");
                        $(".leaflet-control-container .leaflet-top.leaflet-left").addClass("marginLeft380p");
                        $("#blocGeofencesList").addClass("leaflet-top leaflet-left width380P marginT10");
                        $("#map").append($("#blocGeofencesList, #geofenceModal, #deleteModal"));
                    } else {
                        $(".leaflet-control-container .leaflet-top.leaflet-left").removeClass("marginLeft380p");
                        $("#blocGeofencesList").removeClass("leaflet-top leaflet-left width380P marginT10");
                        $("#app").prepend($("#geofenceModal, #deleteModal"));
                        $("#rowGeofencesList").append($("#blocGeofencesList"));
                        $(".map-side-switch").removeClass("marginLeft380p");
                    }
                    break;
                case "assetsTracking":
                    if (this.isInFullscreen) {
                        $('#kt_header').addClass("kt-header-full-screen");
                        $('.autocomplete').addClass('marginT12');
                        $('.apOrBeaconDown').addClass('marginT60')
                    } else {
                        $('#kt_header').removeClass("kt-header-full-screen");
                        $('.autocomplete').removeClass('marginT12');
                        $('.apOrBeaconDown').removeClass('marginT60')
                    }
                    break;
                default:
                    break;
            }
        },

        currentSite: function(site) {
            console.log("Component(LocationMap)::watch(currentSite) - called with ",site);
            if (site && !this.mapInitialized) {
                this.mapInitialized = true;
                // Init default map by default.
                this.defineSiteLocation(site);
            }
        },

        currentMapLatitude: function(latitude) {
            console.log("Component(LocationMap)::watch(currentMapLatitude) - called with ", latitude);
            // site location has been defined => init of leaflet map
            if (!this.leafletMap && this.currentMapLatitude !== 0.01) {
                console.log("Component(LocationMap)::watch(currentMapLatitude) - call initMap()");
                this.initMap();
            } else {
                console.log("Component(LocationMap)::watch(currentMapLatitude) - call onCenterMap()");
                this.onCenterMap();
            }
        },

        // This watcher is called when list of buildings and their floors has been retrieved.
        // This watcher is used to build the floor selector widget located on bottom right corner of the location map.
        buildingsAndTheirFloorsArray: function(list) {
            console.log("Component(LocationMap)::watch(buildingsAndTheirFloorsArray) - called with ",list);
            switch (this.mode) {
                case "gatewaysLocation":
                case "indoorAreasDefinition":
                case "geofencesDefinition":
                case "analyticswidget":
                case "troubleshootingHeatmaps":
                case "walkwaysDefinition":
                case "wallsDefinition":
                case "autocalibrationtagsLocation":
                    this.initFloorsSelector(false);
                    break;
                case "assetsTracking":
                    this.initFloorsSelector(false);
                    this.isMapSideBarDisplayed = true;
                    break;
                case "assetlocationhistory":
                    this.initFloorsSelector(false);
                    this.isMapSideBarDisplayed = true;
                    break;
                default:
                    console.log(
                        "Component(LocationMap)::watch(buildingsAndTheirFloorsArray) - No need to create floor selector in current mode = " +
                            this.mode
                    );
            }
        },

        // This watcher is called each time that the user selects a new floor to display on the map
        selectedFloors: function(floors) {
            console.log("Component(LocationMap)::watch(selectedFloors) - Current Floors to be displayed = ", floors);
            // List of displayed floor has been updated.
            floorsUtil.displaySelectedFloors(this, floors);
        },

        currentAutoCalibrationTagLocation: function(action) {
            console.log("Component(LocationMap)::watch(currentAutoCalibrationTagLocation) - called with ", action);
            switch (this.mode) {
                case "autocalibrationtagsLocation":
                    autocalibrationTagsUtil.updateCurrentAutoCalibrationTagLocation(this, action);
                    break;
                default:
                    break;
            }
            if (this.currentAutoCalibrationTagLocation && this.currentAutoCalibrationTagLocation.state != "CREATE") {
                this.resetDistanceBetweenLastAddedObjectAndCurrentPoint();
            }
        },

        // Each time there is a change in the list of all autocalibration tags, display again the autocalibration tag layer on the map
        siteAutoCalibrationTags: function() {
            switch (this.mode) {
                case "autocalibrationtagsLocation":
                    autocalibrationTagsUtil.generateAutoCalibrationTagsLayer(this);
                    break;
                case "assetsTracking":
                case "assetlocationhistory":
                    autocalibrationTagsUtil.generateAutoCalibrationTagsLayer(this);
                    break;
                case "troubleshootingHeatmaps":
                case "geofencesDefinition":
                case "gatewaysLocation":
                case "wallsDefinition":
                case "indoorAreasDefinition":
                    // By default, on the troubleshooting heatmap, layer is displayed
                    autocalibrationTagsUtil.generateAutoCalibrationTagsLayer(this);
                    autocalibrationTagsUtil.showOrHideAutoCalibrationTagsLayer(this);
                    break;
                default:
                    break;
            }
        },

        currentBLEGatewayLocation: function(action) {
            console.log("Component(LocationMap)::watcher(currentBLEGatewayLocation) - called", action);
            switch (this.mode) {
                case "gatewaysLocation":
                    if (action.state === "SAVE_EDIT") {
                        // Verify if gateway is on the right position
                        const actualCurrentGatewayCoordinates = this.gatewaysLayer.getLayerById(this.currentBLEGatewayLocation.id).toGeoJSON().geometry.coordinates;
                        const currentGatewayObj = _.find(this.siteBLEGateways, (gateway) => gateway.id === this.currentBLEGatewayLocation.id);
                        const locationRules = gatewaysUtil.getLocationRulesOfGateway(this.siteBLEGateways, currentGatewayObj, actualCurrentGatewayCoordinates);
                        if (locationRules.locationLess10m) {
                            // Show modal for incorrect position (< 10m)
                            $("#gatewayPositionIncorrectModal_textLess10m").show();
                            $("#gatewayPositionIncorrectModal_textMore15m").hide();
                            $("#gatewayPositionIncorrectModal").modal("show");
                        } else if (locationRules.locationMore15m) {
                            // Show modal for incorrect position (> 15m)
                            $("#gatewayPositionIncorrectModal_textLess10m").hide();
                            $("#gatewayPositionIncorrectModal_textMore15m").show();
                            $("#gatewayPositionIncorrectModal").modal("show");
                        }
                        // Continue edit location
                        gatewaysUtil.updateCurrentGatewayLocation(this, action);
                    } else {
                        gatewaysUtil.updateCurrentGatewayLocation(this, action);
                    }
                    break;
                default:
                    break;
            }
            if (this.currentBLEGatewayLocation && this.currentBLEGatewayLocation.state != "CREATE") {
                this.resetDistanceBetweenLastAddedObjectAndCurrentPoint();
            }
        },

        currentIndoorArea: function(area) {
            console.log("Component(LocationMap)::watch(currentIndoorArea) - called with : ", area);
            indoorAreasUtil.updateCurrentIndoorArea(this, area);
        },

        currentGeofence: function(area) {
            console.log("Component(LocationMap)::watch(currentGeofence) - called with : ", area);
            geofencesUtil.updateCurrentGeofence(this, area);
            this.addToastMessageFullScreen();
        },

        siteAssetsInside: function (assets) {
            console.log("Component(LocationMap)::watch(siteAssetsInside) - called with : ", assets);
            if (assets && assets.length) {
                assetsTrackingUtil.updateCurrentFloorAssets(this, this.getSelectedFloorId, assets);
            }
        },

        currentWalkway: function(area) {
            console.log("Component(LocationMap)::watch(currentWalkway) - called with : ", area);
            walkwaysUtil.updateCurrentWalkway(this, area);
        },

        currentWall: function(area) {
            console.log("Component(LocationMap)::watch(currentWall) - called with : ", area);
            wallsUtil.updateCurrentWall(this, area);
            this.addToastMessageFullScreen();
        },

        siteIndoorAreas: function(areas) {
            console.log("Component(LocationMap)::watch(siteIndoorAreas) - called with : ", areas);
            if (this.leafletMap) {
                switch (this.mode) {
                    case "indoorAreasDefinition":
                        indoorAreasUtil.generateIndoorAreasLayer(this);
                        break;
                    case "assetsTracking":
                    case "assetlocationhistory":
                        indoorAreasUtil.generateIndoorAreasLayer(this);
                        break;
                    case "troubleshootingHeatmaps":
                    case "geofencesDefinition":
                    case "gatewaysLocation":
                    case "autocalibrationtagsLocation":
                    case "wallsDefinition":
                    case "indoorAreasDefinition":
                        // By default, on the troubleshooting heatmap, layer is not displayed
                        indoorAreasUtil.generateIndoorAreasLayer(this);
                        indoorAreasUtil.showOrHideIndoorAreasLayer(this);
                        break;
                    default:
                        break;
                }
            }
        },

        siteGeofences: function(areas) {
            console.log("Component(LocationMap)::watch(siteGeofences) - called with : ", areas);
            if (this.leafletMap) {
                switch (this.mode) {
                    case "geofencesDefinition":
                        geofencesUtil.generateGeofencesLayer(this);
                        this.addToastMessageFullScreen();
                        break;
                    case "assetsTracking":
                    case "assetlocationhistory":
                        geofencesUtil.generateGeofencesLayer(this);
                        break;
                    default:
                        break;
                }
            }
        },

        siteWalkways: function(areas) {
            console.log("Component(LocationMap)::watch(siteWalkways) - called with : ", areas);
            if (this.leafletMap) {
                switch (this.mode) {
                    case "walkwaysDefinition":
                        walkwaysUtil.generateWalkwaysLayer(this);
                        break;
                    case "assetsTracking":
                    case "assetlocationhistory":
                    case "geofencesDefinition":
                        walkwaysUtil.generateWalkwaysLayer(this);
                        break;
                    default:
                        break;
                }
            }
        },

        siteWalls: function(areas) {
            console.log("Component(LocationMap)::watch(siteWalls) - called with : ", areas);
            if (this.leafletMap) {
                switch (this.mode) {
                    case "wallsDefinition":
                        wallsUtil.generateWallsLayer(this);
                        this.addToastMessageFullScreen();
                        break;
                    case "assetsTracking":
                    case "assetlocationhistory":
                        wallsUtil.generateWallsLayer(this);
                        break;
                    case "troubleshootingHeatmaps":
                    case "geofencesDefinition":
                    case "gatewaysLocation":
                    case "autocalibrationtagsLocation":
                    case "indoorAreasDefinition":
                        // By default, on the troubleshooting heatmap, layer is not displayed
                        wallsUtil.generateWallsLayer(this);
                        wallsUtil.showOrHideWallsLayer(this);
                        break;
                    default:
                        break;
                }
            }
        },

        floorsAssets: function(assets) {
            console.log("Component(LocationMap)::watch(floorsAssets) - called with : ", assets);
            if (this.mode === "assetsTracking") {
                const floorId = this.getSelectedFloorId;
                const mapFloor = assets.find(asset => asset.tag && asset.tag.floorId === floorId);
                const assetsInFloor = assets.filter(asset => asset.tag && asset.tag.floorId === floorId );
                if (mapFloor && assets.length > this.assetsShowLimit) {
                    this.filterAssetsForView();
                } else {
                    // Init view assets with all floors asset
                    if(mapFloor) {
                        this.viewAssets = assetsInFloor;
                    } else {
                        this.viewAssets = [];
                    }
                    // Disable leaflet event if enable previously
                    this.disableAssetTrackingLeafletEvent();
                    // Generate asset location history
                    assetsTrackingUtil.generateAssetsLayer(this);
                    assetsTrackingUtil.showOrHideAssetsLayer(this);
                }
            }
        },

        lastAddedObjectLocation: function(assets) {
            console.log("Component(LocationMap)::watch(lastAddedPointLocation) - called with : ");
            if (this.lastAddedObjectLocation) {
                L.DomEvent.on(this.leafletMap, "mousemove", (event) => {
                    if ( (this.currentBLEGatewayLocation && this.currentBLEGatewayLocation.state === "CREATE") ||
                         (this.currentAutoCalibrationTagLocation && this.currentAutoCalibrationTagLocation.state === "CREATE") ) {
                        if (event.latlng) {
                            this.setMouseMovePoint(event.latlng)
                        }
                    }
                });
            }
        },

        analyticsWidgetHeatmapData: {
            deep: true,
            handler: function(heatmapData) {
                console.log("Component(LocationMap)::watch(analyticsWidgetHeatmapData) - called with : ", heatmapData);
                if (this.mode === "analyticswidget") {
                    analyticsUtil.generateAssetsHeatmapLayer(this, true);
                }
            }
        },

        troubleshootingHeatmapData: {
            deep: true,
            handler: function() {
                if (this.mode === "troubleshootingHeatmaps" && this.troubleshootingHeatmapData && this.troubleshootingHeatmapData.length > 0) {
                    var forceUpdate = true;
                    troubleshootingHeatmapsUtil.generateTroubleshootingHeatmapLayer(this, forceUpdate);
                }
            }
        },

        // Each time there is a change in the list of all gateways, display again the gateways layer on the map
        siteBLEGateways: function(gateways) {
            console.log("Component(LocationMap)::watch(siteBLEGateways) - called with : ", gateways);
            switch (this.mode) {
                case "gatewaysLocation":
                    gatewaysUtil.generateGatewaysLayer(this);
                    break;
                case "assetsTracking":
                case "assetlocationhistory":
                    gatewaysUtil.generateGatewaysLayer(this);
                    break;
                case "troubleshootingHeatmaps":
                    // By default, on the troubleshooting heatmap, gateways layer is displayed
                    gatewaysUtil.generateGatewaysLayer(this);
                    gatewaysUtil.showOrHideGatewaysLayer(this);

                    this.setHeatmapsLayerDisplayed(true);
                    break;
                default:
                    break;
            }
        },

        // Each time a new floor is selected, refresh display of layers
        getSelectedFloorId: function(id) {
            console.log("Component(LocationMap)::watch(getSelectedFloorId) - called with : ", id);
            if (this.getSelectedFloorId) {
                switch (this.mode) {
                    case "gatewaysLocation":
                        gatewaysUtil.generateGatewaysLayer(this);
                        gatewaysUtil.showOrHideGatewaysLayer(this);

                        geofencesUtil.generateGeofencesLayer(this);
                        geofencesUtil.showOrHideGeofencesLayer(this);

                        indoorAreasUtil.generateIndoorAreasLayer(this);
                        indoorAreasUtil.showOrHideIndoorAreasLayer(this);

                        wallsUtil.generateWallsLayer(this);
                        wallsUtil.showOrHideWallsLayer(this);

                        autocalibrationTagsUtil.generateAutoCalibrationTagsLayer(this);
                        autocalibrationTagsUtil.showOrHideAutoCalibrationTagsLayer(this);
                        break;
                    case "autocalibrationtagsLocation":
                        autocalibrationTagsUtil.generateAutoCalibrationTagsLayer(this);
                        autocalibrationTagsUtil.showOrHideAutoCalibrationTagsLayer(this);

                        geofencesUtil.generateGeofencesLayer(this);
                        geofencesUtil.showOrHideGeofencesLayer(this);

                        indoorAreasUtil.generateIndoorAreasLayer(this);
                        indoorAreasUtil.showOrHideIndoorAreasLayer(this);

                        gatewaysUtil.generateGatewaysLayer(this);
                        gatewaysUtil.showOrHideGatewaysLayer(this);

                        wallsUtil.generateWallsLayer(this);
                        wallsUtil.showOrHideWallsLayer(this);
                        break;
                    case "indoorAreasDefinition":
                        indoorAreasUtil.generateIndoorAreasLayer(this);
                        indoorAreasUtil.showOrHideIndoorAreasLayer(this);

                        geofencesUtil.generateGeofencesLayer(this);
                        geofencesUtil.showOrHideGeofencesLayer(this);

                        gatewaysUtil.generateGatewaysLayer(this);
                        gatewaysUtil.showOrHideGatewaysLayer(this);

                        wallsUtil.generateWallsLayer(this);
                        wallsUtil.showOrHideWallsLayer(this);

                        autocalibrationTagsUtil.generateAutoCalibrationTagsLayer(this);
                        autocalibrationTagsUtil.showOrHideAutoCalibrationTagsLayer(this);
                        break;
                    case "geofencesDefinition":
                        geofencesUtil.generateGeofencesLayer(this);
                        geofencesUtil.showOrHideGeofencesLayer(this);

                        indoorAreasUtil.generateIndoorAreasLayer(this);
                        indoorAreasUtil.showOrHideIndoorAreasLayer(this);

                        gatewaysUtil.generateGatewaysLayer(this);
                        gatewaysUtil.showOrHideGatewaysLayer(this);

                        wallsUtil.generateWallsLayer(this);
                        wallsUtil.showOrHideWallsLayer(this);

                        autocalibrationTagsUtil.generateAutoCalibrationTagsLayer(this);
                        autocalibrationTagsUtil.showOrHideAutoCalibrationTagsLayer(this);
                        break;
                    case "walkwaysDefinition":
                        walkwaysUtil.generateWalkwaysLayer(this);
                        walkwaysUtil.showOrHideWalkwaysLayer(this);
                        break;
                    case "wallsDefinition":
                        wallsUtil.generateWallsLayer(this);
                        wallsUtil.showOrHideWallsLayer(this);

                        geofencesUtil.generateGeofencesLayer(this);
                        geofencesUtil.showOrHideGeofencesLayer(this);

                        indoorAreasUtil.generateIndoorAreasLayer(this);
                        indoorAreasUtil.showOrHideIndoorAreasLayer(this);

                        gatewaysUtil.generateGatewaysLayer(this);
                        gatewaysUtil.showOrHideGatewaysLayer(this);

                        autocalibrationTagsUtil.generateAutoCalibrationTagsLayer(this);
                        autocalibrationTagsUtil.showOrHideAutoCalibrationTagsLayer(this);
                        break;
                    case "analyticswidget":
                        analyticsUtil.generateAssetsHeatmapLayer(this, true);
                        break;
                    case "troubleshootingHeatmaps":
                        troubleshootingHeatmapsUtil.removeHeatmapLayer(this);
                        break;
                    case "assetsTracking":
                        assetsTrackingUtil.generateAssetsLayer(this);
                        assetsTrackingUtil.showOrHideAssetsLayer(this);
                        assetsTrackingUtil.disableDragOnSideBarContentMenu(this);

                        gatewaysUtil.generateGatewaysLayer(this);
                        gatewaysUtil.showOrHideGatewaysLayer(this);

                        autocalibrationTagsUtil.generateAutoCalibrationTagsLayer(this);
                        autocalibrationTagsUtil.showOrHideAutoCalibrationTagsLayer(this);

                        indoorAreasUtil.generateIndoorAreasLayer(this);
                        indoorAreasUtil.showOrHideIndoorAreasLayer(this);

                        geofencesUtil.generateGeofencesLayer(this);
                        geofencesUtil.showOrHideGeofencesLayer(this);

                        walkwaysUtil.generateWalkwaysLayer(this);
                        walkwaysUtil.showOrHideWalkwaysLayer(this);

                        wallsUtil.generateWallsLayer(this);
                        wallsUtil.showOrHideWallsLayer(this);

                        break;
                    case "assetlocationhistory":
                        gatewaysUtil.generateGatewaysLayer(this);
                        gatewaysUtil.showOrHideGatewaysLayer(this);
                        assetsTrackingUtil.disableDragOnSideBarContentMenu(this);

                        autocalibrationTagsUtil.generateAutoCalibrationTagsLayer(this);
                        autocalibrationTagsUtil.showOrHideAutoCalibrationTagsLayer(this);

                        indoorAreasUtil.generateIndoorAreasLayer(this);
                        indoorAreasUtil.showOrHideIndoorAreasLayer(this);

                        geofencesUtil.generateGeofencesLayer(this);
                        geofencesUtil.showOrHideGeofencesLayer(this);

                        walkwaysUtil.generateWalkwaysLayer(this);
                        walkwaysUtil.showOrHideWalkwaysLayer(this);

                        wallsUtil.generateWallsLayer(this);
                        wallsUtil.showOrHideWallsLayer(this);

                        break;
                    default:
                        break;
                }
            }
        },

        currentMapTilesLayerOpacity: function() {
            if (this.currentMapTilesLayerOpacity != -1) {
                this.setCurrentMapLayerOpacity(
                    this.currentMapTilesLayerOpacity
                );
            }
        },

        currentMapTileLayerName: function() {
            this.setMapTilesLayer(this.currentMapTileLayerName);
            if (this.mode === "assetsTracking" || this.mode === "assetlocationhistory") {
                // If mode is assets tracking, and if the user change the map layer, force to set opacity of the ne layer to the selected opacity in UI
                this.setCurrentMapLayerOpacity(
                    this.currentMapTilesLayerOpacity
                );
            }
        },

        isAssetsLabelsDisplayed: function() {
            if (this.mode === "assetsTracking") {
                assetsTrackingUtil.showOrHideAssetsLabels(this);
            }
        },

        isAssetsLayerDisplayed: function() {
            if (this.leafletMap) {
                switch (this.mode) {
                    case "assetsTracking":
                    case "assetlocationhistory":
                        assetsTrackingUtil.showOrHideAssetsLayer(this);
                        break;
                    default:
                        break;
                }
            }
        },

        isHeatmapsLayerDisplayed: function() {
            if (this.leafletMap) {
                switch (this.mode) {
                    case "troubleshootingHeatmaps":
                        troubleshootingHeatmapsUtil.showOrHideHeatmapLayer(this);
                        break;
                    default:
                        break;
                }
            }
        },

        isGatewaysLayerDisplayed: function() {
            if (this.leafletMap) {
                switch (this.mode) {
                    case "assetsTracking":
                    case "assetlocationhistory":
                        gatewaysUtil.showOrHideGatewaysLayer(this);
                        break;
                    case "troubleshootingHeatmaps":
                        gatewaysUtil.showOrHideGatewaysLayer(this);
                        break;
                    case "geofencesDefinition":
                    case "gatewaysLocation":
                    case "autocalibrationtagsLocation":
                    case "wallsDefinition":
                    case "indoorAreasDefinition":
                        gatewaysUtil.showOrHideGatewaysLayer(this);
                        break;
                    default:
                        break;
                }
            }
        },

        isAutocalibrationTagsLayerDisplayed: function() {
            if (this.leafletMap) {
                switch (this.mode) {
                    case "assetsTracking":
                    case "assetlocationhistory":
                    case "geofencesDefinition":
                    case "gatewaysLocation":
                    case "autocalibrationtagsLocation":
                    case "wallsDefinition":
                    case "indoorAreasDefinition":
                        autocalibrationTagsUtil.showOrHideAutoCalibrationTagsLayer(this);
                        break;
                    case "troubleshootingHeatmaps":
                        autocalibrationTagsUtil.showOrHideAutoCalibrationTagsLayer(this);
                        break;
                    default:
                        break;
                }
            }
        },

        isIndoorAreasLayerDisplayed: function() {
            if (this.leafletMap) {
                switch (this.mode) {
                    case "assetsTracking":
                    case "assetlocationhistory":
                    case "geofencesDefinition":
                    case "gatewaysLocation":
                    case "autocalibrationtagsLocation":
                    case "wallsDefinition":
                    case "indoorAreasDefinition":
                        indoorAreasUtil.showOrHideIndoorAreasLayer(this);
                        break;
                    case "troubleshootingHeatmaps":
                        indoorAreasUtil.showOrHideIndoorAreasLayer(this);
                        break;
                    default:
                        break;
                }
            }
        },

        isGeofencesLayerDisplayed: function() {
            if (this.leafletMap) {
                switch (this.mode) {
                    case "assetsTracking":
                    case "assetlocationhistory":
                    case "geofencesDefinition":
                    case "gatewaysLocation":
                    case "autocalibrationtagsLocation":
                    case "wallsDefinition":
                    case "indoorAreasDefinition":
                        geofencesUtil.showOrHideGeofencesLayer(this);
                        break;
                    default:
                        break;
                }
            }
        },

        isWalkwaysLayerDisplayed: function() {
            if (this.leafletMap) {
                switch (this.mode) {
                    case "assetsTracking":
                    case "assetlocationhistory":
                        walkwaysUtil.showOrHideWalkwaysLayer(this);
                        break;
                    default:
                        break;
                }
            }
        },

        isWallsLayerDisplayed: function() {
            if (this.leafletMap) {
                switch (this.mode) {
                    case "assetsTracking":
                    case "assetlocationhistory":
                        wallsUtil.showOrHideWallsLayer(this);
                        break;
                    case "troubleshootingHeatmaps":
                        wallsUtil.showOrHideWallsLayer(this);
                        break;
                    case "geofencesDefinition":
                    case "gatewaysLocation":
                    case "autocalibrationtagsLocation":
                    case "wallsDefinition":
                    case "indoorAreasDefinition":
                        wallsUtil.showOrHideWallsLayer(this);
                        break;
                    default:
                        break;
                }
            }
        },

        assetsLabelsDisplayedValues: function() {
            if (this.mode === "assetsTracking") {
                assetsTrackingUtil.displayAssetsTooltipsAccordingRowValues(
                    this
                );
            }
        },

        backgroundPicture: function(filePath) {
            if (filePath) {
                // Get map bounds
                let bounds = this.leafletMap.getBounds();
                let mapBounds = [
                    [bounds._northEast.lat, bounds._southWest.lng],
                    [bounds._southWest.lat, bounds._northEast.lng]
                ];

                // Get image to display
                let backgroundPicture = new Image();
                backgroundPicture.src = filePath;

                // Create image overlay upon current LeafLet map
                this.backgroundImageLayer = L.imageOverlay(
                    backgroundPicture,
                    mapBounds,
                    {
                        opacity: 1,
                        interactive: true
                    }
                );

                this.leafletMap.addLayer(this.backgroundImageLayer);
            } else if (this.backgroundImageLayer) {
                this.leafletMap.removeLayer(this.backgroundImageLayer);
            }
        },

        withScreen: function () {
            if (this.withScreen <= 1024) {
                $("#kt_header_menu_wrapper").removeClass("kt-header-menu-wrapper");
                $("#kt_header_menu_wrapper").addClass("header-assets-search");
            } else {
                $("#kt_header_menu_wrapper").addClass("kt-header-menu-wrapper");
                $("#kt_header_menu_wrapper").removeClass("header-assets-search");
            }
        },

        user: function (user) {
            console.log("Component(LocationMap)::watch(user) - called with : ", user);
            if (user) {
                assetsTrackingUtil.generateAssetsLayer(this);
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "getWallTypeById",
            "getFloorAssetByTagMacAddress",
            "arrayOfAssetsMacAddressToHighlight",
            "user",
            "currentSite",
            "currentMapLatitude",
            "currentMapLongitude",
            "currentMapZoom",
            "currentFloor",
            "buildingsAndTheirFloorsArray",
            "selectedFloors",
            "getSelectedBuildingId",
            "getSelectedFloorId",
            "currentAutoCalibrationTagLocation",
            "currentBLEGatewayLocation",
            "currentIndoorArea",
            "currentGeofence",
            "currentWalkway",
            "currentWall",
            "siteAutoCalibrationTags",
            "siteBLEGateways",
            "siteIndoorAreas",
            "siteGeofences",
            "siteWalkways",
            "siteWalls",
            "getGatewayById",
            "getAutocalibrationTagById",
            "getIndoorAreaById",
            "getGeofenceById",
            "getWalkwayById",
            "getWallById",
            "currentMapTilesLayerOpacity",
            "currentMapTileLayerName",
            "floorsAssets",
            "analyticsWidgetHeatmapData",
            "isAssetsLabelsDisplayed",
            "assetsLabelsDisplayedValues",
            "isGatewaysLayerDisplayed",
            "isAutocalibrationTagsLayerDisplayed",
            "isAssetsLayerDisplayed",
            "isIndoorAreasLayerDisplayed",
            "isGeofencesLayerDisplayed",
            "isWalkwaysLayerDisplayed",
            "isWallsLayerDisplayed",
            "getFloorAssetById",
            "backgroundPicture",
            "lastAddedObjectLocation",
            "distanceBetweenLastAddedObjectAndCurrentPoint",
            "lastAddedObjectName",
            "lastAddedObjectType",
            "troubleshootingHeatmapData",
            "isHeatmapsLayerDisplayed",
            "visualizationLink",
            "siteAssetsInside",
            "getWallColorById"
        ]),

        mapClass() {

            var mapClassName;
            switch (this.mode) {

                case "assetsTracking":
                    mapClassName = 'assetsTrackingMapDiv';
                    break;
                case "assetlocationhistory":
                    mapClassName = "assetsLocationHistoryMapDiv";
                    break;

                case "analyticswidget":
                    mapClassName = 'analyticWidgetMapDiv';
                    break;

                case "troubleshootingHeatmaps":
                    mapClassName = 'troubleshootingHeatmapsMapDiv';
                    break;

                case "siteLocation":
                    mapClassName = 'siteLocationMapDiv';
                    break;

                case "markerLocation":
                    mapClassName = 'markerLocationMapDiv';
                    break;

                case "gatewaysLocation":
                    mapClassName = 'gatewaysLocationMapDiv';
                    break;

                case "autocalibrationtagsLocation":
                    mapClassName = 'autotagsLocationMapDiv';
                    break;

                case "floorMapping":
                    mapClassName = 'floorMappingMapDiv';
                    break;

                case "indoorAreasDefinition":
                    mapClassName = 'indoorAreasMapDiv';
                    break;

                case "geofencesDefinition":
                    mapClassName = 'geofenceMapDiv';
                    break;

                case "walkwaysDefinition":
                    mapClassName = 'walkwaysMapDiv';
                    break;

                case "wallsDefinition":
                    mapClassName = 'wallsMapDiv';
                    break;

                default:
                    mapClassName = 'defaultMapDiv';
                    break;

            }

            return mapClassName;

        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---

        ...mapActions([
            "setHeatmapsLayerDisplayed",
            "resetFloorAssets",
            "resetArrayOfMacAddressToHighlight",
            "clearAllActiveFilters",
            "setMapMode",
            "resetMapMode",
            "resetAssetsState",
            "getDashboardPreferencesFromLocalStorage",
            "resetGatewaysLayerDisplayed",
            "resetHeatmapsLayerDisplayed",
            "resetAutocalibrationTagsLayerDisplayed",
            "resetAssetsLayerDisplayed",
            "resetIndoorAreasLayerDisplayed",
            "resetGeofencesLayerDisplayed",
            "resetWalkwaysLayerDisplayed",
            "resetWallsLayerDisplayed",
            "setHasMovedFromInitialInit",
            "getBuildingsAndTheirFloors",
            "getSiteById",
            "setMapLocation",
            "addFloorToSelectedFloors",
            "removeAllFloorsToSelectedFloors",
            "setCurrentSelectedBuildingId",
            "setCurrentSelectedFloorId",
            "setAutoCalibrationTagLocation",
            "setGatewayLocation",
            "setCurrentGatewayLocation",
            "setCurrentAutoCalibrationTagLocation",
            "setCurrentIndoorArea",
            "setCurrentGeofence",
            "setCurrentWalkway",
            "setCurrentWall",
            "setCurrentMapTilesLayerOpacity",
            "setCurrentOpenedAssetIdInDashboard",
            "setGatewaysLayerDisplayed",
            "setAutocalibrationTagsLayerDisplayed",
            "setAssetsLayerDisplayed",
            "setIndoorAreasLayerDisplayed",
            "setGeofencesLayerDisplayed",
            "setWalkwaysLayerDisplayed",
            "setWallsLayerDisplayed",
            "setMouseMovePoint",
            "resetDistanceBetweenLastAddedObjectAndCurrentPoint",
            "storeLastAddedObjectLocation",
            "showTextModal",
            "generateVisualizationLink",
        ]),

        /**
         * Method to filter floor asset according to map view.
         */
        filterAssetsForView() {
            // Init array which will contains assets whichs are view
            this.viewAssets = [];
            if (this.leafletMap && this.floorsAssets.length > 0) {
                // Keep only floor assets which is located in view
                this.floorsAssets.forEach((asset) => {
                    if (asset && asset.tag && asset.tag.location && asset.tag.location.coordinates) {
                        let assetMarker = L.marker([asset.tag.location.coordinates[0], asset.tag.location.coordinates[1]]);
                        if (this.leafletMap.getBounds().contains(assetMarker.getLatLng())) {
                            this.viewAssets.push(asset);
                        }
                    }
                });
            }
            // Init asset layer
            assetsTrackingUtil.generateAssetsLayer(this);
            assetsTrackingUtil.showOrHideAssetsLayer(this);
        },

        /**
         * Method to show measure control button
         */
        showMeasureControl() {
            // Show measure button
            $(".leaflet-control-draw-measure").show();
        },

        /**
         * Method to hide measure control button
         */
        hideMeasureControl() {
            // Hide measure button
            $(".leaflet-control-draw-measure").hide();
        },

        // This function is used to inform map store if an update of latitude, longitude or zoom appears.
        notifyLocation(longitude, latitude, zoom) {
            const locationData = {
                longitude: longitude,
                latitude: latitude,
                zoom: zoom
            };
            this.setMapLocation(locationData);
        },

        // Define map location before initialization
        defineSiteLocation(site) {
            // If site already contain a location, init map with this location
            if (site.location && site.location.coordinates && site.zoom) {
                this.notifyLocation(
                    site.location.coordinates[0],
                    site.location.coordinates[1],
                    site.zoom
                );
            } else {
                // Site location has not been set for the moment.
                // We will try to focus map to the selected site country if he has been filled by the user. (Optional by default)
                if (site.name && site.city && site.country) {
                    let siteAddress =
                        site.name + " " + site.city + " " + site.country;
                    let url =
                        "https://nominatim.openstreetmap.org/search?format=json&limit=3&q=" +
                        siteAddress;
                    $.get(url, data => {
                        //console.log("site GPS coordinates : "+data);
                        if (data.length !== 0) {
                            for (let result of data) {
                                if (
                                    result.importance > 0.4 &&
                                    (result.class === "building" ||
                                        result.class === "amenity")
                                ) {
                                    this.notifyLocation(
                                        result.lon,
                                        result.lat,
                                        16
                                    );
                                    return;
                                }
                            }
                        }

                        let siteAddress = site.city + " " + site.country;
                        let url =
                            "https://nominatim.openstreetmap.org/search?format=json&limit=3&q=" +
                            siteAddress;
                        $.get(url, data => {
                            for (let result of data) {
                                if (
                                    result.importance > 0.5 &&
                                    (result.class === "place" ||
                                        result.class === "boundary")
                                ) {
                                    this.notifyLocation(
                                        result.lon,
                                        result.lat,
                                        12
                                    );
                                    break;
                                }
                            }
                        });
                    });
                }

                if (site.country) {
                    // A country has been selected by the user during site creation
                    let countryInfo = this.countriesByAlpha2[site.country];
                    if (countryInfo) {
                        // We have defaut lat/long for this site. Init map with these coordinates !
                        this.notifyLocation(
                            countryInfo.longitude,
                            countryInfo.latitude,
                            5
                        );
                    } else {
                        // We don't have any lat/long info for the selected country, init map with default parameters.
                        this.notifyLocation(
                            this.defaultLongitude,
                            this.defaultLatitude,
                            this.defaultZoom
                        );
                    }
                } else {
                    // User has not selected any country during site creation, init map with default parameters.
                    this.notifyLocation(
                        this.defaultLongitude,
                        this.defaultLatitude,
                        this.defaultZoom
                    );
                }
            }
        },

        // This function is used to init Leaflet map with site of default location data
        initMap() {

            console.log("Component(LocationMap)::initMap() - called ");

            // Not appear fullscreen button on asset location history page
            if (this.mode !== "assetlocationhistory") {
                // Init map options : for the moment only fullscreen button
                this.initFullScreenControl();
            }

            // Init Leaflet Map basically
            console.log("Component(LocationMap)::initMap() - create leaflet map and set view to current latitude and longitude");

            this.leafletMap = L.map("map", this.mapOptions).setView(
                [this.currentMapLatitude, this.currentMapLongitude],
                this.currentMapZoom
            );

            console.log("Component(LocationMap)::initMap() - export this map in window to be accessible everywhere");

            window.leafletMap = this.leafletMap;

            // Create a generic method in FeatureGroup class that will be use to get one layer by id
            console.log("Component(LocationMap)::initMap() - create some generic methods that will be used in all FeatureGroup");

            L.FeatureGroup.include({
                getLayerById: function(id) {
                    for (var i in this._layers) {
                        if (this._layers[i].options.id == id) {
                            return this._layers[i];
                        }
                    }
                },
                openTooltips: function() {
                    for (var i in this._layers) {
                        if (this._layers.hasOwnProperty(i)) {
                            this._layers[i].openTooltip();
                        }
                    }
                },
                closeTooltips: function() {
                    for (var i in this._layers) {
                        if (this._layers.hasOwnProperty(i)) {
                            this._layers[i].closeTooltip();
                        }
                    }
                }
            });

            // When map will be ready, adapt automatically height of the map to have maximal height available
            this.leafletMap.whenReady(() => {

                console.log("Component(LocationMap)::initMap() - leaflet map is now ready");

                // Now that map is initiated, get all buildings and their floors for buildings/floors selector creation
                console.log("Component(LocationMap)::initMap() - Get Buildings & Floors now, to be used to instanciate the floor selector");

                this.getBuildingsAndTheirFloors(this.siteId);

                switch (this.mode) {

                    case "assetsTracking":
                        console.log("Component(LocationMap)::initMap() - Init location map for asset tracking");

                        assetsTrackingUtil.initAssetsTracking(this);
                        assetsTrackingUtil.showOrHideAssetsLayer(this);
                        gatewaysUtil.showOrHideGatewaysLayer(this);
                        autocalibrationTagsUtil.showOrHideAutoCalibrationTagsLayer(this);
                        indoorAreasUtil.showOrHideIndoorAreasLayer(this);
                        geofencesUtil.showOrHideGeofencesLayer(this);
                        walkwaysUtil.showOrHideWalkwaysLayer(this);
                        wallsUtil.showOrHideWallsLayer(this);
                        this.addMeasureControl();
                        this.addGodModeControlCommand();
                        break;

                    case "assetlocationhistory":

                        console.log("Component(LocationMap)::initMap() - Init location map for asset tracking");

                        assetsTrackingUtil.initAssetsTracking(this);
                        assetsTrackingUtil.showOrHideAssetsLayer(this);
                        gatewaysUtil.showOrHideGatewaysLayer(this);
                        autocalibrationTagsUtil.showOrHideAutoCalibrationTagsLayer(this);
                        indoorAreasUtil.showOrHideIndoorAreasLayer(this);
                        geofencesUtil.showOrHideGeofencesLayer(this);
                        walkwaysUtil.showOrHideWalkwaysLayer(this);
                        wallsUtil.showOrHideWallsLayer(this);
                        // this.addGodModeControlCommand();
                        break;

                    case "analyticswidget":

                        console.log("Component(LocationMap)::initMap() - Init location map for assets heatmap");

                        analyticsUtil.initAnalytics(this);
                        break;

                    case "troubleshootingHeatmaps":

                        console.log("Component(LocationMap)::initMap() - Init location map for troubleshooting heatmap");

                        this.addMeasureControl();

                        this.setGatewaysLayerDisplayed(true);
                        this.setAutocalibrationTagsLayerDisplayed(true);
                        this.setWallsLayerDisplayed(false);
                        this.setIndoorAreasLayerDisplayed(false);

                        troubleshootingHeatmapsUtil.initTroubleshootingHeatmaps(this);

                        break;

                    case "siteLocation":

                        console.log("Component(LocationMap)::initMap() - Init location map for site location");

                        this.initSearchBar();
                        this.displayLayersControl();
                        this.setMapTilesLayer("OpenStreetMap");
                        this.initCircleMarker(this.currentMapLatitude, this.currentMapLongitude);
                        break;

                    case "markerLocation":

                        console.log("Component(LocationMap)::initMap() - Init location map for marker location");

                        this.setMapTilesLayer("OpenStreetMap");
                        this.initMarkerLocationPopup(this.currentMapLatitude, this.currentMapLongitude);
                        break;

                    case "gatewaysLocation":

                        console.log("Component(LocationMap)::initMap() - Init location map for gateway location");

                        gatewaysUtil.initGatewayLocation(this);
                        this.addMeasureControl();
                        this.addShowHideLabelsControl();
                        break;

                    case "autocalibrationtagsLocation":

                        console.log("Component(LocationMap)::initMap() - Init location map for autocalibration tag location");

                        autocalibrationTagsUtil.initAutoCalibrationTagLocation(this);
                        this.addMeasureControl();
                        this.addShowHideLabelsControl();
                        break;

                    case "floorMapping":

                        console.log("Component(LocationMap)::initMap() - Init location map for floor mapping");
                        floorsUtil.initFloors(this);
                        this.addMeasureControl();
                        this.displayLayersControl();
                        break;

                    case "indoorAreasDefinition":

                        console.log("Component(LocationMap)::initMap() - Init location map for indoor areas definition");

                        indoorAreasUtil.initIndoorAreas(this);
                        this.addMeasureControl();
                        this.addShowHideLabelsControl();
                        break;

                    case "geofencesDefinition":

                        console.log("Component(LocationMap)::initMap() - Init location map for geofences definition");

                        geofencesUtil.initGeofences(this);
                        this.addMeasureControl();
                        this.addShowHideLabelsControl();
                        break;

                    case "walkwaysDefinition":

                        console.log("Component(LocationMap)::initMap() - Init location map for walkways definition");

                        walkwaysUtil.initWalkways(this);
                        this.addMeasureControl();
                        this.addShowHideLabelsControl();
                        break;

                    case "wallsDefinition":

                        console.log("Component(LocationMap)::initMap() - Init location map for walls definition");

                        wallsUtil.initWalls(this);
                        this.addMeasureControl();
                        this.addShowHideLabelsControl();
                        break;

                    default:
                        console.error("Component(LocationMap)::initMap() - unknown locationmap mode, map has not been initialized");
                }

                console.log("Component(LocationMap)::initMap() - Add scale in map");

                // Add scale display into the map
                L.control.scale().addTo(this.leafletMap);

                console.log("Component(LocationMap)::initMap() - Register events when entering and exiting fullscreen");

                // events are fired when entering or exiting fullscreen.
                this.leafletMap.on("enterFullscreen", () => {
                    this.isInFullscreen = true;
                    if (this.mode === "assetsTracking" || this.mode === "assetlocationhistory") {
                        // $("#kt_header").hide();
                        $('.kt-header__topbar').hide();
                        $("#kt_aside").hide();
                        if (this.withScreen <= 1024) {
                            $('.leaflet-control-container .leaflet-top, #kt_header_menu_wrapper').addClass('fiexdTop0p');
                            $('#kt_header_mobile').hide();
                        }
                    }
                });
                this.leafletMap.on("exitFullscreen", () => {
                    this.isInFullscreen = false;
                    if (this.mode === "assetsTracking" || this.mode === "assetlocationhistory") {
                        $("#kt_aside").show();
                        // $("#kt_header").show();
                        $('.kt-header__topbar').show();
                        if (this.withScreen <= 1024) {
                            $('.leaflet-control-container .leaflet-top, #kt_header_menu_wrapper').removeClass('fiexdTop0p');
                            $('#kt_header_mobile').show();
                        }
                    }
                });

            });
        },

        addShowHideLabelsControl() {
            console.log("Component(LocationMap)::addShowHideLabelsControl() - called");
            $(".leaflet-control-zoom-fullscreen")
                .parent()
                .append(
                    '<a id="showHideLabelIcon" class="leaflet-control-zoom-centermap hideLabelIcon" href="#"></a>'
                );
            $("#showHideLabelIcon")
                .off()
                .on("click", e => {
                    $(".leaflet-tooltip").toggle();
                    if ($("#showHideLabelIcon").hasClass("hideLabelIcon")) {
                        $("#showHideLabelIcon")
                            .removeClass("hideLabelIcon")
                            .addClass("showLabelIcon");
                    } else {
                        $("#showHideLabelIcon")
                            .removeClass("showLabelIcon")
                            .addClass("hideLabelIcon");
                    }
                    // Cancel the default action
                    e.preventDefault();
                    // Fix bug if user clicks on center map button, the location of the item was saved
                    e.stopPropagation();
                });
            commonVueHelper.addTooltipToElement(
                $("#showHideLabelIcon"),
                i18n.t("common_showHideLabels")
            );
        },

        displayLayersControl() {
            var openstreetmap = L.tileLayer(
                "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                {
                    maxZoom: 22,
                    maxNativeZoom: 18,
                    attribution:
                        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
                }
            );

            var mapbox = L.tileLayer(
                "https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoid2F3YW5vcG91bG9zIiwiYSI6ImNqdGc3aTh4NTBpZzYzeXA2bG44NzAzYXcifQ.ltDQ5A9BesT6oRZmUauOEQ",
                {
                    maxZoom: 22,
                    maxNativeZoom: 18,
                    attribution:
                        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
                }
            );

            var worldStreetMap = L.tileLayer(
                "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
                {
                    maxZoom: 22,
                    maxNativeZoom: 18,
                    attribution:
                        "Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012"
                }
            );

            var Esri_WorldTopoMap = L.tileLayer(
                "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
                {
                    maxZoom: 22,
                    maxNativeZoom: 18,
                    attribution:
                        "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community"
                }
            );

            var baseLayers = {
                Openstreetmap: openstreetmap.addTo(this.leafletMap),
                Mapbox: mapbox,
                WorldStreetMap: worldStreetMap,
                WorldTopoMap: Esri_WorldTopoMap
            };

            L.control.layers(baseLayers).addTo(this.leafletMap);
        },

        addMeasureControl() {

            console.log("Component(LocationMap)::addMeasureControl() - called");

            this.measureHandler = L.control.measure({
                position: "topleft",
                activeColor: "#ff0505",
                primaryLengthUnit: "meters",
                primaryAreaUnit: "sqmeters",
                secondaryLengthUnit: undefined,
                secondaryAreaUnit: undefined,
                units: {
                    meters: {
                        factor: 1,
                        display: "meters",
                        decimals: 2
                    }
                }
            }).addTo(this.leafletMap);

            if (this.mode == "assetsTracking" || this.mode === "assetlocationhistory") {
                $(".leaflet-control-measure").addClass("marginTop110");
            } else {
                $(".leaflet-control-measure").removeClass("marginTop110");
            }

        },

        addGodModeControlCommand() {
            console.log(
                "Component(LocationMap)::addGodModeControlCommand() - called"
            );

            $(".leaflet-control-draw-measure")
                .parent()
                .hide();
            $(".leaflet-control-draw-measure")
                .parent()
                .css("margin-top", "110px");
            // Add custom function in leaflet map to be enabled from browser console
            this.leafletMap.toggleDebugMode = () => {
                if ($(".leaflet-control-draw-measure").is(":visible")) {
                    $(".leaflet-control-draw-measure")
                        .parent()
                        .hide();
                } else {
                    $(".leaflet-control-draw-measure")
                        .parent()
                        .show();
                }
                autocalibrationTagsUtil.toggleCircleRadiusAutoCalibrationTagsLayer(
                    this
                );
                assetsTrackingUtil.toggleCircleRadiusAssetsLayer(this);
            };

        },

        initFullScreenControl() {
            let fullscreenControlOptions = {
                position: "topleft"
            };
            if (this.mode === "assetsTracking") {
                fullscreenControlOptions.forcePseudoFullscreen = true; // Fullscreen to page width and height
            }
            (this.mapOptions.fullscreenControl = true),
            (this.mapOptions.fullscreenControlOptions = fullscreenControlOptions);
        },

        // This function is used to add floor selection button on bottom left corner of the map
        initFloorsSelector(multipleFloorsSelectionAllowed) {
            // 1- First, create building and floor selector dynamically
            let floorMenu = "";
            let defaultSelectedFloorId = "";
            for (let i = 0; i < this.buildingsAndTheirFloorsArray.length; i++) {
                let currentBuilding = this.buildingsAndTheirFloorsArray[i];
                if (
                    !currentBuilding.floors ||
                    currentBuilding.floors.length == 0
                ) {
                    // There is no floors for this building
                    continue;
                }

                // Lets sort the list of floors by floorNumber
                let sortedFloors = currentBuilding.floors
                    .sort(function(a, b) {
                        return a.floorNumber - b.floorNumber;
                    })
                    .reverse();

                defaultSelectedFloorId =
                    sortedFloors[sortedFloors.length - 1].id;

                // Create header for the building
                let buildingHeader =
                    '<h6 class="floorSelectorDropdownHeaderColor dropdown-header">' +
                    currentBuilding.name +
                    "</h6>";
                for (let j = 0; j < sortedFloors.length; j++) {
                    let currentFloor = sortedFloors[j];
                    let floorItem =
                        "<a data-buildingid=" +
                        currentBuilding.id +
                        " data-floornumber=" +
                        currentFloor.floorNumber +
                        " data-floorname=" +
                        currentFloor.name +
                        " data-floorid=" +
                        currentFloor.id +
                        ' class="floorSelectorLink floorSelectorDropdownColor dropdown-item" href="javascript:;">' +
                        currentFloor.floorNumber +
                        " (" +
                        currentFloor.name +
                        ")</a>";
                    buildingHeader += floorItem;
                }

                floorMenu += buildingHeader;
            }

            if (floorMenu) {
                let floorsSelector =
                    `
                    <div class="leaflet-bottom leaflet-left selectedFloorsList text-center">
                        <ul id="selectedFloorsList"></ul>
                    </div>
                    <div class="leaflet-bottom leaflet-right">
                        <div id="floorSelectorDropdown" class="floorSelectorDropdown leaflet-range-control leaflet-control dropdown dropup dropdown-inline">
                            <button id="floorSelectorButton" type="button" class="floorSelectorDropdownBtn btn btn-elevate-hover btn-icon btn-sm btn-icon-md" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-layer-group" style="margin-right: 8px"></i> ` +
                    i18n.t("b&f_changeFloor") +
                    `
                                <i class="fa fa-angle-up" style="margin-left: 8px;"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right floorSelectorDropdownList" id="floorSelectorDropdownListId" x-placement="bottom-end">
                                ` +
                    floorMenu +
                    `
                            </div>
                        </div>
                    </div>`;

                $(".leaflet-control-container").append(floorsSelector);

                // 2- Handle click on each floor link
                var self = this;
                $(".floorSelectorLink")
                    .off()
                    .on("click", function() {
                        if (multipleFloorsSelectionAllowed) {
                            // to do later for dashboard
                        } else {
                            if (!$(this).hasClass("active")) {
                                // Only one floor selection is allowed in the menu
                                // Remove all active class for all links
                                $(".floorSelectorLink").removeClass("active");
                                // Add class only for current clicked link
                                $(this).addClass("active");
                                setTimeout(() => {
                                    // Remove all selected floors from current selected floors
                                    self.removeAllFloorsToSelectedFloors();
                                    // Add only one floor selected
                                    let buildingId = $(this).attr(
                                        "data-buildingid"
                                    );
                                    let floorId = $(this).attr("data-floorid");
                                    console.log(
                                        "Component(LocationMap)::initFloorsSelectoxr() - Display floorId=" +
                                            floorId
                                    );
                                    self.resetFloorAssets();
                                    self.viewAssets = [];
                                    self.setCurrentSelectedBuildingId(
                                        buildingId
                                    );
                                    self.setCurrentSelectedFloorId(floorId);
                                    self.addFloorToSelectedFloors({
                                        buildingId: buildingId,
                                        floorId: floorId
                                    });
                                    // Store last displayed floor id in local storage.
                                    if (typeof localStorage != "undefined") {
                                        localStorage.setItem(
                                            self.siteId +
                                                "_" +
                                                self.mode +
                                                "_lastDisplayedFloorId",
                                            floorId
                                        );
                                    }
                                    // Reset
                                    self.storeLastAddedObjectLocation({
                                        type: null,
                                        id: null,
                                        lastAddedObjectLocation: null
                                    })
                                }, 50);
                            }
                        }
                    });

                // Get slider input in a variable
                let floorSelectorDropdown = document.getElementById(
                    "floorSelectorDropdown"
                );
                // Enable tooltips
                $('[data-toggle="kt-tooltip"]').tooltip();
                // Disable dragging on map when user enter and leave the slider area
                L.DomEvent.on(
                    floorSelectorDropdown,
                    "mousedown mouseup touchstart",
                    L.DomEvent.stopPropagation
                );
                L.DomEvent.on(floorSelectorDropdown, "mouseenter", () => {
                    this.leafletMap.dragging.disable();
                });
                L.DomEvent.on(floorSelectorDropdown, "mouseleave", () => {
                    this.leafletMap.dragging.enable();
                });

                // -1- Check if there is already a preferred floor map in local storage for this page
                if (typeof localStorage != "undefined") {
                    let floorId = localStorage.getItem(
                        self.siteId + "_" + this.mode + "_lastDisplayedFloorId"
                    );
                    if (floorId) {
                        // Select the floor already loaded in local storage
                        this.handleSelectFloorMapById(floorId);
                    } else {
                        // Select by default the lower floor on the first building
                        this.handleSelectFloorMapById(defaultSelectedFloorId);
                    }
                } else {
                    // Select by default the lower floor on the first building
                    this.handleSelectFloorMapById(defaultSelectedFloorId);
                }
            }
        },

        // This function is used to handle a select of specific floor from the floors list.
        // Result:
        // - Dedicated Floor will be selected into the list
        // - Building name and floor number will be displayed
        // - Dedicated floop map will be loaded in a specific leaflet layer
        handleSelectFloorMapById(floorId) {
            if (typeof localStorage != "undefined") {
                localStorage.setItem(
                    this.siteId + "_" + this.mode + "_lastDisplayedFloorId",
                    floorId
                );
            }
            $(".floorSelectorLink[data-floorid='" + floorId + "']").click();
        },

        // This function is used to close all popups on the map
        hideAllPopupOnMap() {
            if (this.leafletMap) {
                this.leafletMap.closePopup();
            }
        },

        // This function is used to hide floor map selector
        hideFloorMapSelector() {
            $("#floorSelectorDropdown").hide();
            $("#selectedFloorsList").hide();
        },

        // This function is used to show floor map selector
        showFloorMapSelector() {
            $("#floorSelectorDropdown").show();
            $("#selectedFloorsList").show();
        },

        // This function is used to add actions bar on the top on the map
        initActionsBar(availableActionsArray) {
            // By default, when actions bar is created, zoom in/zoom out and fullscreen actions are available.
            // Let's add tooltips now for them
            commonVueHelper.addTooltipToElement(
                $(".leaflet-control-zoom-in"),
                i18n.t("tooltip_zoomin")
            );
            commonVueHelper.addTooltipToElement(
                $(".leaflet-control-zoom-out"),
                i18n.t("tooltip_zoomout")
            );
            commonVueHelper.addTooltipToElement(
                $(".leaflet-control-zoom-fullscreen"),
                i18n.t("tooltip_enabledisableFullscreen")
            );

            // Add Center Map action button
            if (availableActionsArray.includes("centerMapButton")) {
                if (this.mode === "assetlocationhistory") {
                    // Append after zoom button because no fullscreen
                    $(".leaflet-control-zoom-out")
                        .parent()
                        .append(
                            '<a id="centerMapIcon" class="leaflet-control-zoom-centermap centerMapIcon" href="#"></a>'
                        );
                } else {
                    // Append button after fullscreen button
                    $(".leaflet-control-zoom-fullscreen")
                        .parent()
                        .append(
                            '<a id="centerMapIcon" class="leaflet-control-zoom-centermap centerMapIcon" href="#"></a>'
                        );
                }
                $("#centerMapIcon")
                    .off()
                    .on("click", e => {
                        this.onCenterMap();
                        // Cancel the default action
                        e.preventDefault();
                        // Fix bug if user clicks on center map button, the location of the item was saved
                        e.stopPropagation();
                    });
                commonVueHelper.addTooltipToElement(
                    $("#centerMapIcon"),
                    i18n.t("tooltip_centerMapOnSite")
                );
            }

            // Add Opacity slider range button
            if (availableActionsArray.includes("opacitySlider")) {
                // Create slider element
                let sliderControl = `<div id="sliderControlImageOverlay" class="leaflet-range-control leaflet-bar vertical leaflet-control">
                                        <span class="leaflet-range-icon"></span>
                                        <input id="floorImageOverlayOpacitySlider" class="" type="range" orient="vertical" min="0" max="100" step="1" style="outline: none;">
                                    </div>`;
                // Add slider element on the map
                $(".leaflet-control-zoom-fullscreen")
                    .parent()
                    .parent()
                    .append(sliderControl);
                // Add tooltip
                commonVueHelper.addTooltipToElement(
                    $("#sliderControlImageOverlay"),
                    i18n.t("tooltip_setFloorMapOpacity")
                );

                // Get slider input in a variable
                let sliderInput = document.getElementById("floorImageOverlayOpacitySlider");
                // Disable dragging on map when user enter and leave the slider area
                L.DomEvent.on(sliderInput, "mousedown mouseup click touchstart", L.DomEvent.stopPropagation);
                L.DomEvent.on(sliderInput, "mouseenter", () => {
                    this.leafletMap.dragging.disable();
                });
                L.DomEvent.on(sliderInput, "mouseleave", () => {
                    this.leafletMap.dragging.enable();
                });
                L.DomEvent.on(sliderInput, "change", e => {
                    this.floorImageOverlay.setOpacity(e.target.value / 100);
                });
                L.DomEvent.on(sliderInput, "input", e => {
                    this.floorImageOverlay.setOpacity(e.target.value / 100);
                });
            }

            // Enable tooltips
            $('[data-toggle="kt-tooltip"]').tooltip();

            // Code below to fix problem when user clicks on leaflet control bar during indoor area creation or tag/gateway modification
            // We set this boolean to true to detect if the user has clicked on control bar. In this case it must not have consequence to the map
            $(".leaflet-bar").on("mouseenter", () => {
                this.isLeafletControlMouseOver = true;
            });
            $(".leaflet-bar").on("mouseleave", () => {
                this.isLeafletControlMouseOver = false;
            });
        },

        // This function is used to add search bar on the map
        initSearchBar() {
            const provider = new OpenStreetMapProvider({
                params: {
                    'email': this.user.loginEmail, // auth for large number of requests
                    'accept-language': this.user.preferredLanguage
                }
            });

            const searchControl = new GeoSearchControl({
                provider: provider,
                autoComplete: true
            });
            this.leafletMap.addControl(searchControl);
        },

        setCurrentMapLayerOpacity(value) {
            console.log("Component(LocationMap)::setCurrentMapLayerOpacity() - called");
            if (this.currentMapLayer) {
                var opacity = value / 100;
                this.currentMapLayer.setOpacity(opacity);
            }
        },

        // This function is used to add a layer selector on top right corner of the page
        setMapTilesLayer(tileName) {

            console.log("Component(LocationMap)::setMapTilesLayer() - called");

            if (this.currentMapLayer) {
                this.leafletMap.removeLayer(this.currentMapLayer);
            }

            switch (tileName) {
                case "Default":
                    this.currentMapLayer = L.tileLayer(
                        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                        {
                            maxZoom: 22,
                            maxNativeZoom: 18,
                            attribution:
                                '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
                        }
                    );
                    break;

                case "Mapbox":
                    this.currentMapLayer = L.tileLayer(
                        "https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoid2F3YW5vcG91bG9zIiwiYSI6ImNqdGc3aTh4NTBpZzYzeXA2bG44NzAzYXcifQ.ltDQ5A9BesT6oRZmUauOEQ",
                        {
                            maxZoom: 22,
                            maxNativeZoom: 18,
                            attribution:
                                '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
                        }
                    );
                    break;

                case "Satellite":
                    this.currentMapLayer = L.tileLayer(
                        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                        {
                            maxZoom: 22,
                            maxNativeZoom: 18,
                            attribution:
                                '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
                        }
                    );
                    break;

                case "Dark":
                    this.currentMapLayer = L.tileLayer(
                        "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
                        {
                            attribution:
                                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                            subdomains: "abcd",
                            maxZoom: 22,
                            maxNativeZoom: 18
                        }
                    );
                    break;

                case "Grayscale":
                    this.currentMapLayer = L.tileLayer(
                        "https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
                        {
                            id: "mapbox.light",
                            maxZoom: 22,
                            maxNativeZoom: 18,
                            attribution:
                                'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                        }
                    );
                    break;

                case "WorldStreetMap":
                    this.currentMapLayer = L.tileLayer(
                        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
                        {
                            maxZoom: 22,
                            maxNativeZoom: 18,
                            attribution:
                                "Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012"
                        }
                    );
                    break;

                case "WorldTopoMap":
                    this.currentMapLayer = L.tileLayer(
                        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
                        {
                            maxZoom: 22,
                            maxNativeZoom: 18,
                            attribution:
                                "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community"
                        }
                    );
                    break;

                default:
                    this.currentMapLayer = L.tileLayer(
                        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                        {
                            maxZoom: 22,
                            maxNativeZoom: 18,
                            attribution:
                                '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
                        }
                    );
            }

            if (this.leafletMap) {
                this.currentMapLayer.addTo(this.leafletMap);
            }
        },

        // This function is used to add a centered circle on the map. The circle will stay always centered even when map is zoomed or moved.
        initCircleMarker(latitude, longitude) {

            // Create circle that will be always visible and will have alwaus the same width.
            this.circle = L.circleMarker([latitude, longitude], {
                radius: 270,
                color: "#5d78ff",
                fillOpacity: 0.2,
                opacity: 1
            }).addTo(this.leafletMap);

            // Set circle always centered when map is moved or zoomed
            this.leafletMap.on("movestart", () => {
                // Each time the map is moved or zoomed, update store data
                this.setHasMovedFromInitialInit();
            });

            this.leafletMap.on("moveend zoomend", () => {
                // Each time the map is moved or zoomed, update store data
                if (this.leafletMap){
                    this.notifyLocation(
                        this.leafletMap.getCenter().wrap().lng, // wrap to avoid longitude > 180
                        this.leafletMap.getCenter().lat,
                        this.leafletMap.getZoom()
                    );
                    // Update also the center position of the cicle
                    this.circle.setLatLng(this.leafletMap.getCenter());
                }
            });

        },

        // This function is used to add a a centered marker with a small popup with site information
        initMarkerLocationPopup(latitude, longitude) {
            console.log("Component(LocationMap)::initMarkerLocationPopup() - called");
            // Add a centered marker on the map
            var marker = L.marker([latitude, longitude], {
                elevation: 260.0,
                title: this.currentSite.name
            }).addTo(this.leafletMap);
            // Compute country suffix
            let countrySuffix = "";
            let countryData = lookup.byIso(this.currentSite.country);
            if (countryData && countryData.country) {
                countrySuffix = " - " + countryData.country;
            }
            // Add a popup with site name
            marker
                .bindPopup(
                    "<p class='markerLocationPopup'>" +
                        this.currentSite.name +
                        "</p>" +
                        i18n.t(this.currentSite.type) +
                        countrySuffix
                )
                .openPopup();
            // Center map on marker
            this.centerMapOnMarker(marker);
            // Remove fullscreen option
            $(".leaflet-control-zoom-fullscreen.fullscreen-icon").hide();
            // Force view to be 19
            this.leafletMap.setView([latitude, longitude], 19);
        },

        // This function is called when user clicks on the center map button
        onCenterMap() {
            console.log("Component(LocationMap)::onCenterMap() - called");
            if (this.leafletMap) {
                this.leafletMap.setView(
                    [this.currentMapLatitude, this.currentMapLongitude],
                    this.currentMapZoom
                );
            }
        },

        // This function is used to set center of the map to a dedicated marker
        centerMapOnMarker(marker) {
            console.log("Component(LocationMap)::centerMapOnMarker() - called");
            var latLngs = [marker.getLatLng()];
            var markerBounds = L.latLngBounds(latLngs);
            this.leafletMap.fitBounds(markerBounds, {
                maxZoom: this.leafletMap.getZoom()
            });
        },

        // This function is used to set center of the map to a dedicated polygon
        centerMapOnPolygon(polygonLayer) {
            console.log("Component(LocationMap)::centerMapOnPolygon() - called");
            this.leafletMap.fitBounds(polygonLayer.getBounds(), {
                maxZoom: this.leafletMap.getZoom()
            });
        },

        // This function is used to set center of the map to a dedicated image layer
        centerMapOnImageLayer(imageLayer) {
            console.log("Component(LocationMap)::centerMapOnImageLayer() - called");
            this.leafletMap.fitBounds(imageLayer.getBounds(), {
                maxZoom: this.leafletMap.getZoom()
            });
        },

        // This function is called when the user click on Finish button to quit full screen mode
        quitFullscreenButton() {
            console.log("Component(LocationMap)::quitFullscreenButton() - called");
            // Force click on fullscreen button to quit the fullscreen mode
            $(".leaflet-control-zoom-fullscreen.fullscreen-icon")[0].click();
            // Scroll down automatically
            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 500);
        },

        initDrawControl() {

            console.log("Component(LocationMap)::initDrawControl() - called ");

            this.editableLayers = new L.FeatureGroup();
            this.leafletMap.addLayer(this.editableLayers);

            // Now, following the mode, enable some drawing and editing controls
            let options = null;
            switch (this.mode) {
                case "gatewaysLocation":
                    options = gatewaysUtil.initDrawControlForGateway(this);
                    break;
                case "autocalibrationtagsLocation":
                    options = autocalibrationTagsUtil.initDrawControlForAutoCalibrationTag(this);
                    break;
                case "indoorAreasDefinition":
                    options = indoorAreasUtil.initDrawControlForIndoorArea(this);
                    break;
                case "geofencesDefinition":
                    options = geofencesUtil.initDrawControlForGeofence(this);
                    break;
                case "walkwaysDefinition":
                    options = walkwaysUtil.initDrawControlForWalkway(this);
                    break;
                case "wallsDefinition":
                    options = wallsUtil.initDrawControlForWall(this);
                    break;
                default:
                    break;
            }

            console.log("Component(LocationMap)::initDrawControl() - draw control initialized.");

            this.drawControl = new L.Control.Draw(options);
            this.leafletMap.addControl(this.drawControl);

            console.log("Component(LocationMap)::initDrawControl() - adding draw control done.");

            this.editHandler = this.drawControl._toolbars.edit._modes.edit.handler;

            // Register events
            switch (this.mode) {
                case "gatewaysLocation":
                    gatewaysUtil.registerEvents(this);
                    break;
                case "autocalibrationtagsLocation":
                    autocalibrationTagsUtil.registerEvents(this);
                    break;
                case "indoorAreasDefinition":
                    indoorAreasUtil.registerEvents(this);
                    break;
                case "geofencesDefinition":
                    geofencesUtil.registerEvents(this);
                    break;
                case "walkwaysDefinition":
                    walkwaysUtil.registerEvents(this);
                    break;
                case "wallsDefinition":
                    wallsUtil.registerEvents(this);
                    break;
                default:
                    break;
            }

            console.log("Component(LocationMap)::initDrawControl() - register events done.");
        },

        /**
         * Method which allows to add event on leaflet map when all floor assets is greater than number of assets which must be showed.
         */
        enableAssetTrackingLeafletEvent() {
            if (this.floorsAssets && this.floorsAssets.length > this.assetsShowLimit && this.leafletMap) {
                // Add event to leaflet all assets on this floor is greater thant assets show limit
                this.leafletMap.on('zoomend', this.filterAssetsForView);
                this.leafletMap.on("moveend", this.filterAssetsForView);
            }
        },

        /**
         * Method which allows to disable event on leaflet map when all floor assets is greater than number of assets which must be showed.
         */
        disableAssetTrackingLeafletEvent() {
            if (this.leafletMap) {
                // Off event to leaflet all assets on this floor is greater thant assets show limit
                this.leafletMap.off('zoomend', this.filterAssetsForView);
                this.leafletMap.off("moveend", this.filterAssetsForView);
            }
        },
        onResize(event) {
            console.log("Component(locationmap)::onResize() - called", event);
            this.withScreen = $(window).width();
        },
        addToastMessageFullScreen() {
            console.log("Component(locationmap)::addToastMessageFullScreen() - called");
            if (this.isInFullscreen) {
                let toastMessageDiv = document.getElementById("toast-container");
                if (toastMessageDiv) {
                    $('#map').prepend(toastMessageDiv);
                }
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-mapsidebar": mapSideBar,
        "app-mapalert": mapAlert,
        "app-mapsideswitch": mapSideSwitch,
    }
};
</script>

<style src="leaflet-search/dist/leaflet-search.min.css"></style>
<style src="leaflet-geosearch/assets/css/leaflet.css"></style>
<style src="leaflet.fullscreen/Control.FullScreen.css"></style>
<style src="leaflet-draw/dist/leaflet.draw.css"></style>
<style src="leaflet-measure/dist/leaflet-measure.css"></style>
<style src="leaflet-imageoverlay-rotated/Leaflet.ImageOverlay.Rotated.css"></style>
<style src="leaflet.markercluster/dist/MarkerCluster.Default.css"></style>
<style scoped>
.baseTemplate {
    display: contents;
}
.padding2 {
    padding: 2px;
    z-index: 1;
}
.mapDiv {
    z-index: 0;
}
.defaultMapDiv {
    height: 100vh;
}
.analyticWidgetMapDiv {
    height: 250px;
}
.floorMappingMapDiv {
    height: 600px;
}
.troubleshootingHeatmapsMapDiv {
    height: calc(100vh - 250px);
}
.assetsTrackingMapDiv {
    height: calc(100vh - 65px);
}
.assetsLocationHistoryMapDiv {
    height: calc(100vh - 130px);
}
.siteLocationMapDiv {
    height: calc(100vh - 370px);
}
.gatewaysLocationMapDiv {
    height: calc(100vh - 170px);
}
.autotagsLocationMapDiv {
    height: calc(100vh - 170px);
}
.indoorAreasMapDiv {
    height: calc(100vh - 170px);
}
.walkwaysMapDiv {
    height: calc(100vh - 170px);
}
.wallsMapDiv {
    height: calc(100vh - 170px);
}
.geofenceMapDiv {
    height: calc(100vh - 170px);
}
.markerLocationMapDiv {
    height: 350px;
}
.displayInline {
    display: inline;
}
.finishButtonFullscreen {
    position: absolute;
    z-index: 9999;
    bottom: 50px;
    right: 50px;
}
.lastAddedBloc {
    z-index: 9999999;
    color: black;
    position: absolute;
    background: white;
    padding: 0px 5px;
    margin-left: 50px;
    margin-top: 11px;
    border: 2px solid rgba(0, 0, 0, 0.41);
}
</style>
