<template>
    <div class="menuContainer">
        <div class="sideBarTriangle"></div>
        <div class="row addNewFilterDiv">
            <div class="col-sm-12">
                <div class="addNewFilterLabel">{{ $t("map_filters") }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7">
                <div class="whiteLabel">{{ $t("map_enableFiltering") }}</div>
            </div>
            <div class="col-sm-5">
                <span id="configFilters_filterSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="configFilters_filterSwitchInput" @change="onSwitchFiltering" v-model="mapFilteringEnabled" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
        </div>
        <div class="separator"></div>
        <div class="row addNewFilterDiv">
            <div class="col-sm-12">
                <div class="addNewFilterLabel">{{ $t("map_addNewFilter") }}</div>
            </div>
        </div>
        <div>
            <div>
                <div class="btn-group pull-left">
                    <button :disabled="mapFilteringEnabled == false" class="filterButton btn btn-secondary btn-sm dropdown-toggle selectMapTypeDropdownBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ $t(filterTypeLabel) }}
                    </button>
                    <div class="dropdown-menu sideBarCustomDropDown" x-placement="bottom-start">
                        <a @click="onSelectFilterTypeLabel" class="dropdown-item" data-labelvalue="MAP_ASSET_NAME" href="#">{{ $t("MAP_ASSET_NAME") }}</a>
                        <a @click="onSelectFilterTypeLabel" class="dropdown-item" data-labelvalue="MAP_CATEGORY" href="#">{{ $t("MAP_CATEGORY") }}</a>
                        <a @click="onSelectFilterTypeLabel" class="dropdown-item" data-labelvalue="MAP_UNIT" href="#">{{ $t("MAP_UNIT") }}</a>
                        <a @click="onSelectFilterTypeLabel" class="dropdown-item" data-labelvalue="MAP_TAG_TYPE" href="#">{{ $t("MAP_TAG_TYPE") }}</a>
                        <a @click="onSelectFilterTypeLabel" class="dropdown-item" data-labelvalue="MAP_TAG_SERIAL_NUMBER" href="#">{{ $t("MAP_TAG_SERIAL_NUMBER") }}</a>
                        <a @click="onSelectFilterTypeLabel" v-if="this.mode !== 'assetlocationhistory'" class="dropdown-item" data-labelvalue="MAP_AVAILABILITY" href="#">{{ $t("MAP_AVAILABILITY") }}</a>
                        <a v-if="this.siteGeofences && this.siteGeofences.length > 0 && this.mode !== 'assetlocationhistory'" @click="onSelectFilterTypeLabel" class="dropdown-item" data-labelvalue="MAP_GEOFENCE" href="#">{{ $t("MAP_GEOFENCE") }}</a>
                    </div>
                </div>
            </div>

            <div v-if="filterTypeLabel === 'MAP_ASSET_NAME'">
                <div id="filters_dropdown_asset" class="btn-group pull-left">
                    <input :disabled="mapFilteringEnabled == false" type="text" v-model="inputFilterText" @keyup="onInputText" @click="onAssetDropDownOpened" :placeholder="$t(inputPlaceHolderKeyName)" class="filterInputText" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                    <div class="dropdown-menu sideBarCustomDropDown maxHeightDropDownUnit" id="assetList" x-placement="bottom-start">
                        <a v-for="asset in this.sortedAndFilterSiteAssets" :key="asset.id" @click="onSelectAssetFilterLabel" class="dropdown-item" :data-labelvalue="asset.name" href="#">
                            <span>{{ asset.name }}</span>
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="filterTypeLabel === 'MAP_TAG_SERIAL_NUMBER'">
                <div id="filters_dropdown_tag_serial" class="btn-group pull-left">
                    <input :disabled="mapFilteringEnabled == false" type="text" v-model="inputFilterText" @keyup="onInputText" @click="onTagSerialDropDownOpened" :placeholder="$t(inputPlaceHolderKeyName)" class="filterInputText" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                    <div class="dropdown-menu sideBarCustomDropDown maxHeightDropDownUnit" id="tagSerialList" x-placement="bottom-start">
                        <a v-for="tag in this.sortedAndFilterTagSerials" :key="tag.id" @click="onSelectTagSerialFilterLabel" class="dropdown-item" :data-labelvalue="tag.serialNumber" href="#">
                            <span>{{ tag.serialNumber }}</span>
                        </a>
                    </div>
                </div>
            </div>

            <div v-else-if="filterTypeLabel === 'MAP_CATEGORY'">
                <div id="filters_dropdown_categories" class="btn-group pull-left">
                    <button :disabled="mapFilteringEnabled == false" @click="onCategoryDropDownOpened" class="selectTagTypeButton btn btn-secondary btn-sm dropdown-toggle selectMapTypeDropdownBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span v-if="filterCategoryIsDefaultLabel">{{ $t(filterCategoryLabel) }}</span>
                        <span v-else>{{ filterCategoryLabel }}</span>
                    </button>
                    <div class="dropdown-menu sideBarCustomDropDown maxHeightDropDownUnit" x-placement="bottom-start">
                        <input type="search" class="inputSearchFilter" :placeholder="$t('common_searchDots')" v-model="searchCategoryTextValue" />
                        <a v-for="category in this.sortedAndFilteredSiteCategories" :key="category.id" @click="onSelectCategoryFilterLabel" class="dropdown-item" :data-labelvalue="category.name" :data-isdefault="category.isDefault" href="#">
                            <span v-if="category.isDefault">{{ $t(category.name) }}</span>
                            <span v-else>{{ category.name }}</span>
                        </a>
                    </div>
                </div>
            </div>

            <div v-else-if="filterTypeLabel === 'MAP_GEOFENCE'">
                <div id="filters_dropdown_geofences" class="btn-group pull-left">
                    <button :disabled="mapFilteringEnabled == false" @click="onGeofenceDropDownOpened" class="selectTagTypeButton btn btn-secondary btn-sm dropdown-toggle selectMapTypeDropdownBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span>{{ filterGeofenceLabel }}</span>
                    </button>
                    <div class="dropdown-menu sideBarCustomDropDown maxHeightDropDownUnit" x-placement="bottom-start">
                        <input type="search" class="inputSearchFilter" :placeholder="$t('common_searchDots')" v-model="searchGeofenceTextValue" />
                        <a v-for="geofence in this.sortedAndFilteredSiteGeofences" :key="geofence.id" @click="onSelectGeofenceFilterLabel" class="dropdown-item" :data-labelvalue="geofence.name" :data-geofenceid="geofence.id" href="#">
                            <span>{{ geofence.name }}</span>
                        </a>
                    </div>
                </div>
            </div>

            <div v-else-if="filterTypeLabel === 'MAP_AVAILABILITY'">
                <div id="filters_dropdown_availability" class="btn-group pull-left">
                    <button :disabled="mapFilteringEnabled == false" @click="onAvailabilityDropDownOpened" class="selectTagTypeButton btn btn-secondary btn-sm dropdown-toggle selectMapTypeDropdownBtn overflowDots" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ $t(filterAvailabilityLabel) }}
                    </button>
                    <div class="dropdown-menu sideBarCustomDropDown maxHeightDropDownUnit" x-placement="bottom-start">
                        <a @click="onSelectAvailabilityFilterLabel" class="dropdown-item" data-labelvalue="ENUM_AVAILABILITY_AVAILABLE" href="#">{{ $t("ENUM_AVAILABILITY_AVAILABLE") }}</a>
                        <a @click="onSelectAvailabilityFilterLabel" class="dropdown-item" data-labelvalue="ENUM_AVAILABILITY_UNAVAILABLE" href="#">{{ $t("ENUM_AVAILABILITY_UNAVAILABLE") }}</a>
                    </div>
                </div>
            </div>

            <div v-else-if="filterTypeLabel === 'MAP_UNIT'">
                <div id="filters_dropdown_units" class="btn-group pull-left">
                    <button :disabled="mapFilteringEnabled == false" @click="onUnitDropDownOpened" class="selectTagTypeButton btn btn-secondary btn-sm dropdown-toggle selectMapTypeDropdownBtn overflowDots" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ filterUnitLabel.isDefault ? $t(filterUnitLabel.name) : filterUnitLabel.name }}
                    </button>
                    <div class="dropdown-menu sideBarCustomDropDown maxHeightDropDownUnit" x-placement="bottom-start">
                        <input type="search" class="inputSearchFilter" :placeholder="$t('common_searchDots')" v-model="searchUnitTextValue" />
                        <a v-for="unit in this.sortedAndFilteredSiteUnits" :key="unit.id" @click="onSelectUnitFilterLabel" class="dropdown-item" :data-labelvalue="JSON.stringify(unit)" href="#">{{ unit.isDefault ? $t(unit.name) : unit.name }}</a>
                    </div>
                </div>
            </div>

            <div v-else-if="filterTypeLabel === 'MAP_TAG_TYPE'">
                <div id="filters_dropdown_tag_type" class="btn-group pull-left">
                    <button :disabled="mapFilteringEnabled == false" @click="onTagTypeDropDownOpened" class="selectTagTypeButton btn btn-secondary btn-sm dropdown-toggle selectMapTypeDropdownBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span v-if="filterTagTypeIsDefaultLabel">
                            {{ $t("MAP_" + filterTagLabel + "_TAG") }}
                        </span>
                        <span v-else>
                            {{ filterTagLabel }}
                        </span>
                    </button>
                    <div class="dropdown-menu sideBarCustomDropDown" x-placement="bottom-start">
                        <a v-for="(tagType, tagTypeKey, index) in this.mapTagTypesByName" :key="index" @click="onSelectTagFilterLabel" class="dropdown-item" :data-labelvalue="tagTypeKey" :data-isdefault="tagType.isDefault" href="#">
                            <span v-if="tagType.isDefault">{{ $t("MAP_" + tagTypeKey + "_TAG") }}</span>
                            <span v-else>{{ tagTypeKey }}</span>
                        </a>
                    </div>
                </div>
            </div>

            <div>
                <button id="addFilterButton" @click="onAddFilterButton" type="button" class="addFilterButton btn btn-primary btn-sm btn-icon" :disabled="isAddFilterButtonDisabled || mapFilteringEnabled == false"><i class="fa fa-plus marginR5"></i> {{ $t("map_addThisFilter") }}</button>
            </div>
        </div>
        <div class="row addNewFilterDiv" v-if="activeFilters.length !== 0">
            <div class="col-sm-12">
                <div class="addNewFilterLabel">
                    {{ $t("map_activeFilters") }} <span class="kt-badge kt-badge--warning">{{ activeFilters.length }}</span> <span @click="onRemoveAllFilters" v-bind:class="mapFilteringEnabled == false ? 'disabledClassRemoveAll' : ''" v-if="activeFilters.length !== 0" class="clearAllFiltersBtn pull-right">{{ $t("map_clearAllFilters") }}</span>
                </div>
            </div>
        </div>
        <div class="row" v-if="activeFilters.length !== 0">
            <div class="col-sm-12">
                <input v-bind:class="mapFilteringEnabled == false ? 'disabledClass' : ''" v-if="activeFilters.length === 0" type="text" class="noFiltersInput" disabled value="No Filters" />
                <ul v-else id="activefiltersList" class="activeFiltersList">
                    <li v-bind:class="mapFilteringEnabled == false ? 'disabledClass' : ''" v-for="(activeFilter, index) in this.activeFilters" :key="index + activeFilter.keyLabel" class="filterListItem">
                        <div class="col-sm-5 fontW600">{{ $t(activeFilter.keyLabel) }}</div>

                        <div v-if="activeFilter.keyLabel == 'MAP_CATEGORY' && activeFilter.keyValue" class="col-sm-5 filteredValue overflowDots">
                            <span v-if="activeFilter.isDefault">"{{ $t(activeFilter.value) }}"</span>
                            <span v-else>"{{ activeFilter.value }}"</span>
                        </div>
                        <div v-else-if="activeFilter.keyLabel == 'MAP_GEOFENCE' && activeFilter.keyValue" class="col-sm-5 filteredValue overflowDots">"{{ $t(activeFilter.value) }}"</div>
                        <div v-else-if="activeFilter.keyLabel == 'MAP_AVAILABILITY'" class="col-sm-5 filteredValue overflowDots">"{{ $t(activeFilter.value) }}"</div>
                        <div v-else-if="activeFilter.keyLabel == 'MAP_UNIT' && activeFilter.value" class="col-sm-5 filteredValue overflowDots">"{{ activeFilter.value.isDefault ? $t(activeFilter.value.name) : activeFilter.value.name }}"</div>
                        <div v-else-if="activeFilter.keyLabel == 'MAP_TAG_TYPE' && activeFilter.keyValue" class="col-sm-5 filteredValue overflowDots">
                            <span v-if="activeFilter.isDefault">"{{ $t("MAP_" + activeFilter.value + "_TAG") }}"</span>
                            <span v-else>"{{ activeFilter.value }}"</span>
                        </div>
                        <div v-else class="col-sm-5 filteredValue overflowDots">"{{ activeFilter.value }}"</div>

                        <div class="col-sm-2">
                            <button :disabled="mapFilteringEnabled == false" @click="onRemoveActiveFilter" :data-activefilterid="activeFilter.id" type="button" class="removeFilterButton btn btn-primary btn-sm btn-icon">
                                <i class="fa fa-minus"></i>
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const _assetUnit = require("../../../constants/assetUnit");
import i18n from "../../../i18n";
export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            filterTypeLabel: "MAP_ASSET_NAME",
            filterTagLabel: "ASSET",
            filterUnitLabel: "",
            filterCategoryLabel: "ASSET_C_PATIENTS",
            filterGeofenceLabel: "",
            filterGeofenceId: null,
            filterAvailabilityLabel: "ENUM_AVAILABILITY_AVAILABLE",
            filterCategoryIsDefaultLabel: true,
            filterTagTypeIsDefaultLabel: true,
            units: [],
            inputPlaceHolderKeyName: "map_filterPlaceholder_asset",
            isAddFilterButtonDisabled: true,
            inputFilterText: "",
            mapFilteringEnabled: false,
            searchCategoryTextValue: "",
            searchAssetTextValue: "",
            searchTagSerialTextValue: "",
            searchGeofenceTextValue: "",
            searchUnitTextValue: ""
        };
    },
    created: function() {
        console.log("Component(SidebarFiltersMenu)::created() - called");
        this.getAllAssetUnitsOfSite(this.$route.params.siteId);
        this.mapFilteringEnabled = this.$store.getters.isMapFilteringEnabled;
    },
    mounted: function() {
        console.log("Component(SidebarFiltersMenu)::mounted() - called");

        $(".filterInputText").keypress(event => {
            var keycode = event.keyCode ? event.keyCode : event.which;
            if (keycode == "13") {
                this.onAddFilterButton();
                $("#tagSerialList").addClass("listDropdown");
                $("#assetList").addClass("listDropdown");
            }
            event.stopPropagation();
        });

        if (this.site && this.site.BLEGatewaysType === "OMNIACCESS_OEM") {
            this.filterTagLabel = "WRISTBAND";
        }
    },
    destroyed: function() {
        console.log("Component(SidebarFiltersMenu)::destroyed() - called");
    },
    props: {
        siteCategories: {
            type: Array
        },
        siteAssets: {
            type: Array
        },
        siteTagSerials: {
            type: Array
        },
        siteGeofences: {
            type: Array
        },
        mapTagTypesByName: {
            type: Object
        },
        site: {
            type: Object
        },

        mode: {
            type: String
        }
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --

        mapFilteringEnabled: function(value) {
            this.mapFilteringEnabled = value;
        },

        inputFilterText: function() {
            this.onOfHideFilterDropdown();
        },

        assetUnits: function(assetUnits) {
            this.filterUnitLabel = assetUnits[0]; 
            this.units = assetUnits;
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["activeFilters", "currentSite", "assetUnits"]),

        // Computed property to sort and filter array of site units
        sortedAndFilteredSiteUnits() {
            return this.sortedSiteUnits.filter(unit => {
                const unitName = unit.isDefault ? i18n.t(unit.name) : unit.name;
                return unitName.toLowerCase().indexOf(this.searchUnitTextValue.toLowerCase()) != -1;
            });
        },

        // Computed property to sort array of site units
        sortedSiteUnits() {
            function compare(a, b) {
                const nameA = a.isDefault ? i18n.t(a.name).toLowerCase() : a.name.toLowerCase();
                const nameB = a.isDefault ? i18n.t(b.name).toLowerCase() : b.name.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;

                return 0;
            }

            // Sort site categories array
            var sortedArr = this.units.slice().sort(compare);
            return sortedArr;
        },

        // Computed property to sort and filter array of site categories
        sortedAndFilteredSiteCategories() {
            return this.sortedSiteCategories.filter(category => {
                if (category.isDefault) {
                    return (
                        i18n
                            .t(category.name)
                            .toLowerCase()
                            .indexOf(this.searchCategoryTextValue.toLowerCase()) != -1
                    );
                } else {
                    return category.name.toLowerCase().indexOf(this.searchCategoryTextValue.toLowerCase()) != -1;
                }
            });
        },

        sortedAndFilterSiteAssets() {
            return this.sortedSiteAssets.filter(asset => {
                return asset.name.toLowerCase().indexOf(this.inputFilterText.toLowerCase()) != -1;
            });
        },

        sortedAndFilterTagSerials() {
            return this.sortedSiteTagSerials.filter(tag => {
                return tag.serialNumber.toLowerCase().indexOf(this.inputFilterText.toLowerCase()) != -1;
            });
        },

        // Computed property to sort and filter array of site geofences
        sortedAndFilteredSiteGeofences() {
            return this.sortedSiteGeofences.filter(category => {
                return category.name.toLowerCase().indexOf(this.searchGeofenceTextValue.toLowerCase()) != -1;
            });
        },

        // Computed property to sort array of site categories
        sortedSiteCategories() {
            function compare(a, b) {
                var aTranslated, bTranslated;
                if (a.isDefault) {
                    aTranslated = i18n.t(a.name).toLowerCase();
                } else {
                    aTranslated = a.name.toLowerCase();
                }

                if (b.isDefault) {
                    bTranslated = i18n.t(b.name).toLowerCase();
                } else {
                    bTranslated = b.name.toLowerCase();
                }

                if (aTranslated < bTranslated) return -1;
                if (aTranslated > bTranslated) return 1;

                return 0;
            }

            // Sort site categories array
            var sortedArr = this.siteCategories.slice().sort(compare);
            return sortedArr;
        },

        sortedSiteAssets() {
            function compare(a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            }
            let sortedArray = this.siteAssets.slice().sort(compare);
            return sortedArray;
        },

        sortedSiteTagSerials() {
            function compare(a, b) {
                if (a.serialNumber < b.serialNumber) {
                    return -1;
                }
                if (a.serialNumber > b.serialNumber) {
                    return 1;
                }
                return 0;
            }
            let sortedArray = this.siteTagSerials.slice().sort(compare);
            return sortedArray;
        },

        sortedSiteGeofences() {
            function compare(a, b) {
                if ( a.name < b.name ){
                    return -1;
                }
                if ( a.name > b.name ){
                    return 1;
                }
                return 0;
            }

            var sortedArr = this.siteGeofences.slice().sort(compare);
            return sortedArr;
        }

    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["addNewActiveFilter", "removeActiveFilter", "removeAllActiveFilters", "setMapFilteringEnabled", "getAllAssetUnitsOfSite"]),

        onOfHideFilterDropdown() {
            if (this.inputFilterText.length && this.sortedAndFilterSiteAssets.length && this.filterTypeLabel === "MAP_ASSET_NAME") {
                if($("#filters_dropdown_asset .filterInputText[aria-expanded='false']")) {
                    $("#filters_dropdown_asset .filterInputText[aria-expanded='false']").trigger("click");
                }
                $("#assetList").removeClass("listDropdown");
            } else {
                $("#assetList").addClass("listDropdown");
            }

            if (this.inputFilterText.length && this.sortedAndFilterTagSerials.length && this.filterTypeLabel === "MAP_TAG_SERIAL_NUMBER") {
                if ($("#filters_dropdown_tag_serial .filterInputText[aria-expanded='false']")) {
                    $("#filters_dropdown_tag_serial .filterInputText[aria-expanded='false']").click();
                }
                $("#tagSerialList").removeClass("listDropdown");
            } else {
                $("#tagSerialList").addClass("listDropdown");
            }
        },

        // Called when user enable or disable refreshing using Switch selector
        onSwitchFiltering() {
            this.setMapFilteringEnabled(this.mapFilteringEnabled);
        },

        // Called when add filter button is called
        onAddFilterButton() {
            // Add new list item in UI of Active Filters
            let jsonFilter = {
                id: _.uniqueId(), // Only for UI
                keyLabel: this.filterTypeLabel // Only for UI
            };

            // Update value for placeholder
            switch (this.filterTypeLabel) {
                case "MAP_ASSET_NAME": // Input text
                    jsonFilter.value = this.inputFilterText;
                    $("#assetList").removeClass("listDropdown");
                    break;
                case "MAP_CATEGORY": // Select
                    jsonFilter.keyValue = this.filterCategoryLabel;
                    jsonFilter.value = this.filterCategoryLabel;
                    jsonFilter.isDefault = this.filterCategoryIsDefaultLabel;
                    break;
                case "MAP_GEOFENCE": // Select
                    jsonFilter.value = this.filterGeofenceLabel;
                    jsonFilter.keyValue = this.filterGeofenceId;
                    break;
                case "MAP_UNIT": // Select
                    jsonFilter.keyValue = this.filterUnitLabel.name;
                    jsonFilter.value = this.filterUnitLabel;
                    break;
                case "MAP_AVAILABILITY": // Select
                    jsonFilter.keyValue = this.filterAvailabilityLabel;
                    jsonFilter.value = this.filterAvailabilityLabel;
                    break;
                case "MAP_TAG_TYPE": // Select
                    jsonFilter.keyValue = this.filterTagLabel;
                    jsonFilter.value = this.filterTagLabel;
                    jsonFilter.isDefault = this.filterTagTypeIsDefaultLabel;
                    break;
                case "MAP_TAG_SERIAL_NUMBER": // // Input text
                    jsonFilter.value = this.inputFilterText;
                    $("#assetList").removeClass("listDropdown");
                    break;
                default:
                    break;
            }

            this.addNewActiveFilter(jsonFilter);

            // Animate scroll to bottom
            if ($("#activefiltersList") && $("#activefiltersList")[0]) {
                $("#activefiltersList")
                    .stop()
                    .animate(
                        {
                            scrollTop: $("#activefiltersList")[0].scrollHeight
                        },
                        800
                    );
            }

            // Stop propagation events on list of active filters
            let activeFiltersList = document.getElementById("activefiltersList");
            if (activeFiltersList) {
                L.DomEvent.on(activeFiltersList, "mousedown mouseup touchstart", L.DomEvent.stopPropagation);
                L.DomEvent.on(activeFiltersList, "mouseenter", () => {
                    leafletMap.scrollWheelZoom.disable();
                });
                L.DomEvent.on(activeFiltersList, "mouseleave", () => {
                    leafletMap.scrollWheelZoom.enable();
                });
            }
        },

        // Called when user clicks to remove one active filter
        onRemoveActiveFilter(e) {
            let activeFilterIdToRemove = $(e.currentTarget).data().activefilterid;
            this.removeActiveFilter(activeFilterIdToRemove.toString());
        },

        // Called when user click to remove all filters button
        onRemoveAllFilters() {
            this.removeAllActiveFilters();
        },

        // Called when one guy enter something in input text
        onInputText() {
            if (this.inputFilterText) {
                // Text entered => enable add filter button
                this.isAddFilterButtonDisabled = false;
            } else {
                // No text entered => enable add filter button
                this.isAddFilterButtonDisabled = true;
            }
        },

        // This function is called when user click to open or close a asset dropdown
        onAssetDropDownOpened() {
            $("#filters_dropdown_asset")
                .off()
                .on("show.bs.dropdown", () => {
                    leafletMap.scrollWheelZoom.disable();
                    let filtersAssetDropDown = document.getElementById("filters_dropdown_asset");
                    L.DomEvent.on(filtersAssetDropDown, "mousedown mouseup touchstart", L.DomEvent.stopPropagation);
                    L.DomEvent.on(filtersAssetDropDown, "mouseenter", () => {
                        leafletMap.scrollWheelZoom.disable();
                    });
                    L.DomEvent.on(filtersAssetDropDown, "mouseleave", () => {
                        leafletMap.scrollWheelZoom.enable();
                    });
                });

            this.onOfHideFilterDropdown();
        },

        // This function is called when user click to open or close a tag serial dropdown
        onTagSerialDropDownOpened() {
            $("#filters_dropdown_tag_serial")
                .off()
                .on("show.bs.dropdown", () => {
                    leafletMap.scrollWheelZoom.disable();
                    let filtersAssetDropDown = document.getElementById("filters_dropdown_tag_serial");
                    L.DomEvent.on(filtersAssetDropDown, "mousedown mouseup touchstart", L.DomEvent.stopPropagation);
                    L.DomEvent.on(filtersAssetDropDown, "mouseenter", () => {
                        leafletMap.scrollWheelZoom.disable();
                    });
                    L.DomEvent.on(filtersAssetDropDown, "mouseleave", () => {
                        leafletMap.scrollWheelZoom.enable();
                    });
                });

            this.onOfHideFilterDropdown();
        },


        // This function is called when user click to open or close a category dropdown
        onCategoryDropDownOpened() {
            $("#filters_dropdown_categories")
                .off()
                .on("show.bs.dropdown", () => {
                    leafletMap.scrollWheelZoom.disable();
                    let filtersCategoryDropDown = document.getElementById("filters_dropdown_categories");
                    L.DomEvent.on(filtersCategoryDropDown, "mousedown mouseup touchstart", L.DomEvent.stopPropagation);
                    L.DomEvent.on(filtersCategoryDropDown, "mouseenter", () => {
                        leafletMap.scrollWheelZoom.disable();
                    });
                    L.DomEvent.on(filtersCategoryDropDown, "mouseleave", () => {
                        leafletMap.scrollWheelZoom.enable();
                    });
                });
        },

        // This function is called when user click to open or close a geofence dropdown
        onGeofenceDropDownOpened() {
            $("#filters_dropdown_geofences")
                .off()
                .on("show.bs.dropdown", () => {
                    leafletMap.scrollWheelZoom.disable();
                    let filtersGeofencesDropDown = document.getElementById("filters_dropdown_geofences");
                    L.DomEvent.on(filtersGeofencesDropDown, "mousedown mouseup touchstart", L.DomEvent.stopPropagation);
                    L.DomEvent.on(filtersGeofencesDropDown, "mouseenter", () => {
                        leafletMap.scrollWheelZoom.disable();
                    });
                    L.DomEvent.on(filtersGeofencesDropDown, "mouseleave", () => {
                        leafletMap.scrollWheelZoom.enable();
                    });
                });
        },

        onTagTypeDropDownOpened() {
            $("#filters_dropdown_tag_type")
                .off()
                .on("show.bs.dropdown", () => {
                    leafletMap.scrollWheelZoom.disable();
                    let filtersTagTypeDropDown = document.getElementById("filters_dropdown_tag_type");
                    L.DomEvent.on(filtersTagTypeDropDown, "mousedown mouseup touchstart", L.DomEvent.stopPropagation);
                    L.DomEvent.on(filtersTagTypeDropDown, "mouseenter", () => {
                        leafletMap.scrollWheelZoom.disable();
                    });
                    L.DomEvent.on(filtersTagTypeDropDown, "mouseleave", () => {
                        leafletMap.scrollWheelZoom.enable();
                    });
                });
        },

        // This function is called when user click to open or close a availability dropdown
        onAvailabilityDropDownOpened() {
            $("#filters_dropdown_availability")
                .off()
                .on("show.bs.dropdown", () => {
                    leafletMap.scrollWheelZoom.disable();
                    let filtersAvailabilityDropDown = document.getElementById("filters_dropdown_availability");
                    L.DomEvent.on(filtersAvailabilityDropDown, "mousedown mouseup touchstart", L.DomEvent.stopPropagation);
                    L.DomEvent.on(filtersAvailabilityDropDown, "mouseenter", () => {
                        leafletMap.scrollWheelZoom.disable();
                    });
                    L.DomEvent.on(filtersAvailabilityDropDown, "mouseleave", () => {
                        leafletMap.scrollWheelZoom.enable();
                    });
                });
        },

        // This function is called when user click to open or close a unit dropdown
        onUnitDropDownOpened() {
            $("#filters_dropdown_units")
                .off()
                .on("show.bs.dropdown", () => {
                    leafletMap.scrollWheelZoom.disable();
                    let filtersUnitDropDown = document.getElementById("filters_dropdown_units");
                    L.DomEvent.on(filtersUnitDropDown, "mousedown mouseup touchstart", L.DomEvent.stopPropagation);
                    L.DomEvent.on(filtersUnitDropDown, "mouseenter", () => {
                        leafletMap.scrollWheelZoom.disable();
                    });
                    L.DomEvent.on(filtersUnitDropDown, "mouseleave", () => {
                        leafletMap.scrollWheelZoom.enable();
                    });
                });
        },

        // Called when user select
        onSelectFilterTypeLabel(e) {
            this.filterTypeLabel = $(e.target).data().labelvalue;
            // Update value for placeholder
            switch (this.filterTypeLabel) {
                case "MAP_ASSET_NAME": // Input text
                    this.inputPlaceHolderKeyName = "map_filterPlaceholder_asset";
                    this.isAddFilterButtonDisabled = true;
                    this.inputFilterText = "";
                    break;
                case "MAP_CATEGORY": // Select
                    this.isAddFilterButtonDisabled = false;
                    break;
                case "MAP_GEOFENCE": // Select
                    this.isAddFilterButtonDisabled = false;
                    // Select by default the first geofence
                    this.filterGeofenceLabel = this.sortedAndFilteredSiteGeofences[0].name;
                    this.filterGeofenceId = this.sortedAndFilteredSiteGeofences[0].id;
                    break;
                case "MAP_UNIT": // Select
                    this.isAddFilterButtonDisabled = false;
                    break;
                case "MAP_TAG_TYPE": // Select
                    this.isAddFilterButtonDisabled = false;
                    break;
                case "MAP_TAG_SERIAL_NUMBER": // Input text
                    this.inputPlaceHolderKeyName = "map_filterPlaceholder_sn";
                    this.isAddFilterButtonDisabled = true;
                    this.inputFilterText = "";
                    break;
                case "MAP_AVAILABILITY":
                    this.isAddFilterButtonDisabled = false;
                    break;
                default:
                    this.inputPlaceHolderKeyName = "map_filterPlaceholder_asset";
                    this.isAddFilterButtonDisabled = true;
                    break;
            }
        },

        onSelectTagFilterLabel(e) {
            this.filterTagLabel = $(e.currentTarget).data().labelvalue;
            this.filterTagTypeIsDefaultLabel = $(e.currentTarget).data().isdefault;
        },

        onSelectUnitFilterLabel(e) {
            console.log($(e.target).data().labelvalue)
            this.filterUnitLabel = $(e.target).data().labelvalue;
        },

        onSelectAvailabilityFilterLabel(e) {
            this.filterAvailabilityLabel = $(e.target).data().labelvalue;
        },

        onSelectCategoryFilterLabel(e) {
            this.filterCategoryLabel = $(e.currentTarget).data().labelvalue;
            this.filterCategoryIsDefaultLabel = $(e.currentTarget).data().isdefault;
        },

        onSelectAssetFilterLabel(e) {
            this.inputFilterText = $(e.currentTarget).data().labelvalue;
        },

        onSelectTagSerialFilterLabel(e) {
            this.inputFilterText = $(e.currentTarget).data().labelvalue;
        },

        onSelectGeofenceFilterLabel(e) {
            this.filterGeofenceLabel = $(e.currentTarget).data().labelvalue;
            this.filterGeofenceId = $(e.currentTarget).data().geofenceid;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.sideBarTriangle {
    content: "";
    position: absolute;
    left: -8px;
    top: 10px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid rgba(0, 0, 0, 0.7);
    clear: both;
}
.filterButton {
    width: 115px !important;
}
.filterInputText {
    color: #333;
    height: 25px;
    border: none;
    width: 165px;
    float: left;
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: 500;
    outline: none;
}
.filterInputText:disabled {
    background-color: #abaab2;
    border-color: #abaab2;
}
.addFilterButton {
    border: none;
    background: #f8c023;
    text-transform: uppercase;
    font-weight: 600;
    height: 25px !important;
    width: 130px !important;
    padding-top: 1px;
    margin-left: 4px;
    font-size: 10px;
    border-radius: 0px;
}
.addFilterButton:active {
    border: none;
    background: #c7970f !important;
}
.noFiltersInput {
    height: 25px;
    border: none;
    width: 100%;
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 12px;
    outline: none;
}
.selectTagTypeButton {
    margin-left: 5px;
    width: 165px !important;
}
.maxHeightDropDownUnit {
    overflow: auto;
    max-height: 200px;
}
.overflowDots {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.filterListItem {
    display: flex;
    background: white;
    height: 26px;
    padding-top: 4px;
    color: #333;
}
.fontW600 {
    font-weight: 600;
}
.filteredValue {
    color: #1958bd;
    padding-left: 0px;
    padding-right: 0px;
}
.removeFilterButton {
    border: none;
    background: #f31f33b8;
    text-transform: uppercase;
    font-weight: 500;
    height: 20px !important;
    width: 20px !important;
    margin-left: 18px;
    padding-top: 2px;
    margin-top: -1px;
    cursor: pointer;
}
.removeFilterButton:active {
    border: none;
    background: #d23d51 !important;
}
#activefiltersList li:nth-child(even) {
    background: #f9f9f9;
}
.activeFiltersList {
    max-height: 170px;
    overflow: auto;
}
.clearAllFiltersBtn {
    background: #d23d51;
    border-radius: 3px;
    padding: 0px 5px;
    font-size: 10px;
    color: white;
    cursor: pointer;
}
.disabledClass {
    background-color: #abaab2 !important;
    border-color: #abaab2;
    pointer-events: none;
}
.disabledClassRemoveAll {
    background-color: #c76078;
    border-color: #c76078;
    pointer-events: none;
}
.marginR5 {
    margin-right: 5px !important;
}
.listDropdown {
    display: none;
    left: 5px;
}

.inputSearchFilter {
    margin-left: 12px;
    margin-top: -2px;
    margin-bottom: 3px;
    width: 144px;
    height: 22px;
    outline: 0 !important;
    font-size: 11px;
    color: #333;
    padding-left: 4px;
    padding-right: 4px;
    border: 1px solid #a7a7a7;
}
</style>
