<template>
    <div class="menuContainer">
        <div class="sideBarTriangle"></div>
        <div class="row addNewFilterDiv">
            <div class="col-sm-12">
                <div class="addNewFilterLabel">{{ $t("map_layers") }}</div>
            </div>
        </div>
        <div v-if="this.mode === 'troubleshootingHeatmaps'" class="row">
            <div class="col-sm-8">
                <div class="whiteLabel">{{ $t("troubleshooting_heatmaps") }}</div>
            </div>
            <div class="col-sm-4">
                <span id="configLayers_assetsLayerSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="configLayers_assetsLayerSwitchInput" @change="onSwitchHeatmapLayer" v-model="heatmapLayerDisplayed" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
        </div>
        <div v-else class="row">
            <div class="col-sm-8">
                <div class="whiteLabel">{{ $t("asset_assets") }}</div>
            </div>
            <div class="col-sm-4">
                <span id="configLayers_assetsLayerSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="configLayers_assetsLayerSwitchInput" @change="onSwitchAssetsLayer" v-model="assetsLayerDisplayed" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-8">
                <div class="whiteLabel">{{ $t("gtw_gateways") }}</div>
            </div>
            <div class="col-sm-4">
                <span id="configLayers_gatewaysLayerSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="configLayers_gatewaysLayerSwitchInput" @change="onSwitchGatewaysLayer" v-model="gatewaysLayerDisplayed" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-8">
                <div class="whiteLabel">{{ $t("act_autocalibrationtags") }}</div>
            </div>
            <div class="col-sm-4">
                <span id="configLayers_autocalibrationTagsLayerSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="configLayers_autocalibrationTagsLayerSwitchInput" @change="onSwitchAutocalibrationTagsLayer" v-model="autocalibrationTagsLayerDisplayed" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-8">
                <div class="whiteLabel">{{ $t("area_indoorAreas") }}</div>
            </div>
            <div class="col-sm-4">
                <span id="configLayers_indoorAreasLayerSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="configLayers_indoorAreasLayerSwitchInput" @change="onSwitchIndoorAreasLayer" v-model="indoorAreasLayerDisplayed" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
        </div>
        <div v-if="this.mode !== 'troubleshootingHeatmaps'" class="row">
            <div class="col-sm-8">
                <div class="whiteLabel">{{ $t("geofence_geofences") }}</div>
            </div>
            <div class="col-sm-4">
                <span id="configLayers_geofencesLayerSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="configLayers_geofencesLayerSwitchInput" @change="onSwitchGeofencesLayer" v-model="geofencesLayerDisplayed" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
        </div>
        <!--
        <div class="row">
            <div class="col-sm-8">
                <div class="whiteLabel">{{ $t("walkway_walkways") }}</div>
            </div>
            <div class="col-sm-4">
                <span id="configLayers_walkwaysLayerSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="configLayers_walkwaysLayerSwitchInput" @change="onSwitchWalkwaysLayer" v-model="walkwaysLayerDisplayed" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
        </div>
        -->
        <div class="row">
            <div class="col-sm-8">
                <div class="whiteLabel">{{ $t("wall_walls") }}</div>
            </div>
            <div class="col-sm-4">
                <span id="configLayers_wallsLayerSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="configLayers_wallsLayerSwitchInput" @change="onSwitchWallsLayer" v-model="wallsLayerDisplayed" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            heatmapLayerDisplayed: true,
            assetsLayerDisplayed: true,
            gatewaysLayerDisplayed: false,
            autocalibrationTagsLayerDisplayed: false,
            indoorAreasLayerDisplayed: false,
            geofencesLayerDisplayed: false,
            walkwaysLayerDisplayed: false,
            wallsLayerDisplayed: false
        };
    },
    created: function() {
        console.log("Component(SidebarDisplayLayersMenu)::created() - called");
        this.heatmapLayerDisplayed = this.isHeatmapsLayerDisplayed;
        this.assetsLayerDisplayed = this.isAssetsLayerDisplayed;
        this.gatewaysLayerDisplayed = this.isGatewaysLayerDisplayed;
        this.autocalibrationTagsLayerDisplayed = this.isAutocalibrationTagsLayerDisplayed;
        this.indoorAreasLayerDisplayed = this.isIndoorAreasLayerDisplayed;
        this.geofencesLayerDisplayed = this.isGeofencesLayerDisplayed;
        this.walkwaysLayerDisplayed = this.isWalkwaysLayerDisplayed;
        this.wallsLayerDisplayed = this.isWallsLayerDisplayed;
    },
    mounted: function() {
        console.log("Component(SidebarDisplayLayersMenu)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(SidebarDisplayLayersMenu)::destroyed() - called");
    },
    props: {
        mode: String
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        isAssetsLayerDisplayed: function(value) {
            this.assetsLayerDisplayed = value;
        },

        isGatewaysLayerDisplayed: function(value) {
            this.gatewaysLayerDisplayed = value;
        },

        isAutocalibrationTagsLayerDisplayed: function(value) {
            this.autocalibrationTagsLayerDisplayed = value;
        },

        isIndoorAreasLayerDisplayed: function(value) {
            this.indoorAreasLayerDisplayed = value;
        },

        isGeofencesLayerDisplayed: function(value) {
            this.geofencesLayerDisplayed = value;
        },

        isWalkwaysLayerDisplayed: function(value) {
            this.walkwaysLayerDisplayed = value;
        },

        isWallsLayerDisplayed: function(value) {
            this.wallsLayerDisplayed = value;
        },

        isHeatmapsLayerDisplayed: function(value) {
            this.heatmapLayerDisplayed = value;
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["isAssetsLayerDisplayed", "isGatewaysLayerDisplayed", "isAutocalibrationTagsLayerDisplayed", "isIndoorAreasLayerDisplayed", "isGeofencesLayerDisplayed", "isWalkwaysLayerDisplayed", "isWallsLayerDisplayed", "isHeatmapsLayerDisplayed"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["setGatewaysLayerDisplayed", "setAutocalibrationTagsLayerDisplayed", "setIndoorAreasLayerDisplayed", "setGeofencesLayerDisplayed", "setWalkwaysLayerDisplayed", "setWallsLayerDisplayed", "setAssetsLayerDisplayed", "setHeatmapsLayerDisplayed"]),

        // Called when user activate or desactive display of assets layer using Switch selector
        onSwitchAssetsLayer() {
            // store value in vuex store
            this.setAssetsLayerDisplayed(this.assetsLayerDisplayed);
        },

        // Called when user activate or desactive display of gateways layer using Switch selector
        onSwitchGatewaysLayer() {
            // store value in vuex store
            this.setGatewaysLayerDisplayed(this.gatewaysLayerDisplayed);
        },

        // Called when user activate or desactive display of autocalibration tags layer using Switch selector
        onSwitchAutocalibrationTagsLayer() {
            // store value in vuex store
            this.setAutocalibrationTagsLayerDisplayed(this.autocalibrationTagsLayerDisplayed);
        },

        // Called when user activate or desactive display of indoor areas layer using Switch selector
        onSwitchIndoorAreasLayer() {
            // store value in vuex store
            this.setIndoorAreasLayerDisplayed(this.indoorAreasLayerDisplayed);
        },

        // Called when user activate or desactive display of geofences layer using Switch selector
        onSwitchGeofencesLayer() {
            // store value in vuex store
            this.setGeofencesLayerDisplayed(this.geofencesLayerDisplayed);
        },

        // Called when user activate or desactive display of walkways layer using Switch selector
        onSwitchWalkwaysLayer() {
            // store value in vuex store
            this.setWalkwaysLayerDisplayed(this.walkwaysLayerDisplayed);
        },

        // Called when user activate or desactive display of walls layer using Switch selector
        onSwitchWallsLayer() {
            // store value in vuex store
            this.setWallsLayerDisplayed(this.wallsLayerDisplayed);
        },

        // Called when user activate or desactive display of heatmap layer using Switch selector
        onSwitchHeatmapLayer() {
            // store value in vuex store
            this.setHeatmapsLayerDisplayed(this.heatmapLayerDisplayed);
        },
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.sideBarTriangle {
    content: "";
    position: absolute;
    left: -8px;
    top: 10px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid rgba(0, 0, 0, 0.7);
    clear: both;
}
</style>
