<template>
    <ul class="kt-sticky-toolbar customToolbar" :style="this.mode === 'assetlocationhistory' && 'top: 100px;'">
        <li id="sidebaritem_config_link" name="sidebaritem" class="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand">
            <a class="sidebaritemLink" name="sidebaritemLink" @click="onClickConfigMenuItem" href="#"> <i style=" margin-right: 5px; margin-left: -1px; " class="flaticon2-gear"></i> {{ $t("map_configurationTitle") }} <i style=" font-size: 10px; margin-left: 32px; " class="flaticon2-right-arrow"></i> </a>
            <a href="#" @click="onClickConfigMenuItem" class="sidebar_close_btn sidebar_close_btn_config" v-if="this.isConfigMenuDisplayed">
                <i class="flaticon2-delete"></i>
            </a>
            <div id="sidebar_configuration_menu" class="sidebar sidebar_configuration_menu">
                <sidebar-configuration-content :mode="this.mode" v-if="this.isConfigMenuDisplayed"></sidebar-configuration-content>
            </div>
        </li>
        <li id="sidebaritem_filters_link" name="sidebaritem" class="kt-sticky-toolbar__item kt-sticky-toolbar__item--success">
            <a class="sidebaritemLink" name="sidebaritemLink" @click="onClickFiltersMenuItem" href="#"> <i style=" margin-right: 5px; margin-left: -1px; " class="fa fa-filter"></i> {{ $t("map_FiltersTitle") }} <i style=" font-size: 10px; margin-left: 72px; " class="flaticon2-right-arrow"></i> </a>
            <span v-if="activeFilters.length !== 0 && isMapFilteringEnabled" class="kt-badge kt-badge--warning badgeActiveFilters">{{ activeFilters.length }}</span>
            <a href="#" @click="onClickFiltersMenuItem" class="sidebar_close_btn sidebar_close_btn_filters" v-if="this.isFiltersMenuDisplayed">
                <i class="flaticon2-delete"></i>
            </a>
            <div id="sidebar_filters_menu" class="sidebar sidebar_filters_menu">
                <sidebar-filters-menu :mode="this.mode" :mapTagTypesByName="mapTagTypesByNameObj" :siteCategories="siteCategoriesArray" :siteAssets="siteAssetsArray" :siteTagSerials="siteTagsArray" :siteGeofences="siteGeofencesArray" :site="currentSite" v-if="this.isFiltersMenuDisplayed"></sidebar-filters-menu>
            </div>
        </li>
        <li id="sidebaritem_layers_link" v-if="this.showDiplayedItems" name="sidebaritem" class="kt-sticky-toolbar__item kt-sticky-toolbar__item--secondary">
            <a class="sidebaritemLink" name="sidebaritemLink" @click="onClickLayersMenuItem" href="#"> <i style=" margin-right: 5px; margin-left: -1px; " class="flaticon2-layers-1"></i> {{ $t("map_LayersTitle") }} <i v-bind:class="[isFrench ? 'marginL10' : 'marginL19']" style="font-size: 10px;" class="flaticon2-right-arrow"></i> </a>
            <a href="#" @click="onClickLayersMenuItem" class="sidebar_close_btn sidebar_close_btn_layers" v-if="this.isLayersMenuDisplayed">
                <i class="flaticon2-delete"></i>
            </a>
            <div id="sidebar_displaylayers_menu" class="sidebar sidebar_layers_menu">
                <sidebar-display-layers-menu v-if="this.isLayersMenuDisplayed" :mode="this.mode"></sidebar-display-layers-menu>
            </div>
        </li>
    </ul>
</template>

<script>
import SideBarConfigurationContent from "./sidebarconfigurationcontent.vue";
import SideBarDisplayLayersMenu from "./sidebardisplaylayerscontent.vue";
import SideBarFiltersMenu from "./sidebarfilterscontent.vue";
import { mapActions, mapGetters } from "vuex";
import i18n from '../../../i18n';

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            isFrench: this.$i18n.locale === "fr" ? true : false,
            isConfigMenuDisplayed: false,
            isFiltersMenuDisplayed: false,
            isBookmarksMenuDisplayed: false,
            isHistoryMenuDisplayed: false,
            isLayersMenuDisplayed: false,
            showDiplayedItems: false,
            siteCategoriesArray: [],
            siteGeofencesArray: [],
            mapTagTypesByNameObj: {},
            siteAssetsArray: [],
            siteTagsArray: []
        };
    },
    created: function() {
        console.log("Component(mapSideBar)::created() - called");
        // Get asset categories
        this.getSiteAssetCategories(this.siteId);
        // Get site assets
        this.getSiteAssets(this.siteId);
        // Get site tags
        this.getSiteTags(this.siteId);
        // Get site geofences
        this.getSiteGeofences(this.siteId);
        // Get tag types
        this.getTagTypes({siteId: this.siteId});
        // Hide or show the displayed items when the user role is STAFF or CAREGIVER
        var roleName = this.userRoleNameForSite;
        if (roleName) {
            if (roleName != "STAFF" && roleName != "CAREGIVER") {
                this.showDiplayedItems = true;
            } else {
                this.showDiplayedItems = false;
            }
        }
    },
    mounted: function() {
        console.log("Component(mapSideBar)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(mapSideBar)::destroyed() - called");
        this.resetCategoriesState();
        this.resetTagTypesState();
        this.resetGeofencesState();
        this.resetAssetsState();
        this.resetTagsState();
    },
    props: {
        mode: String
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteAssetCategories: function(siteCategories) {
            if (siteCategories) {
                this.siteCategoriesArray = siteCategories;
            }
        },

        siteAssets: function(siteAssets) {
            if (siteAssets) {
                this.siteAssetsArray = siteAssets;
            }
        },
        siteTags: function(siteTags) {
            if (siteTags) {
                this.siteTagsArray = siteTags;
            }
        },

        siteGeofences: function(siteGeofences) {
            if (siteGeofences) {
                this.siteGeofencesArray = siteGeofences;
            }
        },

        tagTypeObjsByName: function(mapTagTypeByName) {
            if (mapTagTypeByName) {
                this.mapTagTypesByNameObj = mapTagTypeByName;
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "siteAssetCategories",
            "currentSite",
            "siteAssets",
            "siteTags",
            "tagTypeObjsByName",
            "activeFilters",
            "isMapFilteringEnabled",
            "userRoleNameForSite",
            "siteGeofences"
        ])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getSiteAssetCategories",
            "getSiteAssets",
            "getSiteTags",
            "getTagTypes",
            "resetCategoriesState",
            "resetTagTypesState",
            "getSiteGeofences",
            "resetGeofencesState",
            "resetAssetsState",
            "resetTagsState"
        ]),

        onClickConfigMenuItem() {
            this.isConfigMenuDisplayed = !this.isConfigMenuDisplayed;
            this.isFiltersMenuDisplayed = false;
            this.isBookmarksMenuDisplayed = false;
            this.isHistoryMenuDisplayed = false;
            this.isLayersMenuDisplayed = false;
        },
        onClickFiltersMenuItem() {
            this.isFiltersMenuDisplayed = !this.isFiltersMenuDisplayed;
            this.isConfigMenuDisplayed = false;
            this.isBookmarksMenuDisplayed = false;
            this.isHistoryMenuDisplayed = false;
            this.isLayersMenuDisplayed = false;
        },
        onClickLayersMenuItem() {
            this.isLayersMenuDisplayed = !this.isLayersMenuDisplayed;
            this.isConfigMenuDisplayed = false;
            this.isBookmarksMenuDisplayed = false;
            this.isHistoryMenuDisplayed = false;
            this.isFiltersMenuDisplayed = false;
        },
        onClickBookmarkMenuItem() {
            this.isBookmarksMenuDisplayed = !this.isBookmarksMenuDisplayed;
            this.isConfigMenuDisplayed = false;
            this.isFiltersMenuDisplayed = false;
            this.isHistoryMenuDisplayed = false;
            this.isLayersMenuDisplayed = false;
        },
        onClickHistoryMenuItem() {
            this.isHistoryMenuDisplayed = !this.isHistoryMenuDisplayed;
            this.isConfigMenuDisplayed = false;
            this.isFiltersMenuDisplayed = false;
            this.isBookmarksMenuDisplayed = false;
            this.isLayersMenuDisplayed = false;
        }

    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "sidebar-configuration-content": SideBarConfigurationContent,
        "sidebar-display-layers-menu": SideBarDisplayLayersMenu,
        "sidebar-filters-menu": SideBarFiltersMenu
    }
};
</script>

<style>
.sideBarCustomDropDown {
    border-radius: 0px;
}
.sideBarCustomDropDown a {
    color: #333;
    height: 26px;
    font-size: 11px;
    font-weight: 500;
    padding: 5px 0px 0px 13px !important;
    border-bottom: 1px solid #ebeaef;
}
.sideBarCustomDropDown a:first-child {
    margin-top: -13px;
}
.sideBarCustomDropDown a:last-child {
    margin-bottom: -13px;
    border-bottom: none;
}
.sidebarSwitchBtn {
    float: right;
    margin-bottom: -10px;
}
.selectMapTypeDropdownBtn {
    width: 170px;
    height: 25px;
    text-align: left;
    color: #333;
    background: white;
    padding: 4px 8px;
    border: 0;
    font-weight: 500;
    border-radius: 0;
}
.selectMapTypeDropdownBtn:after {
    position: absolute !important;
    right: 10px;
    top: 10px;
}
.whiteLabel {
    color: white;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    margin-left: 10px;
}

.whiteLabelRangeSlider {
    color: white;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    margin-left: 10px;
}

@media (max-width: 575px) {
    .whiteLabel {
        margin-top: 15px;
    }
    .whiteLabelRangeSlider {
        margin-top: 10px;
    }
}

@media (max-width: 1024px) {
    .customToolbar {
        top: 185px !important;
    }
    .leaflet-control-container .leaflet-top:first-child {
        top: 55px !important;
    }
    #blocWallsList.leaflet-top.leaflet-left, #blocGeofencesList.leaflet-top.leaflet-left {
        display: none;
    }
}

.marginTop110 {
    margin-top: 110px !important;
}

.customToolbar {
    top: 130px;
    left: 61px;
    margin-left: 4px;
    position: absolute;
    background: none;
    z-index: 40000;
    box-shadow: none;
}
.customToolbar li[name="sidebaritem"] {
    margin-top: -12px !important;
}
.customToolbar li[name="sidebaritem"]:first-child {
    margin-top: 0px !important;
}
.customToolbar li a[name="sidebaritemLink"] {
    background: white !important;
    border-top: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-left: 2px solid rgb(181 179 179) !important;
    border-right: 2px solid rgb(181 179 179) !important;
    width: 157px !important;
    height: 34px !important;
    color: black;
}
.customToolbar li[name="sidebaritem"] a[name="sidebaritemLink"]:hover {
    background: #f4f4f4 !important;
}
.customToolbar li[name="sidebaritem"]:first-child a[name="sidebaritemLink"] {
    border-top: 2px solid rgb(181 179 179) !important;
}
.customToolbar li[name="sidebaritem"]:last-child a[name="sidebaritemLink"] {
    border-bottom: 2px solid rgb(181 179 179) !important;
}
.customToolbar li[name="sidebaritem"] a[name="sidebaritemLink"] i {
    color: black !important;
}
.menuContainer {
    margin: 15px;
}
.menuContainer row:first-child {
    margin-top: 10px;
}
.separator {
    border-bottom: 1px solid #656464;
    margin-bottom: 14px;
    margin-top: 14px;
}
.sidebar {
    width: 340px;
    right: 0px;
    border: 0;
    border-radius: 0;
    padding: 0;
    margin: 0 2px 0 0;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 115px;
    top: 0;
}
.sidebar_configuration_menu {
    margin-top: 10px;
}
.sidebar_layers_menu {
    margin-top: 75px;
}
.sidebar_filters_menu {
    margin-top: 43px;
    width: 450px !important;
}
.sidebar_bookmarks_menu {
    margin-top: 106px;
}
.sidebar_playback_menu {
    margin-top: 139px;
}
.fontWhite {
    color: white;
}
.badgeActiveFilters {
    position: absolute;
    bottom: 47px;
    left: 50px;
    width: 25px;
    height: 25px;
    font-weight: 600;
}
.sidebar_close_btn {
    left: 423px;
    position: absolute;
    z-index: 99;
    width: 20px !important;
    top: 17px;
    background: transparent !important;
}
.sidebar_close_btn_config {
    top: 17px;
}
.sidebar_close_btn_layers {
    top: 79px;
}
.sidebar_close_btn_filters {
    top: 50px;
    left: 532px !important;
}
.sidebar_close_btn i {
    color: white !important;
    font-size: 10px !important;
}

.sidebaritemLink {
    color: black;
    font-size: 12px;
}
.marginL10 {
    margin-left: 10px;
}
.marginL19 {
    margin-left: 19px;
}

.sidebar_layers_menu_asset_location {
    margin-top: 43px;
}
</style>
