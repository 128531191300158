import i18n from "../../../i18n";

/**
 * Init the location map for geofences definition mode
 * @param self
 */
export function initGeofences(self) {

    console.log("Component(LocationMap)::initGeofences() - called");

    self.initActionsBar(["centerMapButton"]);
    self.setMapTilesLayer("OpenStreetMap");
    self.initDrawControl();

    console.log("Component(LocationMap)::initGeofences() - setGeofencesLayerDisplayed now");

    // Force set geofences displayed because if we are in the geofences page
    self.setGeofencesLayerDisplayed(true);

    console.log("Component(LocationMap)::initGeofences() - Hide icons automatically added by leaflet draw library");

    // Hide icons/buttons automatically added by Leaflet Draw library
    $(".leaflet-draw").hide();

    console.log("Component(LocationMap)::initGeofences() - Register events when user will type on keyboard keys");

    // Manage Escape button for cancelling creation/edition of geofence
    let onKeyDown = e => {
        if (e.keyCode == 27) {
            // esc button
            if (self.currentGeofence.state === "START_CREATION") {
                self.setCurrentGeofence({
                    id: "",
                    state: "CANCEL_CREATION"
                });
            } else if (self.currentGeofence.state === "EDIT") {
                self.setCurrentGeofence({
                    id: self.currentGeofence.id,
                    bleMacAddress: self.currentGeofence.bleMacAddress,
                    state: "CANCEL_EDIT"
                });
            } else if (self.currentGeofence.state === "FINALIZE_EDITION") {
                self.setCurrentGeofence({
                    id: "",
                    state: "CANCEL_EDIT"
                });
            } else if (self.currentGeofence.state === "START_MEASURE") {
                self.setCurrentGeofence({
                    id: "",
                    state: "CANCEL_MEASURE"
                });
            }
        }
    };
    L.DomEvent.addListener(document, "keydown", onKeyDown, self.leafletMap);

    console.log("Component(LocationMap)::initGeofences() - end");
}

/**
 * Method called to init draw controls that will be used at geofence creation / edition
 * @param self
 */
export function initDrawControlForGeofence(self) {
    var options = {
        position: "topleft",
        draw: {
            polygon: {
                allowIntersection: false, // Restricts shapes to simple polygons
                drawError: {
                    color: "#e1e100", // Color the shape will turn when intersects
                    message: i18n.t("area_drawingIntersectionsError") // Message that will show when intersect
                },
                shapeOptions: {
                    dashArray: "10, 10",
                    fill: true,
                    fillColor: "#fe57a1",
                    fillOpacity: 0.1,
                    maintainColor: false
                }
            },
            polyline: false,
            circle: false,
            rectangle: false,
            marker: false,
            circlemarker: false
        },
        edit: {
            featureGroup: self.editableLayers,
            remove: false
        }
    };

    return options;
}

export function registerEvents(self) {
    // This listener is only used when user has clicked on start measure button
    self.leafletMap.on(L.Draw.Event.DRAWSTART, e => {
        if (e && e.layerType && e.layerType === "polyline") {
            self.setCurrentGeofence({
                id: "",
                state: "START_MEASURE"
            });
        }
    });

    self.leafletMap.on(L.Draw.Event.CREATED, e => {
        // First check that drawn polygon is not covering another existing geofence
        let polygon = e.layer.toGeoJSON().geometry;
        let geodesicArea = Math.round(Math.round(L.GeometryUtil.geodesicArea(e.layer.getLatLngs()[0]) * 1000) / 1000);
        var geofenceData = {
            id: self.currentGeofence.id,
            polygon: polygon,
            area: geodesicArea,
            state: "SAVE_CREATION"
        };
        self.setCurrentGeofence(geofenceData);
    });
}

/**
 * Method called to do specific actions following the current state of currentGeofence
 * @param self
 * @param action
 */
export function updateCurrentGeofence(self, geofence) {
    if (!geofence.state) {
        // show again measure control
        self.showMeasureControl();
        resetAllLayersToDefaultColor(self);
        if (self.leafletMap) {
            self.leafletMap.closePopup();
        }
    } else {
        if (geofence.state === "START_CREATION") {
            startGeofenceCreation(self);
            self.hideMeasureControl();
        } else if (geofence.state === "SAVE_CREATION") {
            saveNewGeofenceAction(self);
        } else if (geofence.state === "CANCEL_CREATION") {
            cancelNewGeofenceAction(self);
        } else if (geofence.state === "EDIT") {
            startEditGeofenceAction(self);
            self.hideMeasureControl();
        } else if (geofence.state === "SAVE_EDIT") {
            saveEditGeofenceAction(self);
        } else if (geofence.state === "CANCEL_EDIT") {
            cancelEditGeofenceAction(self);
        } else if (geofence.state === "HIGHLIGHT") {
            highlightGeofenceAction(self);
        } else if (geofence.state === "START_MEASURE") {
            startMeasureAction(self);
        } else if (geofence.state === "CANCEL_MEASURE") {
            cancelMeasureAction(self);
        }
    }
}

/**
 * Method called when user start measure action
 * @param self
 */
export function startMeasureAction(self) {
    self.hideMeasureControl();
}

/**
 * Method called when user cancel measure action
 * @param self
 */
export function cancelMeasureAction(self) {
    if (self.measureHandler && self.measureHandler.handler) {
        self.measureHandler.handler.disable();
    }
    self.showMeasureControl();
    self.setCurrentGeofence({
        id: "",
        state: ""
    });
}

/**
 * Method called at page loading or floor selection, to display all geofences of the selected floor
 * @param self
 * @param action
 */
export function generateGeofencesLayer(self) {
    // If geofencesLayer is already defined, clear all layers already existing inside
    if (self.geofencesLayer) {
        self.geofencesLayer.clearLayers();
    } else {
        self.geofencesLayer = new L.FeatureGroup();
    }

    // Get current selected building and floor id
    let currentSelectedBuildingId = self.getSelectedBuildingId;
    let currentSelectedFloorId = self.getSelectedFloorId;

    // Then, for each geofence, create a polygon layer and add it to the global geofences layer
    _.forEach(self.siteGeofences, function (geofence) {
        // Display polygons only for geofences that are actually on the selected building and floor
        if (geofence.building.id === currentSelectedBuildingId && geofence.floor.id === currentSelectedFloorId) {
            // Convert geoJson coordinates to leaflet polygon format
            let polygonCoords = [];
            for (var i = 0; i < geofence.polygon.coordinates[0].length - 1; i++) {
                polygonCoords.push([geofence.polygon.coordinates[0][i][1], geofence.polygon.coordinates[0][i][0]]);
            }

            let geofencePolygon = L.polygon(polygonCoords);

            // Add popup and tooltip to generated polygon
            let areaUnit = geofence.areaUnit === "squareMeters" ? "m<sup>2</sup>" : "ft<sup>2</sup>";
            var popup = new L.Popup().setContent("<strong>" + geofence.name + "</strong><br>" + geofence.area + " " + areaUnit + '<br><span style="color: #999"></span>');
            geofencePolygon.bindPopup(popup);
            geofencePolygon.bindTooltip(geofence.name, {
                className: "customTooltipGeofenceClass",
                permanent: true,
                direction: "center"
            });

            // Define click listener on area and change color of this area when user click on it
            registerClickEventsOnGeofencePolygon(self, geofencePolygon);

            // This line is important: we are setting the id of the geofence polygon layer using geofence id.
            // It will be usefull later to find a layer by id.
            geofencePolygon.options.id = geofence.id;

            // Define polygon display color
            let polygonColor = "#3388ff";
            if (geofence.color) {
                polygonColor = "#" + geofence.color;
            }
            geofencePolygon.setStyle({
                color: polygonColor,
                fillColor: polygonColor,
                className: "geofence_" + geofence.id
            });

            self.geofencesLayer.addLayer(geofencePolygon);
        }
    });
}

/*
 * Function used to reset all markers icon to default icon
 */
function resetAllLayersToDefaultColor(self) {
    if (self.geofencesLayer) {
        // Reset all areas to their original color
        for (var i in self.geofencesLayer._layers) {
            if (self.geofencesLayer._layers.hasOwnProperty(i)) {
                var currentGeofencePolygon = self.geofencesLayer._layers[i];
                // Get style from store
                var geofenceId = currentGeofencePolygon.options.id;
                var geofenceInStore = self.getGeofenceById(geofenceId);
                if (geofenceInStore && geofenceInStore.color) {
                    currentGeofencePolygon.setStyle({
                        color: "#" + geofenceInStore.color,
                        fillColor: "#" + geofenceInStore.color
                    });
                }
            }
        }
    }
}

/*
 * Function used to highlight one area layer on the map
 */
function highlightArea(areaLayer) {
    areaLayer.setStyle({
        color: "#734abd",
        fillColor: "#734abd"
    });
}

/*
 * Function used to register click on area polygon
 */
function registerClickEventsOnGeofencePolygon(self, geofencePolygon) {
    // Register click event on area layer
    geofencePolygon.on("click", function (e) {
        // Reset all area layers
        resetAllLayersToDefaultColor(self);
        // Set selected area
        var geofenceId = e.target.options.id;
        self.setCurrentGeofence({
            id: geofenceId,
            state: "HIGHLIGHT",
            isHighlightFromMap: true
        });
        // Highlight the selected one
        var areaLayer = e.target;
        highlightArea(areaLayer);
    });

    // Register click event on close popup
    geofencePolygon.getPopup().on("remove", () => {
        if (self.leafletMap.hasLayer(geofencePolygon) && self.currentGeofence.state === "HIGHLIGHT") {
            // Reset selected area to empty
            self.setCurrentGeofence({
                id: "",
                state: ""
            });
        }
        // Reset default color only if the marker is always on the map
        // It could be deleted if the remove event has been fired after delete location
        if (self.leafletMap.hasLayer(geofencePolygon)) {
            var geofenceId = geofencePolygon.options.id;
            var geofenceInStore = self.getGeofenceById(geofenceId);
            geofencePolygon.setStyle({
                color: "#" + geofenceInStore.color,
                fillColor: "#" + geofenceInStore.color
            });
        }
    });

    // Register click event on popup open to force again selection of area
    geofencePolygon.on("popupopen", e => {
        // Set selected area
        var geofenceId = e.target.options.id;
        self.setCurrentGeofence({
            id: geofenceId,
            state: "HIGHLIGHT"
        });
    });
}

export function showOrHideGeofencesLayer(self) {

    console.log("Component(LocationMap)::showOrHideGeofencesLayer()) - called");

    if (self.leafletMap && self.geofencesLayer) {
        if (self.isGeofencesLayerDisplayed) {
            self.leafletMap.addLayer(self.geofencesLayer);
        } else {
            self.leafletMap.removeLayer(self.geofencesLayer);
        }
    }
}

/**
 * Method called to start creation of an geofence on the map
 * @param self
 */
export function startGeofenceCreation(self) {
    console.log("Component(LocationMap)::startGeofenceCreation()) - Start drawing polygon !");

    // Close all opened popups on the map
    self.hideAllPopupOnMap();

    self.drawHandler = new L.Draw.Polygon(self.leafletMap, self.drawControl.options.draw.polygon);
    self.drawHandler.enable();
}

/**
 * Method called to save new geofence on the map
 * @param self
 */
export function saveNewGeofenceAction(self) {
    console.log("Component(LocationMap)::saveNewGeofenceAction()) - Start saving new geofence !");
    let geofenceData = {
        id: self.currentGeofence.id,
        name: "",
        color: "3388ff", // blue by default
        siteId: self.siteId,
        buildingId: self.getSelectedBuildingId,
        floorId: self.getSelectedFloorId,
        polygon: self.currentGeofence.polygon, // previously generated in locationMap
        area: self.currentGeofence.area, // previously calculated in locationMap
        areaUnit: "squareMeters",
        state: "FINALIZE_CREATION"
    };

    // Will open confirmation pop-up from geofenceModal.vue component
    // if (self.isInFullscreen === true) {
    //     self.quitFullscreenButton();
    // }
    self.setCurrentGeofence(geofenceData);
}

/**
 * Method called to cancel new geofence on the map
 * @param self
 */
export function cancelNewGeofenceAction(self) {
    console.log("Component(LocationMap)::cancelNewGeofenceAction()) - Cancelling new geofence creation !");

    self.setCurrentGeofence({
        id: "",
        state: ""
    });

    self.drawHandler.disable();
    self.drawHandler = null;

    // Show again floor selector on the map
    self.showFloorMapSelector();
}

/**
 * Method called to start edit of an autocalibration tag on the map
 * @param self
 */
export function startEditGeofenceAction(self) {
    console.log("Component(LocationMap)::startEditGeofenceAction()) - Start editing geofence !");

    // Get full geofence object
    let geofenceObject = self.getGeofenceById(self.currentGeofence.id);
    // Hide floor selector on the map
    self.hideFloorMapSelector();
    // Close all opened popups on the map
    self.hideAllPopupOnMap();
    // Check if the geofence is located on the current selected floor
    if (geofenceObject.building.id === self.getSelectedBuildingId && geofenceObject.floor.id === self.getSelectedFloorId) {
        // Get layer of selected geofence by id
        let currentEditedGeofenceLayer = self.geofencesLayer.getLayerById(self.currentGeofence.id);
        // Center map on highlighted geofence
        self.centerMapOnPolygon(currentEditedGeofenceLayer);
        // First remove all layers in editable Layers because we are able to edit one layer once a time
        self.editableLayers.clearLayers();
        // Add only selected AutoCalibrationTag layer to the list of edited layers
        self.editableLayers.addLayer(currentEditedGeofenceLayer);
        // Force activation of edit mode now ! Only layers available in the editableLayers array will now goes in edit mode in location map.
        self.editHandler.enable();
    } else {
        // We need to switch to the good floor and then edit the geofence
        self.handleSelectFloorMapById(geofenceObject.floor.id);
        // No need to implement callback here,just wait few ms before leaflet update the map.
        setTimeout(() => {
            startEditGeofenceAction(self);
        }, 800);
    }
}

/**
 * Method called to save edit of a geofence on the map
 * @param self
 */
export function saveEditGeofenceAction(self) {
    console.log("Component(LocationMap)::saveEditGeofenceAction()) - Start saving edited geofence !");

    // Get last version of geofence
    let geofence = self.getGeofenceById(self.currentGeofence.id);

    // Generate polygon and area from edited Leaflet layer
    let currentEditedGeofenceLayer = self.geofencesLayer.getLayerById(self.currentGeofence.id);
    let polygon = currentEditedGeofenceLayer.toGeoJSON().geometry;
    let geodesicArea = Math.round(Math.round(L.GeometryUtil.geodesicArea(currentEditedGeofenceLayer.getLatLngs()[0]) * 1000) / 1000);

    var geofenceData = {
        id: geofence.id,
        name: geofence.name,
        color: geofence.color,
        siteId: geofence.site,
        buildingId: geofence.building.id,
        floorId: geofence.floor.id,
        polygon: polygon,
        area: geodesicArea,
        areaUnit: "squareMeters",
        state: "FINALIZE_EDITION"
    };

    // Will open confirmation pop-up from geofenceModal.vue component
    self.setCurrentGeofence(geofenceData);

    self.editHandler.disable();
    // Show again floor selector on the map
    self.showFloorMapSelector();
}

/**
 * Method called to cancel edition of an geofence on the map
 * @param self
 */
export function cancelEditGeofenceAction(self) {
    self.editableLayers.clearLayers();
    // revert layers
    self.editHandler.revertLayers();
    // disable layer editing
    self.editHandler.disable();
    // reinit of current geofence
    self.setCurrentGeofence({
        id: "",
        state: ""
    });
    // CTC-538 Display initial geofences of the selected floor
    generateGeofencesLayer(self);
    // Show again floor selector on the map
    self.showFloorMapSelector();
    // Display back the edited geofence
    showOrHideGeofencesLayer(self);
}

/**
 * Method called to highlight geofence on the map
 * @param self
 */
export function highlightGeofenceAction(self) {
    console.log("Component(LocationMap)::highlightGeofenceAction()) - Highlight geofence !");

    // Defense: If user is currently creating a geofence and click on another one into the list,
    // the highlight feature will be called. In that case, we need to disable the draw handler.
    if (self.drawHandler) {
        self.drawHandler.disable();
        self.drawHandler = null;
    }

    // Defense: If user is currently measuring a distance and click to highlight an area
    if (self.measureHandler && self.measureHandler.handler) {
        self.measureHandler.handler.disable();
    }

    // Defense: If user is currently updating a polygon of an existing geofence and click on another one into the list,
    // the highlight feature will be called. In that case, we need to disable the edit draw handler.
    if (self.editHandler) {
        // revert layers
        self.editHandler.revertLayers();
        // disable layer editing
        self.editHandler.disable();
        // Show again the measure control
        self.showMeasureControl();
    }

    let geofenceObject = self.getGeofenceById(self.currentGeofence.id);
    // Reset all markers icons to default one
    resetAllLayersToDefaultColor(self);
    // Check if the geofence is located on the current selected floor
    if (geofenceObject.building.id === self.getSelectedBuildingId && geofenceObject.floor.id === self.getSelectedFloorId) {
        // Map currently display the good floor, we just need to highlight the geofence
        // Get layer of selected geofence by id
        let geofenceLayer = self.geofencesLayer.getLayerById(self.currentGeofence.id);
        geofenceLayer.openPopup();
        // Highlight selected area
        highlightArea(geofenceLayer);
        // Center map on highlighted area
        self.centerMapOnPolygon(geofenceLayer);
    } else {
        // We need to switch to the good floor and then highlight the geofence
        self.handleSelectFloorMapById(geofenceObject.floor.id);
        // No need to implement callback here,just wait few ms before leaflet update the map.
        setTimeout(() => {
            let geofenceLayer = self.geofencesLayer.getLayerById(self.currentGeofence.id);
            geofenceLayer.openPopup();
            // Highlight selected area
            highlightArea(geofenceLayer);
            // Center map on highlighted area
            self.centerMapOnPolygon(geofenceLayer);
        }, 800);
    }
}