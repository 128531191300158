import HeatmapOverlay from "heatmap.js/plugins/leaflet-heatmap/leaflet-heatmap";
var heatmapLayerOptions = {
    // radius should be small ONLY if scaleRadius is true (or small radius is intended)
    "radius": 15,
    "maxOpacity": .8,
    // scales the radius based on map zoom
    "scaleRadius": false,
    // if set to false the heatmap uses the global maximum for colorization
    // if activated: uses the data maximum within the current map boundaries
    //   (there will always be a red spot with useLocalExtremas true)
    "useLocalExtrema": true,
    // which field name in your data represents the latitude - default "lat"
    latField: 'lat',
    // which field name in your data represents the longitude - default "lng"
    lngField: 'long',
    // which field name in your data represents the data value - default "value"
    valueField: 'count'
};
/**
 * Init the location map for assets location heatmap
 * @param self
 */
export function initAnalytics(self) {
    console.log("Component(LocationMap)::initAnalytics() - called");

    self.initActionsBar(["centerMapButton"]);
    self.setMapTilesLayer(self.currentMapTileLayerName); //background tiles layer (e.g. openStreetMap, mapbaox, ...)
    self.setCurrentMapLayerOpacity(self.currentMapTilesLayerOpacity);

}

/**
 * Method called to display assets heatmap layer
 * @param self
 */
export function generateAssetsHeatmapLayer(self, isNewDataReceived) {

    if (self.analyticsWidgetHeatmapData && self.leafletMap){
        let mapZoom = self.leafletMap.getZoom();

        if (mapZoom != self.currentHeatmapZoomLevel || isNewDataReceived){

            if (self.leafletMap.hasLayer(self.assetsHeatmapLayer)){
                self.leafletMap.removeLayer(self.assetsHeatmapLayer);
            }

            switch (mapZoom){
                case 16:
                    heatmapLayerOptions.radius = 8;
                    break;
                case 17:
                    heatmapLayerOptions.radius = 15;
                    break;
                case 18:
                    heatmapLayerOptions.radius = 25;
                    break;
                case 19:
                    heatmapLayerOptions.radius = 50;
                    break;
                case 20:
                    heatmapLayerOptions.radius = 100;
                    break;
                case 21:
                    heatmapLayerOptions.radius = 180;
                    break;
                case 22:
                    heatmapLayerOptions.radius = 290;
                    break;
                default:
                    heatmapLayerOptions.radius = 14;
                    break;
            }

            self.currentHeatmapZoomLevel = mapZoom;
            self.assetsHeatmapLayer = new HeatmapOverlay(heatmapLayerOptions);

            var widgetData = self.analyticsWidgetHeatmapData;
            if (!widgetData || !Array.isArray(widgetData.metrics)) {
                return;
            }

            var selectedBuildingId = self.getSelectedBuildingId;
            var selectedFloorId = self.getSelectedFloorId;
            var heatmapInfo = widgetData.metrics.filter(function(e) {
                return e.layer && e.layer.floorId === selectedFloorId && e.layer.buildingId === selectedBuildingId && Array.isArray(e.data);
            })[0] || null;

            if (!heatmapInfo) {
                return;
            }
            var heatmapData = {
                max: Math.max(heatmapInfo.count, 1),
                data: heatmapInfo.data
            }

            self.assetsHeatmapLayer.setData(heatmapData);

            self.leafletMap.addLayer(self.assetsHeatmapLayer);
            $(".heatmap-canvas").parent().css("z-index", "9999999");

            self.leafletMap.on("zoomend", () => {
                generateAssetsHeatmapLayer(self, false);
            });

        }

    }

}
