import HeatmapOverlay from "heatmap.js/plugins/leaflet-heatmap/leaflet-heatmap";
var heatmapLayerOptions = {
    // radius should be small ONLY if scaleRadius is true (or small radius is intended)
    "radius": 14,
    "maxOpacity": .8,
    // scales the radius based on map zoom
    "scaleRadius": false, 
    // if set to false the heatmap uses the global maximum for colorization
    // if activated: uses the data maximum within the current map boundaries 
    //   (there will always be a red spot with useLocalExtremas true)
    "useLocalExtrema": false,
    // which field name in your data represents the latitude - default "lat"
    latField: 'lat',
    // which field name in your data represents the longitude - default "lng"
    lngField: 'long',
    // which field name in your data represents the data value - default "value"
    valueField: 'count'
};

/**
 * Init the location map for assets location heatmap
 * @param self
 */
export function initTroubleshootingHeatmaps(self) {
    console.log("Component(LocationMap)::initAnalytics() - called");
    
    self.initActionsBar(["centerMapButton"]);
    self.setMapTilesLayer(self.currentMapTileLayerName); //background tiles layer (e.g. openStreetMap, mapbaox, ...)
    self.setCurrentMapLayerOpacity(self.currentMapTilesLayerOpacity);
}

/**
 * Show or Hide the heatmap layer
 * @param self
 */
export function showOrHideHeatmapLayer(self) {
    console.log("Component(LocationMap)::showOrHideHeatmapLayer() - called");
    if (self.leafletMap && self.troubleshootHeatmapLayer) {
        if (self.isHeatmapsLayerDisplayed) {
            self.leafletMap.addLayer(self.troubleshootHeatmapLayer);
        } else {
            self.leafletMap.removeLayer(self.troubleshootHeatmapLayer);
        }
    }
}

/**
 * Remove heatmap layer if it exists
 * @param self
 */
export function removeHeatmapLayer(self) {
    console.log("Component(LocationMap)::removeHeatmapLayer() - called");
    if (self.leafletMap && self.troubleshootHeatmapLayer && self.leafletMap.hasLayer(self.troubleshootHeatmapLayer)){
        self.leafletMap.removeLayer(self.troubleshootHeatmapLayer);
    }
}

// This function use to render the scale color bar to a div with divId
// Grades is a list of number for the legend label
// Colors is a list of color corresponding to the number in grades
// Both grades and colors are array and should contain the same number of element
export function initScaleBar(divId, grades, colors, unit){
    console.log("Component(LocationMap)::initScaleBar() - called");
    if (!$('#'+divId).is(':empty')) {
        return;
    }
    var div = document.getElementById(divId);

    // Function to render color by grade number
    function getColor(d) {
        return d > grades[6] ? colors[6] : d > grades[5] ? colors[5] : d > grades[4] ? colors[4] : d > grades[3] ? colors[3] :d > grades[2] ? colors[2] :d > grades[1] ? colors[1] :colors[0];
    }
    var colorBar = document.createElement("div");
    colorBar.className = "legendScaleBar ml-1";

    // loop through our density intervals and generate a label with a colored square for each interval
    for (var i = 0; i < grades.length -1; i++) {
        colorBar.innerHTML += '<i style="background:' + getColor(grades[i] + 1) + '"></i>';
    }

    var textBar = document.createElement("div");
    textBar.className = "legendScaleBar d-flex justify-content-between";
    textBar.innerHTML += '<span>-</span>';

    for (var i = 1; i < grades.length; i++) {
        textBar.innerHTML += '<span>' + grades[i] + unit + (grades[i + 1] ? '' : '+') + '</span>';
    }

    div.appendChild(colorBar);
    div.appendChild(textBar);
}

/**
 * Method called to display assets heatmap layer
 * @param self
 */
export function generateTroubleshootingHeatmapLayer(self, forceUpdate) {
    
    if (self.troubleshootingHeatmapData && self.troubleshootingHeatmapData.length > 0) {

        if ( (self.leafletMap.getZoom() != self.currentHeatmapZoomLevel) || forceUpdate ){

            switch (self.leafletMap.getZoom()){
                case 16:
                    heatmapLayerOptions.radius = 1;
                    break;
                case 17:
                    heatmapLayerOptions.radius = 2;
                    break;
                case 18:
                    heatmapLayerOptions.radius = 4;
                    break;
                case 19:
                    heatmapLayerOptions.radius = 8;
                    break;
                case 20:
                    heatmapLayerOptions.radius = 16;
                    break;
                case 21:
                    heatmapLayerOptions.radius = 32;
                    break;
                case 22:
                    heatmapLayerOptions.radius = 64;
                    break;
                default:
                    heatmapLayerOptions.radius = 14;
                    break;
            }

            if (self.leafletMap.hasLayer(self.troubleshootHeatmapLayer)){
                self.leafletMap.removeLayer(self.troubleshootHeatmapLayer);
            }

            self.currentHeatmapZoomLevel = self.leafletMap.getZoom();
            self.troubleshootHeatmapLayer = new HeatmapOverlay(heatmapLayerOptions);
            
            // Uncommend this to have markzers with the RSSI value displayed on the heatmap
            // for (var i=0; i<self.troubleshootingHeatmapData.length; i++){
            //     L.marker([self.troubleshootingHeatmapData[i].lat, self.troubleshootingHeatmapData[i].long]).bindPopup("RSSI:"+ Number(self.troubleshootingHeatmapData[i].count -43)).addTo(self.leafletMap);
            // }

            //var maxObj = _.maxBy(self.troubleshootingHeatmapData, 'count');
            //var minObj = _.minBy(self.troubleshootingHeatmapData, 'count');

            // Heatmap based on attenuation data (rssi between -30 and +25)
            var heatmapData = {
                min: -35,
                max: 15,
                data: self.troubleshootingHeatmapData
            }

            self.troubleshootHeatmapLayer.setData(heatmapData);

            self.leafletMap.addLayer(self.troubleshootHeatmapLayer);
            $(".heatmap-canvas").parent().css("z-index", "9999999");

            if (!self.isHeatmapsLayerDisplayed && self.leafletMap.hasLayer(self.troubleshootHeatmapLayer)){
                self.leafletMap.removeLayer(self.troubleshootHeatmapLayer);
            }

            self.leafletMap.on("zoomend", () => {
                generateTroubleshootingHeatmapLayer(self);
            });

            // Create grades and colors values for heatmapjs scale bar
            var grades = [-1000, -85, -70, -60, -55, -50, -40];
            var colors = ['#ffffff', '#78a2f3', '#6ef567', '#f2fa33', '#f7a733', '#f73c33'];
            initScaleBar('scaleBar', grades, colors, 'dBm');

        }

    }

}
