<template>
    <div id="mapSideSwitch" class="map-side-switch leaflet-top leaflet-left" style="display:none; pointer-events: initial;">
        <div class="row leaflet-range-control leaflet-control">
            <div class="mleft10">
                <span class="text-dark font-weight-normal filterPadding">{{ $t("area_indoorAreas") }}</span>
                <span id="indoor_indoorSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="indoor_indoorLayerSwitchInput" @change="onSwitchIndoorAreasLayer" v-model="indoorAreasLayerDisplayed" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
            <div class="mleft10">
                <span class="text-dark font-weight-normal filterPadding">{{ $t("geofence_geofences") }}</span>
                <span id="geofence_geofenceSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="geofence_geofenceLayerSwitchInput" @change="onSwitchGeofencesLayer" v-model="geofencesLayerDisplayed" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
            <div class="mleft10">
                <span class="text-dark font-weight-normal filterPadding">{{ $t("wall_walls") }}</span>
                <span id="wall_wallsSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="wall_wallsLayerSwitchInput" @change="onSwitchWallsLayer" v-model="wallsLayerDisplayed" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
            <div class="mleft10">
                <span class="text-dark font-weight-normal filterPadding">{{ $t("gtw_bleGateways") }}</span>
                <span id="bleGateway_bleGatewaysSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="bleGateway_bleGatewaysLayerSwitchInput" @change="onSwitchBLEGatewaysLayer" v-model="bleGatewaysLayerDisplayed" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
            <div class="mleft10">
                <span class="text-dark font-weight-normal filterPadding">{{ $t("act_autocalibrationtags") }}</span>
                <span id="autocalibrationtag_autocalibrationtagsSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="autocalibrationtag_autocalibrationtagsLayerSwitchInput" @change="onSwitchAutocalibrationtagsLayer" v-model="autocalibrationtagsLayerDisplayed" type="checkbox" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import L from "leaflet";

export default {
    data() {
        return {
            indoorAreasLayerDisplayed: false,
            geofencesLayerDisplayed: false,
            autocalibrationtagsLayerDisplayed: false,
            bleGatewaysLayerDisplayed: false,
            wallsLayerDisplayed: false,
            userAgent: navigator.userAgent,
            isSafariBrowser: false
        };
    },
    created: function() {
        // this.getSiteIndoorAreas(this.siteId);
        // this.getSiteBLEGateways(this.siteId);
        // this.getSiteAutoCalibrationTags(this.siteId);
        // this.getSiteGeofences(this.siteId);
    },
    mounted: function() {
        console.log("Component(mapSideBar)::mounted() - called");

        if (this.userAgent.toLowerCase().indexOf('safari') > 1 && this.userAgent.toLowerCase().indexOf('chrome') == -1) {
            this.isSafariBrowser = true;
        } else {
            this.isSafariBrowser = false;
        }
    },
    destroyed: function() {
        console.log("Component(mapSideBar)::destroyed() - called");
        
        this.setIndoorAreasLayerDisplayed(false);
        this.setGeofencesLayerDisplayed(false);
        this.setGatewaysLayerDisplayed(false);
        this.setAutocalibrationTagsLayerDisplayed(false);
        this.setWallsLayerDisplayed(false);

        this.indoorAreasLayerDisplayed = false;
        this.geofencesLayerDisplayed = false;
        this.autocalibrationtagsLayerDisplayed = false;
        this.bleGatewaysLayerDisplayed = false;
        this.wallsLayerDisplayed = false;
        // this.isSafariBrowser = false;
    },
    props: {
        mode: String,

    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        isIndoorAreasLayerDisplayed: function(value) {
            this.indoorAreasLayerDisplayed = value;
        },
    
        isGeofencesLayerDisplayed: function(value) {
            this.geofencesLayerDisplayed = value;
        },

        isWallsLayerDisplayed: function(value) {
            this.wallsLayerDisplayed = value;
        },

        isAutocalibrationTagsLayerDisplayed: function(value) {
            this.autocalibrationtagsLayerDisplayed = value;
        },

        isGatewaysLayerDisplayed: function(value) {
            this.bleGatewaysLayerDisplayed = value;
        },

        isLoading: function(isLoading) {
            if(isLoading === false) {
                this.initMapSideSwitch();
                
            }
        }

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "isIndoorAreasLayerDisplayed",
            "isGeofencesLayerDisplayed",
            "isWallsLayerDisplayed",
            "isAutocalibrationTagsLayerDisplayed",
            "isGatewaysLayerDisplayed",
            "isLoading"
        ])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "setIndoorAreasLayerDisplayed",
            "setGeofencesLayerDisplayed",
            "setGatewaysLayerDisplayed",
            "setAutocalibrationTagsLayerDisplayed",
            "setWallsLayerDisplayed",
            "getSiteIndoorAreas",
            "getSiteBLEGateways",
            "getSiteAutoCalibrationTags",
            "getSiteGeofences"
        ]),

        // Called when user activate or de-active display of indoor areas layer using Switch selector
        onSwitchIndoorAreasLayer() {
            // store value in vuex store
            this.setIndoorAreasLayerDisplayed(this.indoorAreasLayerDisplayed);

        },

        onSwitchGeofencesLayer() {
            //store value in vuex store
            this.setGeofencesLayerDisplayed(this.geofencesLayerDisplayed);
        },

        onSwitchBLEGatewaysLayer() {
            //store value in vuex store
            this.setGatewaysLayerDisplayed(this.bleGatewaysLayerDisplayed);
        },

        onSwitchAutocalibrationtagsLayer() {
            //store value in vuex store
            this.setAutocalibrationTagsLayerDisplayed(this.autocalibrationtagsLayerDisplayed);
        },

        onSwitchWallsLayer() {
            //store value in vuex store
            if (this.isSafariBrowser) {
                this.setWallsLayerDisplayed(!this.wallsLayerDisplayed);
            } else {
                this.setWallsLayerDisplayed(this.wallsLayerDisplayed);
            }
        },

        initMapSideSwitch() {
            $(".map-side-switch").show();
            $(".leaflet-control-container").append($(".map-side-switch"));

            let mapSideSwitch = document.getElementById(
                "mapSideSwitch"
            );
            L.DomEvent.on(
                mapSideSwitch,
                "mousedown mouseup touchstart",
                L.DomEvent.stopPropagation
            );
            L.DomEvent.on(mapSideSwitch, "mouseenter", () => {
                if (this.leafletMap) {
                    this.leafletMap.dragging.disable();
                }
            });
            L.DomEvent.on(mapSideSwitch, "mouseleave", () => {
                if(this.leafletMap) {
                    this.leafletMap.dragging.enable();
                }
            });
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scope>
.filterPadding {
    padding-top: 4px;
    display: inline-flex;
    padding-right: 10px;
}

.mleft10 {
    margin-left: 10px;
}
.map-side-switch {
    top: 5px;
    left: 60px;
    right: 25px;
    z-index: 401;
    width: auto;
    background: white;
    padding: 0px 5px 10px 10px;
    margin: 5px 0px 5px 0px;
    pointer-events: auto;
}

</style>
