import i18n from "../../../i18n";
import commonVueHelper from "../../../helpers/commonVueHelper";
import axiosBackend from "../../../axios-backend";

/**
 * Init the location map for gateways location mode
 * @param self
 */
export function initAssetsTracking(self) {

    console.log("Component(LocationMap)::initAssetsTracking() - called");

    self.initActionsBar(["centerMapButton"]);
    self.setMapTilesLayer(self.currentMapTileLayerName);
    self.setCurrentMapLayerOpacity(self.currentMapTilesLayerOpacity);

    // Force set assets layer displayed because if we are in the asset tracking page
    self.setAssetsLayerDisplayed(true);
}

/**
 * Method called to disable drag events when user is in side bar menu
 * @param self
 * @param action
 */
export function disableDragOnSideBarContentMenu(self) {
    // Get slider input in a variable
    let sideBarConfigMenu = document.getElementById("sidebar_configuration_menu");
    if (sideBarConfigMenu){
        // Disable dragging on map when user enter and leave the slider area
        L.DomEvent.on(sideBarConfigMenu, "mousedown mouseup touchstart", L.DomEvent.stopPropagation);
        L.DomEvent.on(sideBarConfigMenu, "mouseenter", () => {
            self.leafletMap.dragging.disable();
        });
        L.DomEvent.on(sideBarConfigMenu, "mouseleave", () => {
            self.leafletMap.dragging.enable();
        });
    }

    // Get slider input in a variable
    let sideBarLayersMenu = document.getElementById("sidebar_displaylayers_menu");
    if (sideBarLayersMenu){
        // Disable dragging on map when user enter and leave the slider area
        L.DomEvent.on(sideBarLayersMenu, "mousedown mouseup touchstart", L.DomEvent.stopPropagation);
        L.DomEvent.on(sideBarLayersMenu, "mouseenter", () => {
            self.leafletMap.dragging.disable();
        });
        L.DomEvent.on(sideBarLayersMenu, "mouseleave", () => {
            self.leafletMap.dragging.enable();
        });
    }

    // Get slider input in a variable
    let sideBarFiltersMenu = document.getElementById("sidebar_filters_menu");
    if (sideBarFiltersMenu){
        // Disable dragging on map when user enter and leave the slider area
        L.DomEvent.on(sideBarFiltersMenu, "mousedown mouseup touchstart", L.DomEvent.stopPropagation);
        L.DomEvent.on(sideBarFiltersMenu, "mouseenter", () => {
            self.leafletMap.dragging.disable();
        });
        L.DomEvent.on(sideBarFiltersMenu, "mouseleave", () => {
            self.leafletMap.dragging.enable();
        });
    }
}

/**
 * Method called to display assets layer
 * @param self
 */
export function generateAssetsLayer(self){
    // Get asset count
    const countAssets = self.viewAssets.length || 0;
    // Set boolean to show or not asset in group
    const showInGroup = countAssets && self.assetsShowLimit && countAssets > self.assetsShowLimit ? true : false;
    // Now clear asset layer if exists
    if (self.assetsLayer) {
        self.leafletMap.removeLayer(self.assetsLayer);
    }
    // Initialise new layer according to number of data
    if (showInGroup) {
        self.assetsLayer = new L.markerClusterGroup.layerSupport({
            spiderLegPolylineOptions: {opacity: 0},
            spiderfyOnMaxZoom: true,
            removeOutsideVisibleBounds: true,
            polygonOptions: {
                stroke: false,
                color: 'transparent'
            }});
        self.assetsCircleRadiusLayer = new L.FeatureGroup();
        self.showInClusterMode = true;
    } else {
        self.assetsLayer = new L.FeatureGroup();
        self.assetsCircleRadiusLayer = new L.FeatureGroup();
        self.showInClusterMode = false;
    }
    // Add new leayer in map
    if (self.leafletMap && self.assetsLayer) {
        self.leafletMap.addLayer(self.assetsLayer);
        self.enableAssetTrackingLeafletEvent();
    }
    // Then, for each asset, create a marker layer and add it to the global gateways layer
    _.forEach(self.viewAssets, asset => {
        let assetMarker = L.marker([asset.tag.location.coordinates[0], asset.tag.location.coordinates[1]], getAssetIcon(asset));

        let tooltipHTML = getTooltipContentAccordingRowValues(self, asset);

        var tooltipObj = {
            className: "assetTrackingTooltip",
            permanent: true,
            direction: "right"
        };

        if (asset.assetcategory.iconSize === "SIZE_SMALL") {
            tooltipObj.offset = new L.Point(15, -35);
        } else if (asset.assetcategory.iconSize === "SIZE_LARGE") {
            tooltipObj.offset = new L.Point(19, -50);
        }
        assetMarker.bindTooltip(tooltipHTML, tooltipObj);

        // This line is important: we are setting the id of the marker layer using asset id.
        // It will be usefull later to find a layer by id.
        assetMarker.options.id = asset.id;

        // to do, listen click on marker to open custom
        assetMarker.on("click", function (e) {
            let assetId = e.target.options.id;
            // Set currentOpenedAsset is store
            self.setCurrentOpenedAssetIdInDashboard("");
            self.setCurrentOpenedAssetIdInDashboard(assetId);
        });

        // Add marker to the map
        self.assetsLayer.addLayer(assetMarker);
        //console.log("generateAssetsLayer assetsLayer.addLayer " + asset.name);

        // Show/hide asset label according to user preferences (shown by default)
        if (!self.isAssetsLabelsDisplayed) {
            assetMarker.closeTooltip();
        }

        // Code to associate a small circle around the marker. Usefull for debug mode.
        let radiusCircleLayer = L.circle([asset.tag.location.coordinates[0], asset.tag.location.coordinates[1]], 3, {
            color: "red"
        });
        self.assetsCircleRadiusLayer.addLayer(radiusCircleLayer);

        // Check if this marker has to be highlighed on the map.
        if (self.arrayOfAssetsMacAddressToHighlight && asset.tag && asset.tag.macAddress && self.arrayOfAssetsMacAddressToHighlight.includes(asset.tag.macAddress)) {
            // Bind a popup to highlighted marker.
            // If asset is displayed in cluster mode burst cluster to show this asset
            if (self.showInClusterMode) {
                // Disable leaflet event
                self.disableAssetTrackingLeafletEvent();
                // Burst the cluster of this marker
                assetMarker.__parent.spiderfy();
                assetMarker
                    .bindPopup("<strong>" + asset.name + "</strong>", {
                        offset: [0, -52]
                    })
                    .openPopup();
                // Center map on marker
                self.centerMapOnMarker(assetMarker);
                // Fly to this marker with a slow animation
                leafletMap.flyTo(assetMarker.getLatLng(), 21, {
                    animate: true,
                    duration: 1
                });
                // Enable leaflet event
                self.enableAssetTrackingLeafletEvent();
            } else {
                assetMarker
                    .bindPopup("<strong>" + asset.name + "</strong>", {
                        offset: [0, -52]
                    })
                    .openPopup();
                // Center map on marker
                self.centerMapOnMarker(assetMarker);
                // Fly to this marker with a slow animation
                leafletMap.flyTo(assetMarker.getLatLng(), 21, {
                    animate: true,
                    duration: 0.5
                });
            }
            self.resetArrayOfMacAddressToHighlight();
        }
    });

    // The following code allows to register click event on tooltips.
    $(".assetTrackingTooltipContainer")
        .off()
        .on("click", e => {
            if (e.currentTarget) {
                if ($(e.currentTarget).data() && $(e.currentTarget).data().assetid) {
                    let assetId = $(e.currentTarget).data().assetid;
                    // Set currentOpenedAsset is store
                    self.setCurrentOpenedAssetIdInDashboard("");
                    self.setCurrentOpenedAssetIdInDashboard(assetId);
                }
            }
        });

    // If there are some macAddress passed as query params into the URL, check if only one has been provided.
    // If it's the case, open automatically the asset details bar.
    if (self.arrayOfAssetsMacAddressToHighlight && self.arrayOfAssetsMacAddressToHighlight.length == 1) {
        const asset = self.$store.getters.getFloorAssetByTagMacAddress(self.arrayOfAssetsMacAddressToHighlight[0]);
        if (asset) {
            self.setCurrentOpenedAssetIdInDashboard(asset.id);
        }
    }

    let numberOfAssets = 0;
    const assetsInFloor = self.floorsAssets ? self.floorsAssets.filter(asset => asset.tag && asset.tag.floorId === self.getSelectedFloorId) : [];
    if (assetsInFloor) {
        numberOfAssets = assetsInFloor.length;
    }

    // At the end, display update number of assets currently displayed on the map
    let labelKeyAssetDisplayed = "asset_oneDisplayed";
    if (numberOfAssets > 1) {
        labelKeyAssetDisplayed = "asset_moreDisplayed";
    }

    if ($("#numberOfAssetsDiv").length > 0) {
        // Remove first the element
        $("#numberOfAssetsDiv").remove();
    }

    let numberAssetsEl = "";

    const formatTime = self.$i18n.locale === "fr" ? "Do MMMM YYYY, HH:mm:ss" : "MMMM Do YYYY, HH:mm:ss";

    // Not add link to generate link to show current assets position position on map
    if (numberOfAssets == 0) {
        numberAssetsEl =
            `<div id="numberOfAssetsDiv">
                <div class="numberOfAssetsDisplayed">
                    ( <span>` +
            numberOfAssets +
            `</span>` +
            " " +
            i18n.t(labelKeyAssetDisplayed) +
            ` )</div>
            </div>`;
        $(".currentBuildingsAndFloorName").append(numberAssetsEl);
    } else {
        // Add link to generate link to show current assets position position on map
        let visualizationLinkHtml = `<div style="display: inline; position: relative;" id="showAssetLink"> <span class="kt-link cursorPointer" id="generateShowAssetLink">${i18n.t("asset_generateAssetTrackingShortcup")}</span> </div>`;
        // Dispose popover
        $(".popover").popover('dispose');

        numberAssetsEl =
            `<div id="numberOfAssetsDiv">
                <div class="numberOfAssetsDisplayed">
                    ( <span>` +
            numberOfAssets +
            `</span>` +
            " " +
            i18n.t(labelKeyAssetDisplayed) +
            ` ) - ${visualizationLinkHtml}</div>
            </div>`;
        $(".currentBuildingsAndFloorName").append(numberAssetsEl);

        // Generate popover on element
        const fullUrl = self.generateVisualizationLink && self.generateVisualizationLink.data ? self.generateVisualizationLink.data.data.link : "";

        const showAssetLink = `
            <div class="asset-link" style= "display: none;">
                <div class="arrow"></div>
                <div class="asset-link-header">
                    <h3>${i18n.t("asset_generateAssetTrackingShortcupPopoverTitle")}</h3>
                    <a href="#" class="close asset-close" id="closeGenerateShowAssetLink" data-dismiss="alert">×</a>
                    <div>
                        <span class="asset-visualizationLink">
                            ${ self.generateVisualizationLink && self.generateVisualizationLink.data ? i18n.t("asset_visualizationLinkValid", {time: self.generateVisualizationLink && moment(self.generateVisualizationLink.data.data.expiredAt).locale(self.$i18n.locale).format(formatTime)}) : i18n.t("asset_VisualizationLinkGenerating") }
                        <span>
                    </div>
                </div>
                <div class="asset-link-body">
                    <div class="row">
                        <div class="input-group" id="copyShowLinkpopover">
                            <span type="text" class="form-control mw-20 text-truncate" id="showAssetUrl" aria-readonly="true">${fullUrl ? fullUrl : i18n.t("asset_VisualizationLinkGenerating")}</span>
                            <a class="btn btn-default btn btn-outline-brand btn-icon"  href="#" type="button" id="btCopyAssetShowLink"><i class="la la-clipboard"></i></a>
                        </div>
                        </div>
                        </div>
                <div class="short-cup-status">
                    <span class="form-text text-muted ml-1" id="shortCupStatus"></span>
                </div>
            </div>
        `;
        $('#showAssetLink').append(showAssetLink);

        // Action when user clicks on close button
        $("#closeGenerateShowAssetLink").click(() => {
            $(".asset-link").hide();
            $("#shortCupStatus").text("");
            localStorage.setItem(self.getSelectedFloorId + '_generateShowAssetLinkPopoverOpen', false);
        });

        // Add action on button clipboard icon
        $("#btCopyAssetShowLink").click((e) => {
            e.preventDefault();
            // Get url link
            const text = $("#showAssetUrl").text() || "";
            // Create textarea to use the api select for copy command => the url is inside the span element which cannot use for copy api . Copy api can be execute with input or textarea
            const textArea = document.createElement('textarea');
            // Add url in previous text area
            textArea.value = text;
            // Add text area in dom child
            document.body.appendChild(textArea);
            // Select text area and copy it
            textArea.select();
            document.execCommand('copy');
            // Remove the text area element
            document.body.removeChild(textArea);
            // Display the message that link is copied
            $("#shortCupStatus").text(i18n.t("asset_shortcupIsCopied"))
        });

        // $('#generateShowAssetLink').popover({
        //     html: true,
        //     title : () => {
        //         return `${i18n.t("asset_generateAssetTrackingShortcupPopoverTitle")}
        //             <a href="#" class="close" id="closeGenerateShowAssetLink" data-dismiss="alert">×</a>
        //             <div>
        //                 <br/>
        //                 <span class="asset-visualizationLink">
        //                     ${ self.generateVisualizationLink && self.generateVisualizationLink.data ? i18n.t("asset_visualizationLinkValid", {time: self.generateVisualizationLink && moment(self.generateVisualizationLink.data.data.expiredAt).locale(self.$i18n.locale).format(formatTime)}) : i18n.t("asset_VisualizationLinkGenerating") }
        //                 <span>
        //             </div>
        //             `
        //     },
        //     trigger: 'manual',
        //     placement: 'top',
        //     content: () => {
        //         const fullUrl = self.generateVisualizationLink && self.generateVisualizationLink.data ? self.generateVisualizationLink.data.data.link : "";
        //         return (
        //             `<div class="row" style="display: none">
        //                 <div class="input-group" id="copyShowLinkpopover" style="display: none">
        //                     <span type="text" class="form-control mw-20 text-truncate" id="showAssetUrl" aria-readonly="true">${fullUrl ? fullUrl : i18n.t("asset_VisualizationLinkGenerating")}</span>
        //                     <a class="btn btn-default btn btn-outline-brand btn-icon"  href="#" type="button" id="btCopyAssetShowLink"><i class="la la-clipboard"></i></a>
        //                 </div>
        //                 <span class="form-text text-muted ml-1" id="shortCupStatus"></span>
        //             </div>`
        //         );
        //     }
        // });
        // When we click on popover open it and add close action on close button
        $("#generateShowAssetLink").click(async (e) => {
            // Store the value of click action in locale storage
            localStorage.setItem(self.getSelectedFloorId + '_generateShowAssetLinkPopoverOpen', true);
            if (e.originalEvent) {
                const assetIds = assetsInFloor.map(asset => asset.id);
                // self.generateVisualizationLink({siteId: self.currentSite.id || self.floorsAssets[0].site, floorId: self.getSelectedFloorId || null, assetIds: assetIds});
                self.generateVisualizationLink = await axiosBackend
                    .post("/sites/"+ (self.currentSite.id || assetsInFloor[0].site) +"/floors/" + self.getSelectedFloorId + "/link",
                        { assetIds: assetIds },
                        {
                            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                            hideSpinner: true
                        }
                    );
                setTimeout(() => {
                    generateAssetsLayer(self);
                },500);
            }
            // Open popover
            // $('#generateShowAssetLink').popover("show");
            $(".asset-link").show();
        });
        // // Verify if popover had been open to open it now
        if (localStorage && localStorage.getItem(self.getSelectedFloorId + "_generateShowAssetLinkPopoverOpen") === "true") {
            $(".asset-link").show();
        }
    }
}

/**
 * Method called to show or hide assets layer according selected value in sidebar
 * @param self
 */
export function showOrHideAssetsLayer(self) {
    console.log("Component(LocationMap)::showOrHideAssetsLayer() - called");
    if (self.leafletMap && self.assetsLayer) {
        if (self.isAssetsLayerDisplayed) {
            if (!self.leafletMap.hasLayer(self.assetsLayer)) {
                console.log("showOrHideAssetsLayer: leafletMap.addLayer");
                self.leafletMap.addLayer(self.assetsLayer);
            }
        } else {
            console.log("showOrHideAssetsLayer: leafletMap.removeLayer");
            self.leafletMap.removeLayer(self.assetsLayer);
        }

        // Show or hide assets labels tooltip
        showOrHideAssetsLabels(self);
    }
}

export function toggleCircleRadiusAssetsLayer(self) {
    if (self.leafletMap && self.assetsCircleRadiusLayer) {
        if (self.leafletMap.hasLayer(self.assetsCircleRadiusLayer)) {
            self.leafletMap.removeLayer(self.assetsCircleRadiusLayer);
        } else {
            self.leafletMap.addLayer(self.assetsCircleRadiusLayer);
        }
    }
}

/**
 * Method called to show or hide all assets labels tooltips according selected value in sidebar
 * @param self
 */
export function showOrHideAssetsLabels(self) {
    if (self.isAssetsLabelsDisplayed) {
        // Open all assets labels
        console.log("showOrHideAssetsLabels: openTooltips");
        self.assetsLayer.openTooltips();
    } else {
        // Close all assets labels
        console.log("showOrHideAssetsLabels: closeTooltips");
        self.assetsLayer.closeTooltips();
    }
}

/**
 * Method called to display correct tooltips according row values selected by the user in sidebar
 * @param self
 */
export function displayAssetsTooltipsAccordingRowValues(self) {
    if (self.assetsLayer) {
        // For each assets, update the tooltip
        for (let markerIdx in self.assetsLayer._layers) {
            if (self.assetsLayer._layers.hasOwnProperty(markerIdx)) {
                let marker = self.assetsLayer._layers[markerIdx];
                let asset = self.getFloorAssetById(marker.options.id);
                let tooltipContent = getTooltipContentAccordingRowValues(self, asset);
                marker.setTooltipContent(tooltipContent);
            }
        }
    }
}

export function updateCurrentFloorAssets(self, floorId, assets) {
    if (assets && assets.length) {
        const floorIds = _.map(assets, asset => asset.tag.floorId);
        if (!_.includes(floorIds, floorId) && _.every(floorIds, item => item === floorIds[0])) {
            // We need to switch to the good floor and then show the assets
            self.handleSelectFloorMapById(floorIds[0]);
        }
    }
}

/**
 * Method called to get tooltip HTML content according current asset to display and assets labels rows values
 * @param self
 * @param asset
 */
function getTooltipContentAccordingRowValues(self, asset) {
    let tooltipHTML = '<div class="assetTrackingTooltipContainer" data-assetid="' + asset.id + '" >';
    if (self.assetsLabelsDisplayedValues.row1 != "MAP_NONE") {
        tooltipHTML += "<span>" + getRowValueForAssetByLabelName(asset, self.assetsLabelsDisplayedValues.row1) + "</span>";
    }
    if (self.assetsLabelsDisplayedValues.row2 != "MAP_NONE") {
        tooltipHTML += "<span>" + getRowValueForAssetByLabelName(asset, self.assetsLabelsDisplayedValues.row2) + "</span>";
    }
    if (self.assetsLabelsDisplayedValues.row3 != "MAP_NONE") {
        tooltipHTML += "<span>" + getRowValueForAssetByLabelName(asset, self.assetsLabelsDisplayedValues.row3) + "</span>";
    }
    if (self.assetsLabelsDisplayedValues.row4 != "MAP_NONE") {
        tooltipHTML += "<span>" + getRowValueForAssetByLabelName(asset, self.assetsLabelsDisplayedValues.row4) + "</span>";
    }
    tooltipHTML += "</div>";
    return tooltipHTML;
}

/**
 * Method called to get the right value of asset key according labelName
 * @param asset
 * @param labelName
 */
function getRowValueForAssetByLabelName(asset, labelName) {
    let value = "N/A";
    switch (labelName) {
        case "MAP_ASSET_NAME":
            value = asset.name;
            break;
        case "MAP_CATEGORY":
            if (asset.assetcategory) {
                if (asset.assetcategory.isDefault) {
                    value = i18n.t(asset.assetcategory.name);
                } else {
                    value = asset.assetcategory.name;
                }
            } else {
                value = "N/A";
            }
            break;
        case "MAP_SUB_CATEGORY":
            if (asset.assetsubcategory) {
                if (asset.assetsubcategory.isDefault) {
                    value = i18n.t(asset.assetsubcategory.name);
                } else {
                    value = asset.assetsubcategory.name;
                }
            } else {
                value = "N/A";
            }
            break;
        case "MAP_UNIT":
            value = asset.assetUnit ? asset.assetUnit.isDefault ? i18n.t(asset.assetUnit.name) : asset.assetUnit.name : "";
            break;
        case "MAP_TAG_SERIAL_NUMBER":
            value = asset.tag ? asset.tag.serialNumber : "N/A";
            break;
        case "MAP_TAG_TYPE":
            if (asset.tag) {
                let tagTypeLabel = commonVueHelper.getPropFromTagType("label", asset.tag.tagType);
                if (tagTypeLabel) {
                    value = tagTypeLabel;
                } else {
                    value = "N/A";
                }
            } else {
                value = "N/A";
            }
            break;
        case "MAP_TAG_BATTERY_STATUS":
            if (asset.tag && asset.tag.tagType) {
                let batteryLifetime = asset.tag.tagType.batteryLifetime;
                let estimatedBatteryLifetimeEndDate = moment(asset.tag.batteryInstallationDate).add(batteryLifetime, "months");
                let estimatedBatteryLifetimeEndDateMinus6Months = moment(estimatedBatteryLifetimeEndDate).subtract(6, "months");
                let estimateBatteryMiddleLifetimeDate = moment(estimatedBatteryLifetimeEndDate).subtract(batteryLifetime / 2, "months");
                let now = moment();
                if (now.isAfter(estimatedBatteryLifetimeEndDate) || now.isAfter(estimatedBatteryLifetimeEndDateMinus6Months)) {
                    value = '<img style="width: 17px" alt="Low Battery" src="/assets/battery/battery-charging.svg" /> <span class="lowBattery">Low</span>';
                } else if (now.isAfter(estimateBatteryMiddleLifetimeDate) && now.isBefore(estimatedBatteryLifetimeEndDateMinus6Months)) {
                    value = '<img style="width: 17px" alt="Medium Battery" src="/assets/battery/battery-charging.svg" /> <span class="mediumBattery">Medium</span>';
                } else {
                    value = '<img style="width: 17px" alt="High Battery" src="/assets/battery/battery-charging.svg" /> <span class="highBattery">High</span>';
                }
            } else {
                value = "N/A";
            }
            break;
        default:
            value = "N/A";
            break;
    }
    return value;
}

/**
 * Method called to get assets icon according asset category and availability (when applicable)
 * @param asset
 * @param labelName
 */
function getAssetIcon(asset) {
    let iconObj = {
        shadowUrl: null
    };

    if (asset.assetcategory.iconSize === "SIZE_SMALL") {
        iconObj.iconAnchor = new L.Point(20, 45);
        iconObj.iconSize = new L.Point(40, 45);
    } else if (asset.assetcategory.iconSize === "SIZE_LARGE") {
        iconObj.iconAnchor = new L.Point(26, 60);
        iconObj.iconSize = new L.Point(51, 60);
    }

    let iconUrl = "";
    if (asset.assetcategory.isDefault) {
        // If the asset category is a default category, use png image saved in public folder,
        // for performance reasons.
        iconUrl = "/assets/categories/" + asset.assetcategory.name + "/Icon_marker" + getAssetIconSizeExtension(asset) + getAssetAvailabilityExtension(asset) + ".png";
        iconObj.iconUrl = iconUrl;
        var generatedClass = "asset_" + asset.tag.macAddress;
        iconObj.className = "leaflet-autocalibrationtag-location-mouse-marker " + generatedClass;

        return {
            icon: L.icon(iconObj)
        };
    } else {
        // If the category is a custom category, we will use the customicon base64 image
        iconUrl = asset.assetcategory.iconImg;
        iconObj.iconUrl = iconUrl;

        var backgroundStyle = "";
        var iconStyle = "";
        var availabilityExt = getAssetAvailabilityExtension(asset);
        if (availabilityExt === "_vert") {
            backgroundStyle = asset.assetcategory.iconSize === "SIZE_SMALL" ? "emptyDefaultMarkerModelGreenSmall" : "emptyDefaultMarkerModelGreenLarge";
            iconStyle = asset.assetcategory.iconSize === "SIZE_SMALL" ? "trackingDashboardCustomIconCatWithAvailabilitySmall" : "trackingDashboardCustomIconCatWithAvailabilityLarge";
        } else if (availabilityExt === "_orange") {
            backgroundStyle = asset.assetcategory.iconSize === "SIZE_SMALL" ? "emptyDefaultMarkerModelOrangeSmall" : "emptyDefaultMarkerModelOrangeLarge";
            iconStyle = asset.assetcategory.iconSize === "SIZE_SMALL" ? "trackingDashboardCustomIconCatWithAvailabilitySmall" : "trackingDashboardCustomIconCatWithAvailabilityLarge";
        } else if (availabilityExt === "_rouge") {
            backgroundStyle = asset.assetcategory.iconSize === "SIZE_SMALL" ? "emptyDefaultMarkerModelRedSmall" : "emptyDefaultMarkerModelRedLarge";
            iconStyle = asset.assetcategory.iconSize === "SIZE_SMALL" ? "trackingDashboardCustomIconCatWithAvailabilitySmall" : "trackingDashboardCustomIconCatWithAvailabilityLarge";
        } else {
            backgroundStyle = asset.assetcategory.iconSize === "SIZE_SMALL" ? "emptyDefaultMarkerModelSmall" : "emptyDefaultMarkerModelLarge";
            iconStyle = asset.assetcategory.iconSize === "SIZE_SMALL" ? "trackingDashboardCustomIconCatSmall" : "trackingDashboardCustomIconCatLarge";
        }

        iconObj.className = "leaflet-autocalibrationtag-location-mouse-marker " + backgroundStyle;
        iconObj.html = '<img id="asset_' + asset.tag.macAddress + '" class="' + iconStyle + '" src="' + iconUrl + '" />';

        return {
            icon: L.divIcon(iconObj)
        };
    }
}

/**
 * Method called to get suffix string used to specified to good icon url on the map according icon size of asssociated category
 * @param asset
 */
function getAssetIconSizeExtension(asset) {
    let iconSizeExtension = "";
    if (asset.assetcategory && asset.assetcategory.iconSize) {
        if (asset.assetcategory.iconSize === "SIZE_SMALL") {
            iconSizeExtension = "_45";
        }
    }
    return iconSizeExtension;
}

/**
 * Method called to get suffix string used to specified to good icon url on the map according availability custom field value
 * @param asset
 */
function getAssetAvailabilityExtension(asset) {
    let iconUrlExtension = "";
    if (asset.customfields) {
        for (let i = 0; i < asset.customfields.length; i++) {
            if (asset.customfields[i].name === "CUSTOMFIELD_AVAILABILITY") {
                switch (asset.customfields[i].value) {
                    case "ENUM_AVAILABILITY_AVAILABLE":
                        iconUrlExtension = "_vert";
                        break;
                    case "ENUM_AVAILABILITY_UNAVAILABLE":
                        iconUrlExtension = "_rouge";
                        break;
                    default:
                        iconUrlExtension = "_vert";
                }
            }
        }
    }
    return iconUrlExtension;
}