import i18n from "../../../i18n";

/**
 * Init the location map for walls definition mode
 * @param self
 */
export function initWalls(self) {

    console.log("Component(LocationMap)::initWalls()) - called");

    self.initActionsBar(["centerMapButton"]);
    self.setMapTilesLayer("OpenStreetMap");
    self.initDrawControl();
    // Force set walls displayed because if we are in the walls page
    self.setWallsLayerDisplayed(true);

    // Hide icons/buttons automatically added by Leaflet Draw library
    $(".leaflet-draw").hide();

    // Manage Escape button for cancelling creation/edition of wall
    let onKeyDown = e => {
        if (e.keyCode == 27) {
            // esc button
            if (self.currentWall.state === "START_CREATION") {
                self.setCurrentWall({
                    id: "",
                    state: "CANCEL_CREATION"
                });
            } else if (self.currentWall.state === "EDIT") {
                self.setCurrentWall({
                    id: self.currentWall.id,
                    state: "CANCEL_EDIT"
                });
            } else if (self.currentWall.state === "FINALIZE_EDITION") {
                self.setCurrentWall({
                    id: "",
                    state: "CANCEL_EDIT"
                });
            } else if (self.currentWall.state === "START_MEASURE") {
                self.setCurrentWall({
                    id: "",
                    state: "CANCEL_MEASURE"
                });
            }
        }
    };
    L.DomEvent.addListener(document, "keydown", onKeyDown, self.leafletMap);
}

/**
 * Method called to init draw controls that will be used at wall creation / edition
 * @param self
 */
export function initDrawControlForWall(self) {
    var options = {
        position: "topleft",
        draw: {
            polygon: {
                allowIntersection: false, // Restricts shapes to simple polygons
                drawError: {
                    color: "#e1e100", // Color the shape will turn when intersects
                    message: i18n.t("area_drawingIntersectionsError") // Message that will show when intersect
                },
                shapeOptions: {
                    dashArray: "10, 10",
                    fill: true,
                    fillColor: "#fe57a1",
                    fillOpacity: 0.1,
                    maintainColor: false
                }
            },
            polyline: false,
            circle: false,
            rectangle: false,
            marker: false,
            circlemarker: false
        },
        edit: {
            featureGroup: self.editableLayers,
            remove: false
        }
    };

    return options;
}

export function registerEvents(self) {
    // This listener is only used when user has clicke don start measure button
    self.leafletMap.on(L.Draw.Event.DRAWSTART, e => {
        if (self.currentWall.state !== "START_CREATION") {
            if (e && e.layerType && e.layerType === "polyline") {
                self.setCurrentWall({ id: "", state: "START_MEASURE" });
            }
        }
    });

    self.leafletMap.on(L.Draw.Event.CREATED, e => {
        // First check that drawn polygon is not covering another existing wall
        let geometry = e.layer.toGeoJSON().geometry;
        let distance = Math.round(Math.round(L.GeometryUtil.length(e.layer.getLatLngs()) * 1000) / 1000);
        var wallData = {
            id: self.currentWall.id,
            geometry: geometry,
            distance: distance,
            state: "SAVE_CREATION"
        };
        self.setCurrentWall(wallData);
    });
}

/**
 * Method called to do specific actions following the current state of currentWall
 * @param self
 * @param action
 */
export function updateCurrentWall(self, wall) {
    if (!wall.state) {
        // show again measure control
        self.showMeasureControl();
        resetAllLayersToDefaultColor(self);
        if (self.leafletMap) {
            self.leafletMap.closePopup();
        }
    } else {
        if (wall.state === "START_CREATION") {
            startWallCreation(self);
            self.hideMeasureControl();
        } else if (wall.state === "SAVE_CREATION") {
            saveNewWallAction(self);
        } else if (wall.state === "CANCEL_CREATION") {
            cancelNewWallAction(self);
        } else if (wall.state === "EDIT") {
            startEditWallAction(self);
            self.hideMeasureControl();
        } else if (wall.state === "SAVE_EDIT") {
            saveEditWallAction(self);
        } else if (wall.state === "CANCEL_EDIT") {
            cancelEditWallAction(self);
        } else if (wall.state === "HIGHLIGHT") {
            highlightWallAction(self);
        } else if (wall.state === "START_MEASURE") {
            startMeasureAction(self);
        } else if (wall.state === "CANCEL_MEASURE") {
            cancelMeasureAction(self);
        }
    }
}

/**
 * Method called when user start measure action
 * @param self
 */
export function startMeasureAction(self) {
    self.hideMeasureControl();
}

/**
 * Method called when user cancel measure action
 * @param self
 */
export function cancelMeasureAction(self) {
    if (self.measureHandler && self.measureHandler.handler) {
        self.measureHandler.handler.disable();
    }
    self.showMeasureControl();
    self.setCurrentWall({
        id: "",
        state: ""
    });
}

/**
 * Method called at page loading or floor selection, to display all walls of the selected floor
 * @param self
 * @param action
 */
export function generateWallsLayer(self) {
    // If wallsLayer is already defined, clear all layers already existing inside
    if (self.wallsLayer) {
        self.wallsLayer.clearLayers();
    } else {
        self.wallsLayer = new L.FeatureGroup();
    }

    // Get current selected building and floor id
    let currentSelectedBuildingId = self.getSelectedBuildingId;
    let currentSelectedFloorId = self.getSelectedFloorId;

    // Then, for each wall, create a polyline layer and add it to the global walls layer
    _.forEach(self.siteWalls, function(wall) {
        // Display polylines only for walls that are actually on the selected building and floor
        if (wall.building.id === currentSelectedBuildingId && wall.floor.id === currentSelectedFloorId) {
            // Convert geoJson coordinates to leaflet polyline format
            let polylineCoords = [];
            for (var i = 0; i < wall.geometry.coordinates.length; i++) {
                polylineCoords.push([wall.geometry.coordinates[i][1], wall.geometry.coordinates[i][0]]);
            }

            let wallPolyline = L.polyline(polylineCoords);

            // Add popup and tooltip to generated polyline
            let areaUnit = wall.distanceUnit === "meters" ? "m" : "ft";
            let wallType = self.getWallTypeById(wall.wallType);
            var popup = new L.Popup().setContent("<strong>" + wall.name + "</strong><br>" + i18n.t(wallType.name) + "<br>" + wall.distance + " " + areaUnit + '<br><span style="color: #999"></span>');
            wallPolyline.bindPopup(popup);
            wallPolyline.bindTooltip(wall.name, { className: "customTooltipWallClass", permanent: true, direction: "center" });

            // Define click listener on area and change color of this area when user click on it
            registerClickEventsOnWallPolyline(self, wallPolyline);

            // This line is important: we are setting the id of the wall polyline layer using wall id.
            // It will be usefull later to find a layer by id.
            wallPolyline.options.id = wall.id;

            // Define polyline display color
            let polylineColor = "#3388ff";
            if (wall.wallType) {
                polylineColor = self.getWallColorById(wall.id);
            }
            wallPolyline.setStyle({
                color: polylineColor,
                fillColor: polylineColor,
                className: "wall_" + wall.id
            });

            self.wallsLayer.addLayer(wallPolyline);
        }
    });
}

/*
 * Function used to reset all markers icon to default icon
 */
function resetAllLayersToDefaultColor(self) {
    if (self.wallsLayer) {
        // Reset all areas to their original color
        for (var i in self.wallsLayer._layers) {
            if (self.wallsLayer._layers.hasOwnProperty(i)) {
                var currentWallPolyline = self.wallsLayer._layers[i];
                // Get style from store
                var wallId = currentWallPolyline.options.id;
                var wallInStore = self.getWallById(wallId);
                if (wallInStore && wallInStore.wallType) {
                    currentWallPolyline.setStyle({
                        color: self.getWallColorById(wallInStore.id),
                        fillColor: self.getWallColorById(wallInStore.id)
                    });
                }
            }
        }
    }
}

/*
 * Function used to highlight one area layer on the map
 */
function highlightArea(areaLayer) {
    areaLayer.setStyle({
        color: "#734abd",
        fillColor: "#734abd"
    });
}

/*
 * Function used to register click on area polygon
 */
function registerClickEventsOnWallPolyline(self, wallPolyline) {
    // Register click event on area layer
    wallPolyline.on("click", function(e) {
        // Reset all area layers
        resetAllLayersToDefaultColor(self);
        // Set selected area
        var wallId = e.target.options.id;
        self.setCurrentWall({ id: wallId, state: "HIGHLIGHT", isHighlightFromMap: true });
        // Highlight the selected one
        var areaLayer = e.target;
        highlightArea(areaLayer);
    });

    // Register click event on close popup
    wallPolyline.getPopup().on("remove", () => {
        if (self.leafletMap.hasLayer(wallPolyline) && self.currentWall.state === "HIGHLIGHT") {
            // Reset selected area to empty
            self.setCurrentWall({ id: "", state: "" });
        }
        // Reset default color only if the marker is always on the map
        // It could be deleted if the remove event has been fired after delete location
        if (self.leafletMap.hasLayer(wallPolyline)) {
            var wallId = wallPolyline.options.id;
            var wallInStore = self.getWallById(wallId);
            wallPolyline.setStyle({
                color: "#" + wallInStore.color,
                fillColor: "#" + wallInStore.color
            });
        }
    });

    // Register click event on popup open to force again selection of area
    wallPolyline.on("popupopen", e => {
        // Set selected area
        var wallId = e.target.options.id;
        self.setCurrentWall({ id: wallId, state: "HIGHLIGHT" });
    });
}

export function showOrHideWallsLayer(self) {

    console.log("Component(LocationMap)::showOrHideWallsLayer()) - called");

    if (self.leafletMap && self.wallsLayer) {
        if (self.isWallsLayerDisplayed) {
            self.leafletMap.addLayer(self.wallsLayer);
        } else {
            self.leafletMap.removeLayer(self.wallsLayer);
        }
    }
}

/**
 * Method called to start creation of an wall on the map
 * @param self
 */
export function startWallCreation(self) {
    console.log("Component(LocationMap)::startWallCreation()) - Start drawing polygon !");

    // Close all opened popups on the map
    self.hideAllPopupOnMap();

    self.drawHandler = new L.Draw.Polyline(self.leafletMap, self.drawControl.options.draw.polyline);
    self.drawHandler.enable();
}

/**
 * Method called to save new wall on the map
 * @param self
 */
export function saveNewWallAction(self) {
    console.log("Component(LocationMap)::saveNewWallAction()) - Start saving new wall !");
    if (self.currentWall.geometry.coordinates.length > 1) {
        let wallData = {
            id: self.currentWall.id,
            name: "",
            siteId: self.siteId,
            buildingId: self.getSelectedBuildingId,
            floorId: self.getSelectedFloorId,
            geometry: self.currentWall.geometry,
            distance: self.currentWall.distance,
            distanceUnit: "meters",
            state: "FINALIZE_CREATION",
            wallType: self.currentWall.wallType
        };

        // Will open confirmation pop-up from wallModal.vue component
        // if (self.isInFullscreen === true) {
        //     self.quitFullscreenButton();
        // }
        self.setCurrentWall(wallData);
    } else {
        // Case where user has created a wall with only one point
        self.setCurrentWall({ id: "", state: "" });
        // Show generic modal
        self.showTextModal({
            textContent: i18n.t('wall_unableToDrawWallWithOnlyOnePoint')
        });
    }
}

/**
 * Method called to cancel new wall on the map
 * @param self
 */
export function cancelNewWallAction(self) {
    console.log("Component(LocationMap)::cancelNewWallAction()) - Cancelling new wall creation !");

    self.setCurrentWall({
        id: "",
        state: ""
    });

    self.drawHandler.disable();
    self.drawHandler = null;

    // Show again floor selector on the map
    self.showFloorMapSelector();
}

/**
 * Method called to start edit of an autocalibration tag on the map
 * @param self
 */
export function startEditWallAction(self) {
    console.log("Component(LocationMap)::startEditWallAction()) - Start editing wall !");

    // Get full wall object
    let wallObject = self.getWallById(self.currentWall.id);
    // Hide floor selector on the map
    self.hideFloorMapSelector();
    // Close all opened popups on the map
    self.hideAllPopupOnMap();
    // Check if the wall is located on the current selected floor
    if (wallObject.building.id === self.getSelectedBuildingId && wallObject.floor.id === self.getSelectedFloorId) {
        // Get layer of selected wall by id
        let currentEditedWallLayer = self.wallsLayer.getLayerById(self.currentWall.id);
        // Center map on highlighted wall
        self.centerMapOnPolygon(currentEditedWallLayer);
        // First remove all layers in editable Layers because we are able to edit one layer once a time
        self.editableLayers.clearLayers();
        // Add only selected AutoCalibrationTag layer to the list of edited layers
        self.editableLayers.addLayer(currentEditedWallLayer);
        // Force activation of edit mode now ! Only layers available in the editableLayers array will now goes in edit mode in location map.
        self.editHandler.enable();
    } else {
        // We need to switch to the good floor and then edit the wall
        self.handleSelectFloorMapById(wallObject.floor.id);
        // No need to implement callback here,just wait few ms before leaflet update the map.
        setTimeout(() => {
            startEditWallAction(self);
        }, 800);
    }
}

/**
 * Method called to save edit of a wall on the map
 * @param self
 */
export function saveEditWallAction(self) {
    console.log("Component(LocationMap)::saveEditWallAction()) - Start saving edited wall !");

    // Get last version of wall
    let wall = self.getWallById(self.currentWall.id);

    // Generate polygon and area from edited Leaflet layer
    let currentEditedWallLayer = self.wallsLayer.getLayerById(self.currentWall.id);
    let geometry = currentEditedWallLayer.toGeoJSON().geometry;
    let distance = Math.round(Math.round(L.GeometryUtil.length(currentEditedWallLayer.getLatLngs()) * 1000) / 1000);

    var wallData = {
        id: wall.id,
        name: wall.name,
        // color: wall.color,
        siteId: wall.site,
        buildingId: wall.building.id,
        floorId: wall.floor.id,
        geometry: geometry,
        distance: distance,
        distanceUnit: "meters",
        state: "FINALIZE_EDITION",
        wallType: wall.wallType
    };

    // Will open confirmation pop-up from wallModal.vue component
    self.setCurrentWall(wallData);

    self.editHandler.disable();
    // Show again floor selector on the map
    self.showFloorMapSelector();
}

/**
 * Method called to cancel edition of an wall on the map
 * @param self
 */
export function cancelEditWallAction(self) {
    self.editableLayers.clearLayers();
    // revert layers
    self.editHandler.revertLayers();
    // disable layer editing
    self.editHandler.disable();
    // reinit of current wall
    self.setCurrentWall({ id: "", state: "" });
    // CTC-538 Display initial walls of the selected floor
    generateWallsLayer(self);
    // Show again floor selector on the map
    self.showFloorMapSelector();
    // Display back the edited wall
    showOrHideWallsLayer(self);
}

/**
 * Method called to highlight wall on the map
 * @param self
 */
export function highlightWallAction(self) {
    console.log("Component(LocationMap)::highlightWallAction()) - Highlight wall !");

    // Defense: If user is currently creating a wall and click on another one into the list,
    // the highlight feature will be called. In that case, we need to disable the draw handler.
    if (self.drawHandler) {
        self.drawHandler.disable();
        self.drawHandler = null;
    }

    // Defense: If user is currently measuring a distance and click to highlight an area
    if (self.measureHandler && self.measureHandler.handler) {
        self.measureHandler.handler.disable();
    }

    // Defense: If user is currently updating a polygon of an existing wall and click on another one into the list,
    // the highlight feature will be called. In that case, we need to disable the edit draw handler.
    if (self.editHandler) {
        // revert layers
        self.editHandler.revertLayers();
        // disable layer editing
        self.editHandler.disable();
        // Show again the measure control
        self.showMeasureControl();
    }

    let wallObject = self.getWallById(self.currentWall.id);
    // Reset all markers icons to default one
    resetAllLayersToDefaultColor(self);
    // Check if the wall is located on the current selected floor
    if (wallObject.building.id === self.getSelectedBuildingId && wallObject.floor.id === self.getSelectedFloorId) {
        // Map currently display the good floor, we just need to highlight the wall
        // Get layer of selected wall by id
        let wallLayer = self.wallsLayer.getLayerById(self.currentWall.id);
        wallLayer.openPopup();
        // Highlight selected area
        highlightArea(wallLayer);
        // Center map on highlighted area
        self.centerMapOnPolygon(wallLayer);
    } else {
        // We need to switch to the good floor and then highlight the wall
        self.handleSelectFloorMapById(wallObject.floor.id);
        // No need to implement callback here,just wait few ms before leaflet update the map.
        setTimeout(() => {
            let wallLayer = self.wallsLayer.getLayerById(self.currentWall.id);
            wallLayer.openPopup();
            // Highlight selected area
            highlightArea(wallLayer);
            // Center map on highlighted area
            self.centerMapOnPolygon(wallLayer);
        }, 800);
    }
}
