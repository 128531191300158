import i18n from "../../../i18n";
import * as turf from '@turf/turf'

const DEFAULT_GATEWAY_ICON = {
    options: {
        shadowUrl: null,
        className: "leaflet-autocalibrationtag-location-mouse-marker",
        iconAnchor: new L.Point(35, 35),
        iconSize: new L.Point(35, 35),
        iconUrl: "/assets/markers/gateway.png"
    }
};

const HIGHLIGHT_GATEWAY_ICON = {
    options: {
        shadowUrl: null,
        className: "leaflet-autocalibrationtag-location-mouse-marker",
        iconAnchor: new L.Point(35, 35),
        iconSize: new L.Point(35, 35),
        iconUrl: "/assets/markers/gateway_highlight.png"
    }
};

/**
 * Init the location map for gateways location mode
 * @param self
 */
export function initGatewayLocation(self) {

    console.log("gateway::initGatewayLocation() - called");

    self.initActionsBar(["centerMapButton"]);
    self.setMapTilesLayer("OpenStreetMap");
    self.initDrawControl();
    // Force set gateways displayed because if we are in the gateways page
    self.setGatewaysLayerDisplayed(true);

    // Hide icons/buttons automatically added by Leaflet Draw library
    $(".leaflet-draw").hide();

    // Manage Escape button for cancelling editing and set location
    let onKeyDown = e => {
        if (e.keyCode == 27) {
            // esc button
            if (self.currentBLEGatewayLocation.state === "CREATE") {
                self.setCurrentGatewayLocation({
                    id: "",
                    bleMacAddress: "",
                    state: "CANCEL"
                });
            } else if (self.currentBLEGatewayLocation.state === "EDIT") {
                self.setCurrentGatewayLocation({
                    id: self.currentBLEGatewayLocation.id,
                    bleMacAddress: self.currentBLEGatewayLocation.bleMacAddress,
                    state: "CANCEL_EDIT"
                });
            } else if (self.currentBLEGatewayLocation.state === "SET_FLOOR_ELEVATION") {
                self.setCurrentGatewayLocation({
                    id: "",
                    bleMacAddress: "",
                    state: "CANCEL"
                });
            } else if (self.currentBLEGatewayLocation.state === "START_MEASURE") {
                self.setCurrentGatewayLocation({
                    id: "",
                    bleMacAddress: "",
                    state: "CANCEL_MEASURE"
                });
            }
        }
    };
    L.DomEvent.addListener(document, "keydown", onKeyDown, self.leafletMap);
}

/**
 * Method called to do specific actions following the current state of currentGatewayLocation
 * @param self
 * @param action
 */
export function updateCurrentGatewayLocation(self, action) {
    if (!action.bleMacAddress && action.state === "CANCEL") {
        self.showMeasureControl();
        cancelSetGatewayMarkerAction(self);
    } else if (!action.state) {
        // show again measure control
        self.showMeasureControl();
        resetAllLayersToDefaultMarker(self);
        if (self.leafletMap) {
            self.leafletMap.closePopup();
        }
    } else {
        if (action.state === "CREATE") {
            startSetGatewayMarkerAction(self);
            self.hideMeasureControl();
        } else if (action.state === "EDIT") {
            startEditGatewayMarkerLocationAction(self);
            self.hideMeasureControl();
        } else if (action.state === "DELETE") {
            deleteGatewayMarkerLocationAction(self);
        } else if (action.state === "HIGHLIGHT") {
            self.showMeasureControl();
            highlightGatewayMarkerAction(self);
        } else if (action.state === "SAVE_EDIT") {
            saveEditGatewayMarkerLocationAction(self);
        } else if (action.state === "CANCEL_EDIT") {
            cancelEditGatewayMarkerLocationAction(self);
        } else if (action.state === "START_MEASURE") {
            startMeasureAction(self);
        } else if (action.state === "CANCEL_MEASURE") {
            cancelMeasureAction(self);
        }
    }
}

/**
 * Method called when user start measure action
 * @param self
 */
export function startMeasureAction(self) {
    self.hideMeasureControl();
}

/**
 * Method called when user cancel measure action
 * @param self
 */
export function cancelMeasureAction(self) {
    if (self.measureHandler && self.measureHandler.handler) {
        self.measureHandler.handler.disable();
    }
    self.showMeasureControl();
    self.setCurrentGatewayLocation({
        id: "",
        bleMacAddress: "",
        state: ""
    });
}

/**
 * Method called to start location of a gateway on the map
 * @param self
 */
export function startSetGatewayMarkerAction(self) {
    console.log("gateway::startSetGatewayMarkerAction()) - Start setting location !");
    self.drawHandler = new L.Draw.Marker(self.leafletMap, self.drawControl.options.draw.marker);
    self.drawHandler.enable();
}

/**
 * Method called to start edit of a gateway on the map
 * @param self
 */
export function startEditGatewayMarkerLocationAction(self) {
    console.log("gateway::startEditGatewayMarkerLocationAction()) - Start editing location !");
    let gatewayObject = self.getGatewayById(self.currentBLEGatewayLocation.id);
    // Hide floor selector on the map
    self.hideFloorMapSelector();
    // Close all opened popups on the map
    self.hideAllPopupOnMap();
    // Check if the gateway is located on the current selected floor
    if (gatewayObject.building.id === self.getSelectedBuildingId && gatewayObject.floor.id === self.getSelectedFloorId) {
        // Get layer of selected gateway by id
        let currentEditedGatewayLayer = self.gatewaysLayer.getLayerById(self.currentBLEGatewayLocation.id);
        // Center map on highlighted gateway
        self.centerMapOnMarker(currentEditedGatewayLayer);
        // First remove all layers in editable Layers because we are able to edit one layer once a time
        self.editableLayers.clearLayers();
        // Add only selected gateway layer to the list of edited layers
        self.editableLayers.addLayer(currentEditedGatewayLayer);

        // Force activation of edit mode now ! Only layers available in the editableLayers array will now goes in edit mode in location map.
        self.editHandler.enable();
    } else {
        // We need to switch to the good floor and then highlight the gateway
        self.handleSelectFloorMapById(gatewayObject.floor.id);
        // No need to implement callback here,just wait few ms before leaflet update the map.
        setTimeout(() => {
            startEditGatewayMarkerLocationAction(self);
        }, 800);
    }
}

/**
 * Method called to save edit of a gateway on the map
 * @param self
 */
export function saveEditGatewayMarkerLocationAction(self) {
    console.log("gateway::saveEditGatewayMarkerLocationAction()) - Start saving new gateway location !");
    // Get layer of selected gateway by id
    let currentEditedGatewayLayer = self.gatewaysLayer.getLayerById(self.currentBLEGatewayLocation.id);
    let gatewayMarkerLocation = currentEditedGatewayLayer.toGeoJSON().geometry;
    let gatewayLocationData = {
        id: self.currentBLEGatewayLocation.id,
        buildingId: self.getSelectedBuildingId,
        floorId: self.getSelectedFloorId,
        siteId: self.siteId,
        location: gatewayMarkerLocation
    };
    if (typeof self.currentBLEGatewayLocation.floorElevation === "number") {
        gatewayLocationData.floorElevation = self.currentBLEGatewayLocation.floorElevation;
    }
    self.setGatewayLocation(gatewayLocationData);
    self.editHandler.disable();
    // Show again floor selector on the map
    self.showFloorMapSelector();

    // Store the last edited location in store. Usefull if distance has to be displayed on the map.
    self.storeLastAddedObjectLocation({
        type: "GATEWAY",
        id: self.currentBLEGatewayLocation.id,
        lastAddedObjectLocation: gatewayMarkerLocation
    });
}

/**
 * Method called to save edit of a gateway on the map
 * @param self
 */
export function cancelEditGatewayMarkerLocationAction(self) {
    console.log("gateway::cancelEditGatewayMarkerLocationAction()) - Cancel editing new gateway location !");
    self.editHandler.disable();
    let gatewayBeforeEditing = self.getGatewayById(self.currentBLEGatewayLocation.id);
    let gatewayLocationData = {
        id: self.currentBLEGatewayLocation.id,
        buildingId: self.getSelectedBuildingId,
        floorId: self.getSelectedFloorId,
        siteId: self.siteId,
        location: gatewayBeforeEditing.location
    };
    if (typeof self.currentBLEGatewayLocation.floorElevation === "number") {
        gatewayLocationData.floorElevation = self.currentBLEGatewayLocation.floorElevation;
    }
    self.setGatewayLocation(gatewayLocationData);
    // Show again floor selector on the map
    self.showFloorMapSelector();
}

/**
 * Method called to delete one gateway location on the map
 * @param self
 */
export function deleteGatewayMarkerLocationAction(self) {
    console.log("gateway::deleteGatewayMarkerLocationAction()) - Delete marker location !");
    let gatewayLocationData = {
        id: self.currentBLEGatewayLocation.id,
        buildingId: null,
        floorId: null,
        siteId: self.siteId,
        location: null,
        floorElevation: self.currentBLEGatewayLocation.floorElevation // Current value in current Gateway
    };
    self.setGatewayLocation(gatewayLocationData);
}

/**
 * Method called to highlight gateway on the map
 * @param self
 */
export function highlightGatewayMarkerAction(self) {
    console.log("gateway::highlightGatewayMarkerAction()) - Highlight marker location !");

    // Defense: If user is currently measuring a distance and click to highlight an area
    if (self.measureHandler && self.measureHandler.handler) {
        self.measureHandler.handler.disable();
    }

    let gatewayObject = self.getGatewayById(self.currentBLEGatewayLocation.id);
    // Reset all markers icons to default one
    resetAllLayersToDefaultMarker(self);
    // Check if the gateway is located on the current selected floor
    if (gatewayObject.building.id === self.getSelectedBuildingId && gatewayObject.floor.id === self.getSelectedFloorId) {
        // Map currently display the good floor, we just need to highlight the good gateway
        // Get layer of selected gateway by id
        let gatewayLayer = self.gatewaysLayer.getLayerById(self.currentBLEGatewayLocation.id);
        gatewayLayer.openPopup();
        // Highlight selected marker
        highlightMarker(gatewayLayer);
        // Center map on highlighted gateway
        self.centerMapOnMarker(gatewayLayer);
    } else {
        // We need to switch to the good floor and then highlight the gateway
        self.handleSelectFloorMapById(gatewayObject.floor.id);
        // No need to implement callback here,just wait few ms before leaflet update the map.
        setTimeout(() => {
            let gatewayLayer = self.gatewaysLayer.getLayerById(self.currentBLEGatewayLocation.id);
            gatewayLayer.openPopup();
            // Highlight selected marker
            highlightMarker(gatewayLayer);
            // Center map on highlighted gateway
            self.centerMapOnMarker(gatewayLayer);
        }, 800);
    }
}

/**
 * Method called to cancel location of a gateway on the map
 * @param self
 */
export function cancelSetGatewayMarkerAction(self) {
    self.drawHandler.disable();
}

/**
 * Method called to init draw controls that will be used when user will start location of a gateway
 * @param self
 */
export function initDrawControlForGateway(self) {
    var gatewayLocationMarker = L.Icon.extend({
        options: getGatewayIconFormat()
    });

    // By default disable all drawing and editing options
    var options = {
        position: "topleft",
        draw: {
            polygon: false,
            polyline: false,
            circle: false,
            rectangle: false,
            marker: {
                icon: new gatewayLocationMarker()
            },
            circlemarker: false
        },
        edit: {
            featureGroup: self.editableLayers,
            remove: false
        }
    };

    return options;
}

export function registerEvents(self) {
    let self2 = self;

    // This listener is only used when user has clicke don start measure button
    self.leafletMap.on(L.Draw.Event.DRAWSTART, e => {
        if (e && e.layerType && e.layerType === "polyline") {
            self.setCurrentGatewayLocation({ id: "", state: "START_MEASURE" });
        }
    });

    self.leafletMap.on(L.Draw.Event.CREATED, e => {
        var gatewayMarkerLayer = e.layer;
        let gatewayMarkerLocation = gatewayMarkerLayer.toGeoJSON().geometry;
        let gatewayLocationData = {
            id: self2.currentBLEGatewayLocation.id,
            buildingId: self2.getSelectedBuildingId,
            floorId: self2.getSelectedFloorId,
            siteId: self2.siteId,
            location: gatewayMarkerLocation,
            state: "SET_FLOOR_ELEVATION"
        };
        if (typeof self2.currentBLEGatewayLocation.floorElevation === "number") {
            gatewayLocationData.floorElevation = self2.currentBLEGatewayLocation.floorElevation;
        }
        console.log("gateway::initDrawControl() - New gateway marker created. Marker Data = ", gatewayLocationData);
        if (self2.isInFullscreen === true) {
            self2.quitFullscreenButton();
        }
        self2.setCurrentGatewayLocation(gatewayLocationData);
    });
}

export function generateGatewaysLayer(self) {
    // If gatewaysLayer is already defined, clear all layers already existing inside
    if (self.gatewaysLayer) {
        self.gatewaysLayer.clearLayers();
    } else {
        self.gatewaysLayer = new L.FeatureGroup();
    }

    // Get current selected building and floor id
    let currentSelectedBuildingId = self.getSelectedBuildingId;
    let currentSelectedFloorId = self.getSelectedFloorId;

    // Then, for each gateway, create a marker layer and add it to the global gateways layer
    _.forEach(self.siteBLEGateways, function(gateway) {
        if (gateway.building && gateway.floor) {
            let buildingId = gateway.building.id ? gateway.building.id : gateway.building;
            let floorId = gateway.floor.id ? gateway.floor.id : gateway.floor;
            // Create markers only for gateways that are actually on the selected building and floor
            if (gateway.location && gateway.location.type && buildingId === currentSelectedBuildingId && floorId === currentSelectedFloorId) {
                let gatewayMarker = L.marker([gateway.location.coordinates[1], gateway.location.coordinates[0]], getGatewayIconFormat(gateway, self));
                if (gateway.ipAddress) {
                    var popup = new L.Popup().setContent("<strong id='gateway_tooltip_name'>" + gateway.name + "</strong><br><span id='gateway_tooltip_bleMacAddress'>" + gateway.bleMacAddress + "</span><br><span id='gateway_tooltip_coordinates' style='color: #999'>" + gateway.location.coordinates[1] + ", " + gateway.location.coordinates[0] + "</span>" + '<br><span style="color: #999">' + self.$t("common_floorElevationTooltip") + ": <span id='gateway_tooltip_floorElevation'>" + gateway.floorElevation + " m</span></span>" + '<br><span style="color: #999">' + self.$t("gtw_bleGatewayIpAddressLabel") + ": <span id='gateway_tooltip_ipAddress'>" + gateway.ipAddress + "</span></span>");
                } else {
                    var popup = new L.Popup().setContent("<strong id='gateway_tooltip_name'>" + gateway.name + "</strong><br><span id='gateway_tooltip_bleMacAddress'>" + gateway.bleMacAddress + "</span><br><span id='gateway_tooltip_coordinates' style='color: #999'>" + gateway.location.coordinates[1] + ", " + gateway.location.coordinates[0] + "</span>" + '<br><span style="color: #999">' + self.$t("common_floorElevationTooltip") + ": <span id='gateway_tooltip_floorElevation'>" + gateway.floorElevation + " m</span></span>");
                }
                // Define click listener on marker and change icon color when user click on a gateway marker
                if (self && self.$route && (self.$route.name == "siteGatewaysHealth" || self.$route.name == "heatmaps")) {
                    const gatewayHealth = self.$store.getters.getGatewayHealthById(gateway.id);
                    if (gatewayHealth){
                        if (gatewayHealth.lastEmission && gatewayHealth.lastEmission != -1) {
                            // AP is UP
                            var lastEmission = moment(gatewayHealth.lastEmission).fromNow();
                            gatewayMarker.bindPopup(popup, { offset: new L.Point(-17, -28) }).bindTooltip(gateway.name + "<br /><span style='color: #369921;font-weight: 500;'>"+i18n.t("troubleshooting_gatewayStatusUp")+"</span><br />("+lastEmission+")", { className: "customTooltipGatewayClass", permanent: true, direction: "left", offset: new L.Point(-35, -18) });
                        } else {
                            // AP is DOWN
                            gatewayMarker.bindPopup(popup, { offset: new L.Point(-17, -28) }).bindTooltip(gateway.name + "<br /><span style='color: #e61b45;font-weight: 500;'>"+i18n.t("troubleshooting_gatewayStatusDown")+"</span>", { className: "customTooltipGatewayClass", permanent: true, direction: "left", offset: new L.Point(-35, -18) });
                        }
                    } else {
                        gatewayMarker.bindPopup(popup, { offset: new L.Point(-17, -28) }).bindTooltip(gateway.name, { className: "customTooltipGatewayClass", permanent: true, direction: "left", offset: new L.Point(-35, -18) });
                        registerClickEventsOnGatewayMarker(self, gatewayMarker);
                    }
                } else {
                    gatewayMarker.bindPopup(popup, { offset: new L.Point(-17, -28) }).bindTooltip(gateway.name, { className: "customTooltipGatewayClass", permanent: true, direction: "left", offset: new L.Point(-35, -18) });
                    registerClickEventsOnGatewayMarker(self, gatewayMarker);
                }

                // This line is important: we are setting the id of the marker layer using gateway id.
                // It will be usefull later to find a layer by id.
                gatewayMarker.options.id = gateway.id;
                gatewayMarker.options.bleMacAddress = gateway.bleMacAddress;
                self.gatewaysLayer.addLayer(gatewayMarker);
            }
        }
    });
}

export function showOrHideGatewaysLayer(self) {
    console.log("gateway::showOrHideGatewaysLayer() - called");
    if (self.leafletMap && self.gatewaysLayer) {
        if (self.isGatewaysLayerDisplayed) {
            self.leafletMap.addLayer(self.gatewaysLayer);
        } else {
            self.leafletMap.removeLayer(self.gatewaysLayer);
        }
    }
}

/**
 * Get the minimal distance between gateway by gateway id.
 * @param {*} siteBLEGateways
 * @param {*} gateway
 * @param {*} locationCoordinates
 * @returns array of minimal distance by gateway identifier
 */
function getDistanceMinBetweenGateways(siteBLEGateways, gateway, locationCoordinates) {
    let distancesMinByGateway = {};
    const options = {units: 'meters'};
    const floorId = (gateway.floor && gateway.floor.id ? gateway.floor.id : gateway.floorId);
    for (let gtwA of siteBLEGateways) {
        let coordinatesA = undefined;
        if (gtwA.id === gateway.id) {
            coordinatesA = locationCoordinates;
        } else if (gtwA.floor && gtwA.floor.id === floorId && gtwA.location && gtwA.location.coordinates) {
            coordinatesA = gtwA.location.coordinates;
        }
        if (coordinatesA !== undefined) {
            const from = turf.point(coordinatesA);
            for (let gtwB of siteBLEGateways) {
                if (gtwB.id !== gtwA.id  && gtwB.floor && gtwB.floor.id === floorId && gtwB.location && gtwB.location.coordinates) {
                    const to = turf.point(gtwB.location.coordinates);
                    const distanceAB = turf.distance(from, to, options);
                    if (distancesMinByGateway[gtwA.id] === undefined || distancesMinByGateway[gtwA.id] > distanceAB) {
                        distancesMinByGateway[gtwA.id] = distanceAB;
                    }
                }
            }
        }
    }
    return distancesMinByGateway;
}

/**
 * Get an array of sorted distance (by asc) from a gateway to all other gateways on the same floor.
 * @param {*} siteBLEGateways
 * @param {*} gateway
 * @param {*} locationCoordinates
 * @returns array of ordered distances
 */
function getOrderedDistancesArrayOfGateway(siteBLEGateways, gateway, locationCoordinates) {
    let distancesArray = [];
    const options = {units: 'meters'};
    const from = turf.point(locationCoordinates);
    const floorId = (gateway.floor && gateway.floor.id ? gateway.floor.id : gateway.floorId);
    for (let gtw of siteBLEGateways) {
        if (gtw.id !== gateway.id  && gtw.floor && gtw.floor.id === floorId && gtw.location && gtw.location.coordinates) {
            const to = turf.point(gtw.location.coordinates);
            const distance = turf.distance(from, to, options);
            distancesArray.push(distance);
        }
    }
    let orderedDistances = distancesArray.sort((a,b) => a-b);
    console.log("gateway::getOrderedDistancesArrayOfGateway() - result:", orderedDistances);
    return orderedDistances;
}

/**
 * Get location rules of a gateway.
 * @param {*} siteBLEGateways
 * @param {*} gateway
 * @param {*} locationCoordinates
 * @returns object of gateway location rule keys
 */
export function getLocationRulesOfGateway(siteBLEGateways, gateway, locationCoordinates) {
    let locationRules = {
        locationLess10m: false,
        locationMore15m: false
    };
    const orderedDistances = getOrderedDistancesArrayOfGateway(siteBLEGateways, gateway, locationCoordinates);
    if (orderedDistances.length > 0) {
        // Get the first element which is the distance which has near that position
        const nearGatewayDistance = orderedDistances[0];
        if (nearGatewayDistance < 10) {
            console.log("gateway::getLocationRulesOfGateway() - The gateway is located less than 10m from another gateway");
            locationRules.locationLess10m = true;
        } else if (nearGatewayDistance > 15) {
            console.log("gateway::getLocationRulesOfGateway() - The gateway is located more than 15m from the nearest gateway (" + orderedDistances[0] + ")");
            locationRules.locationMore15m = true;
        }
    }
    return locationRules;
}

/*
 * Function used to reset all markers icon to default icon
 */
function resetAllLayersToDefaultMarker(self) {
    if (self.gatewaysLayer) {
        // Reset all gateways marker to default icon
        for (var i in self.gatewaysLayer._layers) {
            if (self.gatewaysLayer._layers.hasOwnProperty(i)) {
                var currentGatewayMarker = self.gatewaysLayer._layers[i];
                var BASE_STYLE = DEFAULT_GATEWAY_ICON;
                BASE_STYLE.options.html = '<img id="gatewayMarker_' + currentGatewayMarker.options.bleMacAddress + '" class="gatewayMarkerFormat" src="/assets/markers/gateway.png" />';
                var defaultGatewayIcon = L.DivIcon.extend(BASE_STYLE);
                currentGatewayMarker.setIcon(new defaultGatewayIcon());
            }
        }
    }
}

/*
 * Function used to highlight one gateway marker on the map
 */
function highlightMarker(markerLayer) {
    // Change color of clicked marker
    var HIGHLIGHT_BASE_STYLE = HIGHLIGHT_GATEWAY_ICON;
    HIGHLIGHT_BASE_STYLE.options.html = '<img id="gatewayMarker_' + markerLayer.options.bleMacAddress + '" class="gatewayMarkerFormat" src="/assets/markers/gateway_highlight.png" />';
    var highlightGatewayIcon = L.DivIcon.extend(HIGHLIGHT_BASE_STYLE);
    markerLayer.setIcon(new highlightGatewayIcon());
}

/*
 * Function used to register click on gateway markers
 */
function registerClickEventsOnGatewayMarker(self, gatewayMarker) {
    // Register click event on marker icon
    gatewayMarker.on("click", function(e) {
        console.log("gateway::registerClickEventsOnGatewayMarker() - event click - currentBLEGatewayLocation:", self.currentBLEGatewayLocation);
        // Case of edition in progress
        if (self.currentBLEGatewayLocation  && self.currentBLEGatewayLocation.state === "EDIT") {
            // Do not select gateway with highlighting
            return;
        }
        // Reset all markers
        resetAllLayersToDefaultMarker(self);
        // Set selected gateway
        var gatewayId = e.target.options.id;
        self.setCurrentGatewayLocation({ id: gatewayId, state: "HIGHLIGHT", isHighlightFromMap: true });
        // Highlight the selected one
        var markerLayer = e.target;
        highlightMarker(markerLayer);
    });

    // Register click event on close popup
    gatewayMarker.getPopup().on("remove", () => {
        console.log("gateway::registerClickEventsOnGatewayMarker() - event getPopup remove - currentBLEGatewayLocation:", self.currentBLEGatewayLocation);
        // Case of edition in progress
        if (self.currentBLEGatewayLocation  && self.currentBLEGatewayLocation.state === "EDIT") {
            // Do not select gateway with highlighting
            return;
        }
        if (self.leafletMap.hasLayer(gatewayMarker) && self.currentBLEGatewayLocation.state === "HIGHLIGHT") {
            // Reset selected gateway to empty
            self.setCurrentGatewayLocation({ id: "", bleMacAddress: "", state: "" });
        }
        // Reset default icon only if the marker is always on the map
        // It could be deleted if the remove event has been fired after delete location
        if (self.leafletMap.hasLayer(gatewayMarker)) {
            // Reset default marker icon
            var defaultGatewayIcon = L.Icon.extend(DEFAULT_GATEWAY_ICON);
            gatewayMarker.setIcon(new defaultGatewayIcon());
        }
    });

    // Register click event on popup open to force again selection of gateway
    gatewayMarker.on("popupopen", e => {
        console.log("gateway::registerClickEventsOnGatewayMarker() - event popupopen - currentBLEGatewayLocation:", self.currentBLEGatewayLocation);
        // Case of edition in progress
        if (self.currentBLEGatewayLocation && self.currentBLEGatewayLocation.state === "EDIT") {
            // Do not select gateway with highlighting
            return;
        }
        // Set selected gateway
        var gatewayId = e.target.options.id;
        self.setCurrentGatewayLocation({ id: gatewayId, state: "HIGHLIGHT" });
    });
}

function getGatewayIconFormat(gateway, self) {
    if (gateway) {

        let iconObj = {};
        if (self && self.$route && (self.$route.name == "siteGatewaysHealth" || self.$route.name == "heatmaps")) {

            const gatewayHealth = self.$store.getters.getGatewayHealthById(gateway.id);
            
            if (gatewayHealth) {
                if (gatewayHealth.lastEmission && gatewayHealth.lastEmission != -1) {
                    iconObj = {
                        shadowUrl: null,
                        className: "leaflet-autocalibrationtag-location-mouse-marker",
                        iconAnchor: new L.Point(35, 35),
                        iconSize: new L.Point(35, 35),
                        html: '<img id="gatewayMarker_' + gateway.bleMacAddress + '" class="gatewayMarkerFormat" src="/assets/markers/gateway_up.png" />',
                        iconUrl: "/assets/markers/gateway_up.png"
                    };
                } else {
                    iconObj = {
                        shadowUrl: null,
                        className: "leaflet-autocalibrationtag-location-mouse-marker",
                        iconAnchor: new L.Point(35, 35),
                        iconSize: new L.Point(35, 35),
                        html: '<img id="gatewayMarker_' + gateway.bleMacAddress + '" class="gatewayMarkerFormat" src="/assets/markers/gateway_down.png" />',
                        iconUrl: "/assets/markers/gateway_down.png"
                    };
                }
            } else {
                // To display gateway on the map
                iconObj = {
                    shadowUrl: null,
                    className: "leaflet-autocalibrationtag-location-mouse-marker",
                    iconAnchor: new L.Point(35, 35),
                    iconSize: new L.Point(35, 35),
                    html: '<img id="gatewayMarker_' + gateway.bleMacAddress + '" class="gatewayMarkerFormat" src="/assets/markers/gateway.png" />',
                    iconUrl: "/assets/markers/gateway.png"
                };
            }

        } else {

            // To display gateway on the map
            iconObj = {
                shadowUrl: null,
                className: "leaflet-autocalibrationtag-location-mouse-marker",
                iconAnchor: new L.Point(35, 35),
                iconSize: new L.Point(35, 35),
                html: '<img id="gatewayMarker_' + gateway.bleMacAddress + '" class="gatewayMarkerFormat" src="/assets/markers/gateway.png" />',
                iconUrl: "/assets/markers/gateway.png"
            };

        }

        return {
            icon: L.divIcon(iconObj)
        };
    } else {
        // To define location of the gateway on the map in Gateway location page
        return DEFAULT_GATEWAY_ICON.options;
    }
}
