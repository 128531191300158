<template>
    <div v-if="visible ? visible : false" class="helpPlaceImage alert alert-light alert-elevate fade show" role="alert">
        <!-- Icon -->
        <div class="alert-icon"><i :class="icon ? icon : ''"></i></div>
        <!-- Message -->
        <div class="alert-text">{{ message ? message : "" }}</div>
        <!-- Close button -->
        <div v-if="closeButton ? closeButton : false" class="alert-close">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true"><i class="la la-close"></i></span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created: function() {
        console.log("Component(mapSideBar)::created() - called");
    },
    mounted: function() {
        console.log("Component(mapSideBar)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(mapSideBar)::destroyed() - called");
    },
    props: {
        icon: String,
        message: String,
        visible: Boolean,
        closeButton: Boolean
    },

    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.helpPlaceImage {
    position: relative;
    z-index: 9999;
    width: 40%;
    margin: 10px auto;
}
</style>
