import i18n from "../../../i18n";

/**
 * Init the location map for indoor areas definition mode
 * @param self
 */
export function initFloors(self) {
    self.initActionsBar(["centerMapButton", "opacitySlider"]);
    self.setMapTilesLayer("OpenStreetMap");
    displayFloorMap(self, self.currentFloor, "NEW_OR_EDIT_FLOOR");
    displayFloorMapMarkers(self); // Display floor map markers to be able to change position of the map
    displayFloorMapPositionningHelper(self);
}

/**
 * This method is used to display selected floor on map
 */
export function displaySelectedFloors(self, floors) {
    // First, delete all floors layers currently displayed.
    for (let i = 0; i < self.displayedFloorsMapLayers.length; i++) {
        self.leafletMap.removeLayer(self.displayedFloorsMapLayers[i]);
    }

    // Remove all generated list of floors displayed
    $("#selectedFloorsList").empty();

    // Now, one layer for each new floor to display
    for (let i = 0; i < floors.length; i++) {
        let floorObj = floors[i];
        // Generate a new image verlay on the map for each selected floor
        let mode = "FLOOR_SELECTOR";
        displayFloorMap(self, floorObj, mode);
        self.displayedFloorsMapLayers.push(self.floorImageOverlay);
        // Generate a new item in selected floors list
        let buildingObj = self.$store.getters.getBuildingById(floorObj.building);
        addSelectedFloorToList(floorObj, buildingObj);
        // Center map on new selected floor
        self.centerMapOnImageLayer(self.floorImageOverlay);
    }
}

export function addSelectedFloorToList(floorObj, buildingObj) {
    let html =
        `
                <li class="leaflet-range-control leaflet-control currentBuildingsAndFloorName">
                    <span class="floorSelector_currentBuildingName">` +
        buildingObj.name +
        `</span>
                    /
                    <span>` +
        floorObj.name +
        `</span> (` +
        i18n.t("b&f_level") +
        `: <span>` +
        floorObj.floorNumber +
        `</span>)
                </li>`;
    $("#selectedFloorsList").append(html);
}

// This generic function is used to add one floor map as a layer on the map
export function displayFloorMap(self, floorObject, mode) {
    var c1Coords, c2Coords, c3Coords;
    if (floorObject.mapCoordinates) {
        // Floor was loaded from backend, display it at the stored position
        c1Coords = {
            lat: floorObject.mapCoordinates.coordinates[0][1],
            lng: floorObject.mapCoordinates.coordinates[0][0]
        };
        c2Coords = {
            lat: floorObject.mapCoordinates.coordinates[1][1],
            lng: floorObject.mapCoordinates.coordinates[1][0]
        };
        c3Coords = {
            lat: floorObject.mapCoordinates.coordinates[2][1],
            lng: floorObject.mapCoordinates.coordinates[2][0]
        };
    } else {
        // This is a new floor, let's initialize the coordinates

        // Get map bounds
        let bounds = self.leafletMap.getBounds();
        let mapBounds = {
            northLat: bounds._northEast.lat,
            southLat: bounds._southWest.lat,
            westLng: bounds._southWest.lng,
            eastLng: bounds._northEast.lng
        };

        // By default, center the overlay at 50% inside the map
        c1Coords = {
            lat: 0.75 * mapBounds.northLat + 0.25 * mapBounds.southLat,
            lng: 0.75 * mapBounds.westLng + 0.25 * mapBounds.eastLng
        };
        c2Coords = {
            lat: 0.75 * mapBounds.northLat + 0.25 * mapBounds.southLat,
            lng: 0.25 * mapBounds.westLng + 0.75 * mapBounds.eastLng
        };
        c3Coords = {
            lat: 0.25 * mapBounds.northLat + 0.75 * mapBounds.southLat,
            lng: 0.75 * mapBounds.westLng + 0.25 * mapBounds.eastLng
        };
    }

    // Get floorMap image from store
    let floorImage = new Image();
    
    // Use base64 image
    floorImage.src = floorObject.map;

    // Create floor overlay upon current LeafLet map
    self.floorImageOverlay = L.imageOverlay.rotated(floorImage, L.latLng(c1Coords.lat, c1Coords.lng), L.latLng(c2Coords.lat, c2Coords.lng), L.latLng(c3Coords.lat, c3Coords.lng), {
        opacity: mode === "FLOOR_SELECTOR" ? 1 : 0.4,
        interactive: true
    });
    self.leafletMap.addLayer(self.floorImageOverlay);

}

// Display markers in order to position floor layout on the building image of leaflet map
export function displayFloorMapMarkers(self) {
    var mapEl = self;

    let cornersCoords = self.floorImageOverlay.getGeoJsonMapCoordinates();
    let corner1 = L.latLng(cornersCoords.coordinates[0][1], cornersCoords.coordinates[0][0]),
        corner2 = L.latLng(cornersCoords.coordinates[1][1], cornersCoords.coordinates[1][0]),
        corner3 = L.latLng(cornersCoords.coordinates[2][1], cornersCoords.coordinates[2][0]);

    self.markers[1] = L.marker(corner1, {
        draggable: true
    }).addTo(self.leafletMap);
    self.markers[2] = L.marker(corner2, {
        draggable: true
    }).addTo(self.leafletMap);
    self.markers[3] = L.marker(corner3, {
        draggable: true
    }).addTo(self.leafletMap);

    // Register events on markers
    function repositionImage() {
        mapEl.floorImageOverlay.reposition(mapEl.markers[1].getLatLng(), mapEl.markers[2].getLatLng(), mapEl.markers[3].getLatLng());
        mapEl.$emit("onchangefloormap");
    }
    self.markers[1].on("drag dragend", repositionImage);
    self.markers[2].on("drag dragend", repositionImage);
    self.markers[3].on("drag dragend", repositionImage);

    self.floorImageOverlay.initMoveMarker(self.markers, self.leafletMap);
    self.floorImageOverlay.initRotateMarker(self.markers, self.leafletMap);
    self.floorImageOverlay.initResizeMarker(self.markers, self.leafletMap);

    self.floorImageOverlay._moveMarker.on("drag dragstart", function() { mapEl.$emit("onchangefloormap") });
    self.floorImageOverlay._resizeMarker.on("drag dragstart", function() { mapEl.$emit("onchangefloormap") });
    self.floorImageOverlay._rotateMarker.on("drag dragstart", function() { mapEl.$emit("onchangefloormap") });
}

// Display floor map helper element that gives some information to the user to place correclty his PNG image on the map
export function displayFloorMapPositionningHelper(self) {
    self.displayMapAlertWindow = {
        visible: true,
        closeButton: true,
        message: self.$t("b&f_helpForPlacingFloorMap"),
        icon: "flaticon-questions-circular-button"
    };
}