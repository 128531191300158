import {
    helpers,
    booleanPointInPolygon,
    booleanDisjoint
} from "@turf/turf";
import i18n from "../../../i18n";

/**
 * Init the location map for indoor areas definition mode
 * @param self
 */
export function initIndoorAreas(self) {

    console.log("Component(LocationMap)::initIndoorAreas() - called");

    self.initActionsBar(["centerMapButton"]);
    self.setMapTilesLayer("OpenStreetMap");
    self.initDrawControl();
    // Force set indoor areas displayed because if we are in the indoorAreas page
    self.setIndoorAreasLayerDisplayed(true);

    // Hide icons/buttons automatically added by Leaflet Draw library
    $(".leaflet-draw").hide();

    // Manage Escape button for cancelling creation/edition of indoor area
    let onKeyDown = e => {
        if (e.keyCode == 27) {
            // esc button
            if (self.currentIndoorArea.state === "START_CREATION") {
                self.setCurrentIndoorArea({
                    id: "",
                    state: "CANCEL_CREATION"
                });
            } else if (self.currentIndoorArea.state === "EDIT") {
                self.setCurrentIndoorArea({
                    id: self.currentIndoorArea.id,
                    bleMacAddress: self.currentIndoorArea.bleMacAddress,
                    state: "CANCEL_EDIT"
                });
            } else if (self.currentIndoorArea.state === "FINALIZE_EDITION") {
                self.setCurrentIndoorArea({
                    id: "",
                    state: "CANCEL_EDIT"
                });
            } else if (self.currentIndoorArea.state === "START_MEASURE") {
                self.setCurrentIndoorArea({
                    id: "",
                    state: "CANCEL_MEASURE"
                });
            }
        }
    };
    L.DomEvent.addListener(document, "keydown", onKeyDown, self.leafletMap);
}

/**
 * Method called to init draw controls that will be used at indoor area creation / edition
 * @param self
 */
export function initDrawControlForIndoorArea(self) {
    console.log("Component(LocationMap)::initDrawControlForIndoorArea() - called ");
    var options = {
        position: "topleft",
        draw: {
            polygon: {
                allowIntersection: false, // Restricts shapes to simple polygons
                drawError: {
                    color: "#e1e100", // Color the shape will turn when intersects
                    message: i18n.t("area_drawingIntersectionsError") // Message that will show when intersect
                },
                shapeOptions: {
                    dashArray: "10, 10",
                    fill: true,
                    fillColor: "#fe57a1",
                    fillOpacity: 0.1,
                    maintainColor: false
                }
            },
            polyline: false,
            circle: false,
            rectangle: false,
            marker: false,
            circlemarker: false
        },
        edit: {
            featureGroup: self.editableLayers,
            remove: false
        }
    };

    return options;
}

export function registerEvents(self) {
    // This listener is only used when user has clicke don start measure button
    self.leafletMap.on(L.Draw.Event.DRAWSTART, e => {
        if (e && e.layerType && e.layerType === "polyline") {
            self.setCurrentIndoorArea({
                id: "",
                state: "START_MEASURE"
            });
        }
    });

    // This listener is called when polygon has been created?
    self.leafletMap.on(L.Draw.Event.CREATED, e => {
        // First check that drawn polygon is not covering another existing indoor area
        let polygon = e.layer.toGeoJSON().geometry;

        if (isPolygonCoveringOthers(self, polygon, undefined)) {
            // show alert
            self.displayMapAlertWindow = {
                visible: true,
                closeButton: false,
                message: self.$t("area_errorOnPolygonCreation"),
                icon: "flaticon-exclamation-square"
            };
            setTimeout(() => {
                // hide alert after 5 seconds
                self.displayMapAlertWindow = {
                    visible: false
                };
            }, 5000);
            setTimeout(() => {
                // cancel drawing
                self.drawHandler.disable();
                self.drawHandler = null;
                // start drawing of a new polygon
                startIndoorAreaCreation(self);
            }, 1000); // 1 second delay to prevent exceptions in Leaflet Draw
            return;
        }

        let geodesicArea = Math.round(Math.round(L.GeometryUtil.geodesicArea(e.layer.getLatLngs()[0]) * 1000) / 1000);
        var indoorAreaData = {
            id: self.currentIndoorArea.id,
            polygon: polygon,
            area: geodesicArea,
            state: "SAVE_CREATION"
        };
        self.setCurrentIndoorArea(indoorAreaData);
    });

    self.leafletMap.on(L.Draw.Event.DRAWVERTEX, function () {
        if (self.drawHandler && self.drawHandler._poly) {
            let poly = self.drawHandler._poly;
            let latlngs = poly.getLatLngs();
            let lastlatlng = latlngs[latlngs.length - 1];
            let previouslatlng = latlngs.length > 1 ? latlngs[latlngs.length - 2] : null;

            if (isDrawnPointInExistingIndoorArea(self, lastlatlng, previouslatlng)) {
                // Point has been drawn inside an existing indoor area
                // show alert
                let initialAlert = self.drawHandler.options.drawError.message;
                self.drawHandler.options.drawError.message = i18n.t("area_drawingUponOtherPolygonError");
                self.drawHandler._showErrorTooltip();
                self.drawHandler.options.drawError.message = initialAlert;
            }

            // If the user clicks on the leaflet bar control or if the point is not located in another area, we remove it.
            if (isDrawnPointInExistingIndoorArea(self, lastlatlng, previouslatlng) || self.isLeafletControlMouseOver === true) {
                self.isLeafletControlMouseOver = false;
                // Remove point
                if (latlngs.length === 1) {
                    setTimeout(() => {
                        // cancel drawing
                        self.drawHandler.disable();
                        self.drawHandler = null;
                        // start drawing of a new polygon
                        startIndoorAreaCreation(self);
                    }, 1000); // 1 second delay to prevent exceptions in Leaflet Draw
                } else {
                    // Remove last drawn point
                    self.drawHandler.deleteLastVertex();
                }
            }
        }
    });
}

/**
 * return true if polygon covered others
 * @param self
 * @param polygon
 * @param polygonId id of the poylgon if it's already exists, undefined if it's a new one
 * @returns {boolean} true if covering
 */

export function isPolygonCoveringOthers(self, polygon, polygonId) {
    let indoorArea = null;
    // Get current selected building and floor id
    let currentSelectedBuildingId = self.getSelectedBuildingId;
    let currentSelectedFloorId = self.getSelectedFloorId;

    // For each indoor area on the same building / floor, check that the intersection between both polygons is null
    for (let item in self.siteIndoorAreas) {
        if (self.siteIndoorAreas.hasOwnProperty(item)) {
            indoorArea = self.siteIndoorAreas[item];
            if (polygonId && indoorArea.id === polygonId) {
                continue;
            }
            // Keep only indoorAreas located on the selected building and floor
            if (indoorArea.building.id === currentSelectedBuildingId && indoorArea.floor.id === currentSelectedFloorId) {
                // Get indoor area polygon in geoJson format
                if (!booleanDisjoint(polygon, indoorArea.polygon)) {
                    return true;
                }
            }
        }
    }
    return false;
}

export function isDrawnPointInExistingIndoorArea(self, drawnPointLatLng, previousPointLatLng) {
    let drawnPoint = null,
        drawnLine = null;
    if (previousPointLatLng === null) {
        // This is the first drawn point
        drawnPoint = helpers.point([drawnPointLatLng.lng, drawnPointLatLng.lat]);
    } else {
        drawnLine = helpers.lineString([
            [previousPointLatLng.lng, previousPointLatLng.lat],
            [drawnPointLatLng.lng, drawnPointLatLng.lat]
        ]);
    }

    // Get current selected building and floor id
    let currentSelectedBuildingId = self.getSelectedBuildingId;
    let currentSelectedFloorId = self.getSelectedFloorId;

    let polygon = null;
    // Then, for each indoor area on the same building / floor, check that this point/line is not in the given indoor area
    for (let item in self.siteIndoorAreas) {
        if (self.siteIndoorAreas.hasOwnProperty(item)) {
            let indoorArea = self.siteIndoorAreas[item];
            // Keep only indoorAreas located on the selected building and floor
            if (indoorArea.building.id === currentSelectedBuildingId && indoorArea.floor.id === currentSelectedFloorId) {
                // Get indoor area polygon in geoJson format
                polygon = indoorArea.polygon;
                // Check that the point/line is not part of this polygon
                if (previousPointLatLng === null && booleanPointInPolygon(drawnPoint, polygon)) {
                    // First point of the new polygon, and it is inside this indoor area
                    return true;
                } else if (previousPointLatLng !== null && !booleanDisjoint(drawnLine, polygon)) {
                    // The last line of the polygon is partly inside this indoor area
                    return true;
                }
            }
        }
    }
    // All indoor areas on the same building and floor have been checked
    return false;
}

/**
 * Method called to do specific actions following the current state of currentIndoorArea
 * @param self
 * @param action
 */
export function updateCurrentIndoorArea(self, indoorArea) {
    if (!indoorArea.state) {
        // show again measure control
        self.showMeasureControl();
        resetAllLayersToDefaultColor(self);
        if (self.leafletMap) {
            self.leafletMap.closePopup();
        }
    } else {
        if (indoorArea.state === "START_CREATION") {
            startIndoorAreaCreation(self);
            self.hideMeasureControl();
        } else if (indoorArea.state === "SAVE_CREATION") {
            saveNewIndoorAreaAction(self);
        } else if (indoorArea.state === "CANCEL_CREATION") {
            cancelNewIndoorAreaAction(self);
        } else if (indoorArea.state === "EDIT") {
            startEditIndoorAreaAction(self);
            self.hideMeasureControl();
        } else if (indoorArea.state === "SAVE_EDIT") {
            saveEditIndoorAreaAction(self);
        } else if (indoorArea.state === "CANCEL_EDIT") {
            cancelEditIndoorAreaAction(self);
        } else if (indoorArea.state === "HIGHLIGHT") {
            highlightIndoorAreaAction(self);
        } else if (indoorArea.state === "START_MEASURE") {
            startMeasureAction(self);
        } else if (indoorArea.state === "CANCEL_MEASURE") {
            cancelMeasureAction(self);
        }
    }
}

/**
 * Method called when user start measure action
 * @param self
 */
export function startMeasureAction(self) {
    self.hideMeasureControl();
}

/**
 * Method called when user cancel measure action
 * @param self
 */
export function cancelMeasureAction(self) {
    if (self.measureHandler && self.measureHandler.handler) {
        self.measureHandler.handler.disable();
    }
    self.showMeasureControl();
    self.setCurrentIndoorArea({
        id: "",
        state: ""
    });
}

/**
 * Method called at page loading or floor selection, to display all indoor areas of the selected floor
 * @param self
 * @param action
 */
export function generateIndoorAreasLayer(self) {
    // If indoorAreasLayer is already defined, clear all layers already existing inside
    if (self.indoorAreasLayer) {
        self.indoorAreasLayer.clearLayers();
    } else {
        self.indoorAreasLayer = new L.FeatureGroup();
    }

    // Get current selected building and floor id
    let currentSelectedBuildingId = self.getSelectedBuildingId;
    let currentSelectedFloorId = self.getSelectedFloorId;

    // Then, for each indoor area, create a polygon layer and add it to the global indoor areas layer
    _.forEach(self.siteIndoorAreas, function (indoorArea) {
        // Display polygons only for indoorAreas that are actually on the selected building and floor
        if (indoorArea.building.id === currentSelectedBuildingId && indoorArea.floor.id === currentSelectedFloorId) {
            // Convert geoJson coordinates to leaflet polygon format
            let polygonCoords = [];
            for (var i = 0; i < indoorArea.polygon.coordinates[0].length - 1; i++) {
                polygonCoords.push([indoorArea.polygon.coordinates[0][i][1], indoorArea.polygon.coordinates[0][i][0]]);
            }

            let indoorAreaPolygon = L.polygon(polygonCoords);

            // Add popup and tooltip to generated polygon
            let areaUnit = indoorArea.areaUnit === "squareMeters" ? "m<sup>2</sup>" : "ft<sup>2</sup>";
            var popup = new L.Popup().setContent("<strong>" + indoorArea.name + "</strong><br>" + indoorArea.area + " " + areaUnit + '<br><span style="color: #999"></span>');
            indoorAreaPolygon.bindPopup(popup);
            indoorAreaPolygon.bindTooltip(indoorArea.name, {
                className: "customTooltipIndoorAreaClass",
                permanent: true,
                direction: "center"
            });

            // Define click listener on area and change color of this area when user click on it
            registerClickEventsOnIndoorAreaPolygon(self, indoorAreaPolygon);

            // This line is important: we are setting the id of the indoorArea polygon layer using indoorArea id.
            // It will be usefull later to find a layer by id.
            indoorAreaPolygon.options.id = indoorArea.id;

            // Define polygon display color
            let polygonColor = "#3388ff";
            if (indoorArea.color) {
                polygonColor = "#" + indoorArea.color;
            }
            indoorAreaPolygon.setStyle({
                color: polygonColor,
                fillColor: polygonColor,
                className: "indoorArea_" + indoorArea.id
            });

            self.indoorAreasLayer.addLayer(indoorAreaPolygon);
        }
    });
}

/*
 * Function used to reset all markers icon to default icon
 */
function resetAllLayersToDefaultColor(self) {
    if (self.indoorAreasLayer) {
        // Reset all areas to their original color
        for (var i in self.indoorAreasLayer._layers) {
            if (self.indoorAreasLayer._layers.hasOwnProperty(i)) {
                var currentIndoorAreaPolygon = self.indoorAreasLayer._layers[i];
                // Get style from store
                var indoorAreaId = currentIndoorAreaPolygon.options.id;
                var indoorAreaInStore = self.getIndoorAreaById(indoorAreaId);
                if (indoorAreaInStore && indoorAreaInStore.color) {
                    currentIndoorAreaPolygon.setStyle({
                        color: "#" + indoorAreaInStore.color,
                        fillColor: "#" + indoorAreaInStore.color
                    });
                }
            }
        }
    }
}

/*
 * Function used to highlight one area layer on the map
 */
function highlightArea(areaLayer) {
    areaLayer.setStyle({
        color: "#734abd",
        fillColor: "#734abd"
    });
}

/*
 * Function used to register click on area polygon
 */
function registerClickEventsOnIndoorAreaPolygon(self, indoorAreaPolygon) {
    // Register click event on area layer
    indoorAreaPolygon.on("click", function (e) {
        // Reset all area layers
        resetAllLayersToDefaultColor(self);
        // Set selected area
        var indoorAreaId = e.target.options.id;
        self.setCurrentIndoorArea({
            id: indoorAreaId,
            state: "HIGHLIGHT",
            isHighlightFromMap: true
        });
        // Highlight the selected one
        var areaLayer = e.target;
        highlightArea(areaLayer);
    });

    // Register click event on close popup
    indoorAreaPolygon.getPopup().on("remove", () => {
        if (self.leafletMap.hasLayer(indoorAreaPolygon) && self.currentIndoorArea.state === "HIGHLIGHT") {
            // Reset selected area to empty
            self.setCurrentIndoorArea({
                id: "",
                state: ""
            });
        }
        // Reset default color only if the marker is always on the map
        // It could be deleted if the remove event has been fired after delete location
        if (self.leafletMap.hasLayer(indoorAreaPolygon)) {
            var indoorAreaId = indoorAreaPolygon.options.id;
            var indoorAreaInStore = self.getIndoorAreaById(indoorAreaId);
            indoorAreaPolygon.setStyle({
                color: "#" + indoorAreaInStore.color,
                fillColor: "#" + indoorAreaInStore.color
            });
        }
    });

    // Register click event on popup open to force again selection of area
    indoorAreaPolygon.on("popupopen", e => {
        // Set selected area
        var indoorAreaId = e.target.options.id;
        self.setCurrentIndoorArea({
            id: indoorAreaId,
            state: "HIGHLIGHT"
        });
    });
}

export function showOrHideIndoorAreasLayer(self) {

    console.log("Component(LocationMap)::showOrHideIndoorAreasLayer()) - called");

    if (self.leafletMap && self.indoorAreasLayer) {
        if (self.isIndoorAreasLayerDisplayed) {
            self.leafletMap.addLayer(self.indoorAreasLayer);
        } else {
            self.leafletMap.removeLayer(self.indoorAreasLayer);
        }
    }
}

/**
 * Method called to start creation of an indoor area on the map
 * @param self
 */
export function startIndoorAreaCreation(self) {
    console.log("Component(LocationMap)::startIndoorAreaCreation()) - Start drawing polygon !");

    // Close all opened popups on the map
    self.hideAllPopupOnMap();

    self.drawHandler = new L.Draw.Polygon(self.leafletMap, self.drawControl.options.draw.polygon);
    self.drawHandler.enable();
}

/**
 * Method called to save new indoor area on the map
 * @param self
 */
export function saveNewIndoorAreaAction(self) {
    console.log("Component(LocationMap)::saveNewIndoorAreaAction()) - Start saving new indoor area !");
    let indoorAreaData = {
        id: self.currentIndoorArea.id,
        name: "",
        color: "3388ff", // blue by default
        siteId: self.siteId,
        buildingId: self.getSelectedBuildingId,
        floorId: self.getSelectedFloorId,
        polygon: self.currentIndoorArea.polygon, // previously generated in locationMap
        area: self.currentIndoorArea.area, // previously calculated in locationMap
        areaUnit: "squareMeters",
        state: "FINALIZE_CREATION"
    };

    // Will open confirmation pop-up from indoorAreaModal.vue component

    if (self.isInFullscreen === true) {
        self.quitFullscreenButton();
    }
    self.setCurrentIndoorArea(indoorAreaData);
}

/**
 * Method called to cancel new indoor area on the map
 * @param self
 */
export function cancelNewIndoorAreaAction(self) {
    console.log("Component(LocationMap)::cancelNewIndoorAreaAction()) - Cancelling new indoor area creation !");

    self.setCurrentIndoorArea({
        id: "",
        state: ""
    });

    self.drawHandler.disable();
    self.drawHandler = null;

    // Show again floor selector on the map
    self.showFloorMapSelector();
}

/**
 * Method called to start edit of an autocalibration tag on the map
 * @param self
 */
export function startEditIndoorAreaAction(self) {
    console.log("Component(LocationMap)::startEditIndoorAreaAction()) - Start editing indoor area !");

    // Get full indoor area object
    let indoorAreaObject = self.getIndoorAreaById(self.currentIndoorArea.id);
    // Hide floor selector on the map
    self.hideFloorMapSelector();
    // Close all opened popups on the map
    self.hideAllPopupOnMap();
    // Check if the indoor area is located on the current selected floor
    if (indoorAreaObject.building.id === self.getSelectedBuildingId && indoorAreaObject.floor.id === self.getSelectedFloorId) {
        // Get layer of selected indoor area by id
        let currentEditedIndoorAreaLayer = self.indoorAreasLayer.getLayerById(self.currentIndoorArea.id);
        // Center map on highlighted indoor area
        self.centerMapOnPolygon(currentEditedIndoorAreaLayer);
        // First remove all layers in editable Layers because we are able to edit one layer once a time
        self.editableLayers.clearLayers();
        // Add only selected AutoCalibrationTag layer to the list of edited layers
        self.editableLayers.addLayer(currentEditedIndoorAreaLayer);
        // Force activation of edit mode now ! Only layers available in the editableLayers array will now goes in edit mode in location map.
        self.editHandler.enable();
    } else {
        // We need to switch to the good floor and then edit the indoor area
        self.handleSelectFloorMapById(indoorAreaObject.floor.id);
        // No need to implement callback here,just wait few ms before leaflet update the map.
        setTimeout(() => {
            startEditIndoorAreaAction(self);
        }, 800);
    }
}

/**
 * Method called to save edit of a indoor area on the map
 * @param self
 */
export function saveEditIndoorAreaAction(self) {
    console.log("Component(LocationMap)::saveEditIndoorAreaAction()) - Start saving edited indoor area !");

    // Get last version of indoor area
    let indoorArea = self.getIndoorAreaById(self.currentIndoorArea.id);

    // Generate polygon and area from edited Leaflet layer
    let currentEditedIndoorAreaLayer = self.indoorAreasLayer.getLayerById(self.currentIndoorArea.id);
    let polygon = currentEditedIndoorAreaLayer.toGeoJSON().geometry;
    let geodesicArea = Math.round(Math.round(L.GeometryUtil.geodesicArea(currentEditedIndoorAreaLayer.getLatLngs()[0]) * 1000) / 1000);

    if (isPolygonCoveringOthers(self, polygon, self.currentIndoorArea.id)) {
        // show alert
        self.displayMapAlertWindow = {
            visible: true,
            closeButton: false,
            message: self.$t("area_errorOnPolygonEdition"),
            icon: "flaticon-exclamation-square"
        };
        setTimeout(() => {
            // hide alert after 5 seconds
            self.displayMapAlertWindow = {
                visible: false
            };
        }, 5000);

        // Will open confirmation pop-up from indoorAreaModal.vue component
        self.currentIndoorArea.state = "EDIT";
        self.setCurrentIndoorArea(self.currentIndoorArea);

        return;
    }

    var indoorAreaData = {
        id: indoorArea.id,
        name: indoorArea.name,
        color: indoorArea.color,
        siteId: indoorArea.site,
        buildingId: indoorArea.building.id,
        floorId: indoorArea.floor.id,
        polygon: polygon,
        area: geodesicArea,
        areaUnit: "squareMeters",
        state: "FINALIZE_EDITION"
    };

    // Will open confirmation pop-up from indoorAreaModal.vue component
    self.setCurrentIndoorArea(indoorAreaData);

    self.editHandler.disable();
    // Show again floor selector on the map
    self.showFloorMapSelector();
}

/**
 * Method called to cancel edition of an indoor area on the map
 * @param self
 */
export function cancelEditIndoorAreaAction(self) {
    self.editableLayers.clearLayers();
    // revert layers
    self.editHandler.revertLayers();
    // disable layer editing
    self.editHandler.disable();
    // reinit of current indoor area
    self.setCurrentIndoorArea({
        id: "",
        state: ""
    });
    // CTC-538 Display initial indoor areas of the selected floor
    generateIndoorAreasLayer(self);
    // Show again floor selector on the map
    self.showFloorMapSelector();
    // Display back the edited indoor area
    showOrHideIndoorAreasLayer(self);
}

/**
 * Method called to highlight indoor area on the map
 * @param self
 */
export function highlightIndoorAreaAction(self) {
    console.log("Component(LocationMap)::highlightIndoorAreaAction()) - Highlight indoor area !");

    // Defense: If user is currently creating an indoor area and click on another one into the list,
    // the highlight feature will be called. In that case, we need to disable the draw handler.
    if (self.drawHandler) {
        self.drawHandler.disable();
        self.drawHandler = null;
    }

    // Defense: If user is currently measuring a distance and click to highlight an area
    if (self.measureHandler && self.measureHandler.handler) {
        self.measureHandler.handler.disable();
    }

    // Defense: If user is currently updating a polygon of an existing indoor area and click on another one into the list,
    // the highlight feature will be called. In that case, we need to disable the edit draw handler.
    if (self.editHandler) {
        // revert layers
        self.editHandler.revertLayers();
        // disable layer editing
        self.editHandler.disable();
        // Show again the measure control
        self.showMeasureControl();
    }

    let indoorAreaObject = self.getIndoorAreaById(self.currentIndoorArea.id);
    // Reset all markers icons to default one
    resetAllLayersToDefaultColor(self);
    // Check if the indoor area is located on the current selected floor
    if (indoorAreaObject.building.id === self.getSelectedBuildingId && indoorAreaObject.floor.id === self.getSelectedFloorId) {
        // Map currently display the good floor, we just need to highlight the indoor area
        // Get layer of selected indoor area by id
        let indoorAreaLayer = self.indoorAreasLayer.getLayerById(self.currentIndoorArea.id);
        if (indoorAreaLayer) {
            indoorAreaLayer.openPopup();
            // Highlight selected area
            highlightArea(indoorAreaLayer);
            // Center map on highlighted area
            self.centerMapOnPolygon(indoorAreaLayer);
        }
    } else {
        // We need to switch to the good floor and then highlight the indoor area
        self.handleSelectFloorMapById(indoorAreaObject.floor.id);
        // No need to implement callback here,just wait few ms before leaflet update the map.
        setTimeout(() => {
            let indoorAreaLayer = self.indoorAreasLayer.getLayerById(self.currentIndoorArea.id);
            if (indoorAreaLayer) {
                indoorAreaLayer.openPopup();
                // Highlight selected area
                highlightArea(indoorAreaLayer);
                // Center map on highlighted area
                self.centerMapOnPolygon(indoorAreaLayer);
            }
        }, 800);
    }
}