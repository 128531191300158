import i18n from "../../../i18n";

const DEFAULT_AUTO_CALIBRATION_TAG_ICON = {
    options: {
        shadowUrl: null,
        className: "leaflet-autocalibrationtag-location-mouse-marker",
        iconAnchor: new L.Point(35, 35),
        iconSize: new L.Point(35, 35),
        iconUrl: "/assets/markers/tag.png"
    }
};

const HIGHLIGHT_AUTO_CALIBRATION_TAG_ICON = {
    options: {
        shadowUrl: null,
        className: "leaflet-autocalibrationtag-location-mouse-marker",
        iconAnchor: new L.Point(35, 35),
        iconSize: new L.Point(35, 35),
        iconUrl: "/assets/markers/tag_highlight.png"
    }
};

/**
 * Init the location map for autotcalibration tag mode
 * @param self
 */
export function initAutoCalibrationTagLocation(self) {
    self.initActionsBar(["centerMapButton"]);
    self.setMapTilesLayer("OpenStreetMap");
    self.initDrawControl();
    // Force set act tags displayed because if we are in the autocalibration tags page
    self.setAutocalibrationTagsLayerDisplayed(true);

    // Hide icons/buttons automatically added by Leaflet Draw library
    $(".leaflet-draw").hide();

    // Manage Escape button for cancelling editing and set location
    let onKeyDown = e => {
        if (e.keyCode == 27) {
            // esc button
            if (self.currentAutoCalibrationTagLocation.state === "CREATE") {
                self.setCurrentAutoCalibrationTagLocation({
                    id: "",
                    state: "CANCEL"
                });
            } else if (self.currentAutoCalibrationTagLocation.state === "EDIT") {
                self.setCurrentAutoCalibrationTagLocation({
                    id: self.currentAutoCalibrationTagLocation.id,
                    state: "CANCEL_EDIT"
                });
            } else if (self.currentAutoCalibrationTagLocation.state === "SET_FLOOR_ELEVATION") {
                self.setCurrentAutoCalibrationTagLocation({
                    id: "",
                    bleMacAddress: "",
                    state: "CANCEL"
                });
            } else if (self.currentAutoCalibrationTagLocation.state === "START_MEASURE") {
                self.setCurrentAutoCalibrationTagLocation({
                    id: "",
                    bleMacAddress: "",
                    state: "CANCEL_MEASURE"
                });
            }
        }
    };
    L.DomEvent.addListener(document, "keydown", onKeyDown, self.leafletMap);
}

/**
 * Method call to do specific action following the current state of currentAutoCalibrationTagLocation
 * @param self
 * @param action
 */
export function updateCurrentAutoCalibrationTagLocation(self, action) {
    if (action.id === "" && action.state === "CANCEL") {
        self.showMeasureControl();
        cancelSetAutoCalibrationTagMarkerAction(self);
    } else if (!action.state) {
        self.showMeasureControl();
        resetAllLayersToDefaultMarker(self);
        if (self.leafletMap){
            self.leafletMap.closePopup();
        }
    } else {
        if (action.state === "CREATE") {
            startSetAutoCalibrationTagMarkerAction(self);
            self.hideMeasureControl();
        } else if (action.state === "EDIT") {
            startEditAutoCalibrationTagMarkerLocationAction(self);
            self.hideMeasureControl();
        } else if (action.state === "DELETE") {
            deleteAutoCalibrationTagMarkerLocationAction(self);
        } else if (action.state === "HIGHLIGHT") {
            self.showMeasureControl();
            highlightAutoCalibrationTagMarkerAction(self);
        } else if (action.state === "SAVE_EDIT") {
            saveEditAutoCalibrationTagMarkerLocationAction(self);
        } else if (action.state === "CANCEL_EDIT") {
            cancelEditAutoCalibrationTagMarkerAction(self);
        } else if (action.state === "START_MEASURE") {
            startMeasureAction(self);
        } else if (action.state === "CANCEL_MEASURE") {
            cancelMeasureAction(self);
        }
    }
}

/**
 * Method called when user start measure action
 * @param self
 */
export function startMeasureAction(self) {
    self.hideMeasureControl();
}

/**
 * Method called when user cancel measure action
 * @param self
 */
export function cancelMeasureAction(self) {
    if (self.measureHandler && self.measureHandler.handler) {
        self.measureHandler.handler.disable();
    }
    self.showMeasureControl();
    self.setCurrentAutoCalibrationTagLocation({
        id: "",
        bleMacAddress: "",
        state: ""
    });
}

/**
 * Method called to start location of an autocalibration tag on the map
 * @param self
 */
export function startSetAutoCalibrationTagMarkerAction(self) {
    console.log("Component(LocationMap)::startSetAutoCalibrationTagMarkerAction()) - Start setting location !");
    self.drawHandler = new L.Draw.Marker(self.leafletMap, self.drawControl.options.draw.marker);
    self.drawHandler.enable();
}

/**
 * Method called to start edit of an autocalibration tag on the map
 * @param self
 */
export function startEditAutoCalibrationTagMarkerLocationAction(self) {
    console.log("Component(LocationMap)::startEditAutoCalibrationTagMarkerLocationAction()) - Start editing location !");
    // Get full autocalibration tag object
    let autocalibrationTagObject = self.getAutocalibrationTagById(self.currentAutoCalibrationTagLocation.id);
    // Hide floor selector on the map
    self.hideFloorMapSelector();
    // Close all opened popups on the map
    self.hideAllPopupOnMap();
    // Check if the autocalibration tag is located on the current selected floor
    if (autocalibrationTagObject.building.id === self.getSelectedBuildingId && autocalibrationTagObject.floor.id === self.getSelectedFloorId) {
        // Get layer of selected actag by id
        let currentEditedAutoCalibrationTagLayer = self.autocalibrationtagsLayer.getLayerById(self.currentAutoCalibrationTagLocation.id);
        // Center map on highlighted actag
        self.centerMapOnMarker(currentEditedAutoCalibrationTagLayer);
        // First remove all layers in editable Layers because we are able to edit one layer once a time
        self.editableLayers.clearLayers();
        // Add only selected AutoCalibrationTag layer to the list of edited layers
        self.editableLayers.addLayer(currentEditedAutoCalibrationTagLayer);
        // Force activation of edit mode now ! Only layers available in the editableLayers array will now goes in edit mode in location map.
        self.editHandler.enable();
    } else {
        // We need to switch to the good floor and then highlight the actag
        self.handleSelectFloorMapById(autocalibrationTagObject.floor.id);
        // No need to implement callback here,just wait few ms before leaflet update the map.
        setTimeout(() => {
            startEditAutoCalibrationTagMarkerLocationAction(self);
        }, 800);
    }
}

/**
 * Method called to save edit of an autocalibration tag on the map
 * @param self
 */
export function saveEditAutoCalibrationTagMarkerLocationAction(self) {
    console.log("Component(LocationMap)::saveEditAutoCalibrationTagMarkerLocationAction()) - Start saving new autocalibration tag location !");
    // Get layer of selected act tag by id
    let currentEditedAutocalibrationTagLayer = self.autocalibrationtagsLayer.getLayerById(self.currentAutoCalibrationTagLocation.id);
    let actagMarkerLocation = currentEditedAutocalibrationTagLayer.toGeoJSON().geometry;
    let actLocationData = {
        id: self.currentAutoCalibrationTagLocation.id,
        buildingId: self.getSelectedBuildingId,
        floorId: self.getSelectedFloorId,
        siteId: self.siteId,
        location: actagMarkerLocation
    };
    self.setAutoCalibrationTagLocation(actLocationData);
    self.editHandler.disable();
    // Show again floor selector on the map
    self.showFloorMapSelector();
    // Store the last edited location in store. Usefull if distance has to be displayed on the map.
    self.storeLastAddedObjectLocation({
        type: "ACTAG",
        id: self.currentAutoCalibrationTagLocation.id,
        lastAddedObjectLocation: actagMarkerLocation
    });
}

/**
 * Method called to delete one autocalibraion tag location on the map
 * @param self
 */
export function deleteAutoCalibrationTagMarkerLocationAction(self) {
    console.log("Component(LocationMap)::deleteAutoCalibrationTagMarkerLocationAction()) - Delete marker location !");
    let actLocationData = {
        id: self.currentAutoCalibrationTagLocation.id,
        buildingId: null,
        floorId: null,
        floorElevation: 2.5, // Default value in backend AutocalibrationTag model
        siteId: self.siteId,
        location: null
    };
    self.setAutoCalibrationTagLocation(actLocationData);
}

/**
 * Method called to highlight autocalibraion tag on the map
 * @param self
 */
export function highlightAutoCalibrationTagMarkerAction(self) {
    console.log("Component(LocationMap)::highlightAutoCalibrationTagMarkerAction()) - Highlight marker location !");

    // Defense: If user is currently measuring a distance and click to highlight an area
    if (self.measureHandler && self.measureHandler.handler) {
        self.measureHandler.handler.disable();
    }

    // Get full autocalibration tag object
    let autocalibrationTagObject = self.getAutocalibrationTagById(self.currentAutoCalibrationTagLocation.id);
    // Reset all markers icons to default one
    resetAllLayersToDefaultMarker(self);
    // Check if the autocalibration tag is located on the current selected floor
    if (autocalibrationTagObject.building.id === self.getSelectedBuildingId && autocalibrationTagObject.floor.id === self.getSelectedFloorId) {
        // Map currently display the good floor, we just need to highlight the good autocalibration Tag
        // Get layer of selected actag by id
        let autocalibrationTagLayer = self.autocalibrationtagsLayer.getLayerById(self.currentAutoCalibrationTagLocation.id);
        autocalibrationTagLayer.openPopup();
        // Highlight selected marker
        highlightMarker(autocalibrationTagLayer);
        // Center map on highlighted act
        self.centerMapOnMarker(autocalibrationTagLayer);
    } else {
        // We need to switch to the good floor and then highlight the actag
        self.handleSelectFloorMapById(autocalibrationTagObject.floor.id);
        // No need to implement callback here,just wait few ms before leaflet update the map.
        setTimeout(() => {
            let autocalibrationTagLayer = self.autocalibrationtagsLayer.getLayerById(self.currentAutoCalibrationTagLocation.id);
            autocalibrationTagLayer.openPopup();
            // Highlight selected marker
            highlightMarker(autocalibrationTagLayer);
            // Center map on highlighted act
            self.centerMapOnMarker(autocalibrationTagLayer);
        }, 800);
    }
}

/**
 * Method called to cancel the editing of location of an autocalibration tag on the map
 * @param self
 */
export function cancelEditAutoCalibrationTagMarkerAction(self) {
    console.log("Component(LocationMap)::cancelEditAutoCalibrationTagMarkerAction()) - Cancel editing of autocalibration tag location !");
    // Get layer of selected act tag by id
    self.editHandler.disable();
    let actBeforeEditing = self.getAutocalibrationTagById(self.currentAutoCalibrationTagLocation.id);

    let actLocationData = {
        id: self.currentAutoCalibrationTagLocation.id,
        buildingId: self.getSelectedBuildingId,
        floorId: self.getSelectedFloorId,
        siteId: self.siteId,
        location: actBeforeEditing.location
    };
    self.setAutoCalibrationTagLocation(actLocationData);
    self.editHandler.disable();
    // Show again floor selector on the map
    self.showFloorMapSelector();
}

/**
 * Method called to cancel location of an autocalibration tag on the map
 * @param self
 */
export function cancelSetAutoCalibrationTagMarkerAction(self) {
    console.log("Component(LocationMap)::cancelSetAutoCalibrationTagMarkerAction()) - Cancel autocalibration tag set location !");
    self.drawHandler.disable();
}

/**
 *  Method called to draw an autocalibration icon on the map
 * @param self
 */
export function initDrawControlForAutoCalibrationTag(self) {
    var autoCalibrationTagLocationMarker = L.Icon.extend({
        options: getAutocalibrationTagIconFormat()
    });

    // By default disable all drawing and editing options
    let options = {
        position: "topleft",
        draw: {
            polygon: false,
            polyline: false,
            circle: false,
            rectangle: false,
            marker: {
                icon: new autoCalibrationTagLocationMarker()
            },
            circlemarker: false
        },
        edit: {
            featureGroup: self.editableLayers,
            remove: false
        }
    };

    return options;
}

export function registerEvents(self) {
    let self2 = self;

    // This listener is only used when user has clicke don start measure button
    self.leafletMap.on(L.Draw.Event.DRAWSTART, e => {
        if (e && e.layerType && e.layerType === "polyline") {
            self.setCurrentAutoCalibrationTagLocation({
                id: "",
                state: "START_MEASURE"
            });
        }
    });

    self.leafletMap.on(L.Draw.Event.CREATED, e => {
        var autocalibrationTagMarkerLayer = e.layer;
        let autocalibrationTagMarkerLocation = autocalibrationTagMarkerLayer.toGeoJSON().geometry;
        let actLocationData = {
            id: self2.currentAutoCalibrationTagLocation.id,
            buildingId: self.getSelectedBuildingId,
            floorId: self2.getSelectedFloorId,
            siteId: self2.siteId,
            location: autocalibrationTagMarkerLocation,
            state: "SET_FLOOR_ELEVATION"
        };
        console.log("Component(LocationMap)::initDrawControl() - New autocalibration tag marker created. Marker Data = ", actLocationData);

        if (self2.isInFullscreen === true) {
            self2.quitFullscreenButton();
        }
        self2.setCurrentAutoCalibrationTagLocation(actLocationData);
    });
}

export function generateAutoCalibrationTagsLayer(self) {
    // If autocalibrationtagsLayer is already defined, clear all layers already existing inside
    if (self.autocalibrationtagsLayer) {
        self.autocalibrationtagsLayer.clearLayers();
        self.autocalibrationtagsCircleRadiusLayer.clearLayers();
    } else {
        self.autocalibrationtagsLayer = new L.FeatureGroup();
        self.autocalibrationtagsCircleRadiusLayer = new L.FeatureGroup();
    }

    // Get current selected building and floor id
    let currentSelectedBuildingId = self.getSelectedBuildingId;
    let currentSelectedFloorId = self.getSelectedFloorId;

    // Then, for each autocalibration tag, create a marker layer and add it to the global autocalibration tag layer
    _.forEach(self.siteAutoCalibrationTags, function (actag) {
        // Create markers only for actag that are actually on the selected building and floor
        if (actag.building && actag.floor) {
            let buildingId = actag.building.id ? actag.building.id : actag.building;
            let floorId = actag.floor.id ? actag.floor.id : actag.floor;

            if (actag.location && actag.location.type && buildingId === currentSelectedBuildingId && floorId === currentSelectedFloorId) {
                let actMarker = L.marker([actag.location.coordinates[1], actag.location.coordinates[0]], getAutocalibrationTagIconFormat(actag, self));

                var popup = new L.Popup().setContent("<strong id='actag_tooltip_macAddress'>" + actag.macAddress + "</strong>" + "<br><span id='actag_tooltip_coordinates' style='color: #999'>" + actag.location.coordinates[1] + ", " + actag.location.coordinates[0] + "</span>" + '<br><span style="color: #999">' + self.$t("common_floorElevationTooltip") + ": <span id='actag_tooltip_floorElevation'>" + actag.floorElevation + " m</span></span>");

                if (self && self.$route && self.$route.name == "siteBeaconsHealth") {
                
                    const beaconHealth = self.$store.getters.getBeaconHealthById(actag.id);
                    if (beaconHealth){
                        if (beaconHealth.coverage.length == 0) {
                            // beacon coverage is Out of coverage
                            actMarker.bindPopup(popup, {
                                offset: new L.Point(-17, -28)
                            }).bindTooltip(
                                actag.serialNumber + "<br />("+actag.macAddress+")<br /><span style='color: #fd397a;font-weight: 500;'>"+i18n.t("troubleshooting_tagStatusNone")+"</span>",
                                {
                                    className: "customTooltipActClassCenter",
                                    permanent: true,
                                    direction: "left",
                                    offset: new L.Point(-35, -18)
                                }
                            );
                        } else if (beaconHealth.coverage.length == 1 || beaconHealth.coverage.length == 2) {
                            // beacon coverage is Bad
                            actMarker.bindPopup(popup, {
                                offset: new L.Point(-17, -28)
                            }).bindTooltip(
                                actag.serialNumber + "<br />("+actag.macAddress+")<br /><span style='color: #ffb822;font-weight: 500;'>"+i18n.t("troubleshooting_tagStatusBad")+"</span>",
                                {
                                    className: "customTooltipActClassCenter",
                                    permanent: true,
                                    direction: "left",
                                    offset: new L.Point(-35, -18)
                                }
                            );
                        } else if (beaconHealth.coverage.length == 3) {
                            // beacon coverage is Acceptable
                            actMarker.bindPopup(popup, {
                                offset: new L.Point(-17, -28)
                            }).bindTooltip(
                                actag.serialNumber + "<br />("+actag.macAddress+")<br /><span style='color: #0abb87;font-weight: 500;'>"+i18n.t("troubleshooting_tagStatusGood")+"</span>",
                                {
                                    className: "customTooltipActClassCenter",
                                    permanent: true,
                                    direction: "left",
                                    offset: new L.Point(-35, -18)
                                }
                            );
                        } else {
                            // beacon coverage is Excellent
                            actMarker.bindPopup(popup, {
                                offset: new L.Point(-17, -28)
                            }).bindTooltip(
                                actag.serialNumber + "<br />("+actag.macAddress+")<br /><span style='color: #0abb87;font-weight: 500;'>"+i18n.t("troubleshooting_tagStatusExcellent")+"</span>",
                                {
                                    className: "customTooltipActClassCenter",
                                    permanent: true,
                                    direction: "left",
                                    offset: new L.Point(-35, -18)
                                }
                            );
                        }
                    } else {

                        actMarker.bindPopup(popup, {
                            offset: new L.Point(-17, -28)
                        }).bindTooltip(actag.serialNumber ? actag.serialNumber : actag.macAddress, {
                            className: "customTooltipActClass",
                            permanent: true,
                            direction: "left",
                            offset: new L.Point(-35, -18)
                        });

                        registerClickEventsOnActagMarker(self, actMarker);
                    }

                } else {

                    actMarker.bindPopup(popup, {
                        offset: new L.Point(-17, -28)
                    }).bindTooltip(actag.serialNumber ? actag.serialNumber : actag.macAddress, {
                        className: "customTooltipActClass",
                        permanent: true,
                        direction: "left",
                        offset: new L.Point(-35, -18)
                    });
    
                    // Define click listener on marker and change icon color when user click on a autocalibration marker
                    registerClickEventsOnActagMarker(self, actMarker);

                }

                // This line is important: we are setting the id of the marker layer using autocalibration tag id.
                // It will be usefull later to find a layer by id.
                actMarker.options.id = actag.id;
                actMarker.options.macAddress = actag.macAddress;
                self.autocalibrationtagsLayer.addLayer(actMarker);

                let radiusCircleLayer = L.circle([actag.location.coordinates[1], actag.location.coordinates[0]], 3);
                self.autocalibrationtagsCircleRadiusLayer.addLayer(radiusCircleLayer);
            }
        }
    });
}

/*
 * Function used to reset all markers icon to default icon
 */
function resetAllLayersToDefaultMarker(self) {
    if (self.autocalibrationtagsLayer) {
        // Reset all autocalibration tags marker to default icon
        for (var i in self.autocalibrationtagsLayer._layers) {
            if (self.autocalibrationtagsLayer._layers.hasOwnProperty(i)) {
                var currentActagMarker = self.autocalibrationtagsLayer._layers[i];
                var BASE_STYLE = DEFAULT_AUTO_CALIBRATION_TAG_ICON;
                BASE_STYLE.options.html = '<img id="autocalibrationTagMarker_' + currentActagMarker.options.macAddress + '" class="autocalibrationMarkerFormat" src="/assets/markers/tag.png" />';
                var defaultActagIcon = L.DivIcon.extend(BASE_STYLE);
                currentActagMarker.setIcon(new defaultActagIcon());
            }
        }
    }
}

/*
 * Function used to highlight one autocalibration tag marker on the map
 */
function highlightMarker(markerLayer) {
    // Change color of clicked marker
    var HIGHLIGHT_BASE_STYLE = HIGHLIGHT_AUTO_CALIBRATION_TAG_ICON;
    HIGHLIGHT_BASE_STYLE.options.html = '<img id="autocalibrationTagMarker_' + markerLayer.options.macAddress + '" class="autocalibrationMarkerFormat" src="/assets/markers/tag_highlight.png" />';
    var highlightActagIcon = L.DivIcon.extend(HIGHLIGHT_BASE_STYLE);
    markerLayer.setIcon(new highlightActagIcon());
}

/*
 * Function used to register click on autocalibration tag markers
 */
function registerClickEventsOnActagMarker(self, actagMarker) {
    // Register click event on marker icon
    actagMarker.on("click", function (e) {
        // Reset all markers
        resetAllLayersToDefaultMarker(self);
        // Set selected autocalibration tag
        var autocalibrationTagId = e.target.options.id;
        self.setCurrentAutoCalibrationTagLocation({
            id: autocalibrationTagId,
            state: "HIGHLIGHT",
            isHighlightFromMap: true
        });
        // Highlight the selected one
        var markerLayer = e.target;
        highlightMarker(markerLayer);
    });

    // Register click event on close popup
    actagMarker.getPopup().on("remove", () => {
        if (self.leafletMap.hasLayer(actagMarker) && self.currentAutoCalibrationTagLocation.state === "HIGHLIGHT") {
            // Reset selected autocalibration tag to empty
            self.setCurrentAutoCalibrationTagLocation({
                id: "",
                bleMacAddress: "",
                state: ""
            });
        }
        // Reset default icon only if the marker is always on the map
        // It could be deleted if the remove event has been fired after delete location
        if (self.leafletMap.hasLayer(actagMarker)) {
            // Reset default marker icon
            var defaultActagIcon = L.Icon.extend(DEFAULT_AUTO_CALIBRATION_TAG_ICON);
            actagMarker.setIcon(new defaultActagIcon());
        }
    });

    // Register click event on popup open to force again selection of autocalibration tag
    actagMarker.on("popupopen", e => {
        // Set selected autocalibration tag
        var actagId = e.target.options.id;
        self.setCurrentAutoCalibrationTagLocation({
            id: actagId,
            state: "HIGHLIGHT"
        });
    });
}

export function showOrHideAutoCalibrationTagsLayer(self) {
    console.log("Component(LocationMap)::showOrHideAutoCalibrationTagsLayer() - called");
    if (self.leafletMap && self.autocalibrationtagsLayer) {
        if (self.isAutocalibrationTagsLayerDisplayed) {
            self.leafletMap.addLayer(self.autocalibrationtagsLayer);
        } else {
            self.leafletMap.removeLayer(self.autocalibrationtagsLayer);
        }
    }
}

export function toggleCircleRadiusAutoCalibrationTagsLayer(self) {
    if (self.leafletMap && self.autocalibrationtagsCircleRadiusLayer) {
        if (self.leafletMap.hasLayer(self.autocalibrationtagsCircleRadiusLayer)) {
            self.leafletMap.removeLayer(self.autocalibrationtagsCircleRadiusLayer);
        } else {
            self.leafletMap.addLayer(self.autocalibrationtagsCircleRadiusLayer);
        }
    }
}

function getAutocalibrationTagIconFormat(autocalibrationTag, self) {

    if (autocalibrationTag) {

        let iconObj = {};
        if (self && self.$route && self.$route.name == "siteBeaconsHealth") {

            const beaconHealth = self.$store.getters.getBeaconHealthById(autocalibrationTag.id);
            if (beaconHealth){
                
                if (beaconHealth.coverage.length == 0) {
                    iconObj = {
                        shadowUrl: null,
                        className: "leaflet-autocalibrationtag-location-mouse-marker",
                        iconAnchor: new L.Point(35, 35),
                        iconSize: new L.Point(35, 35),
                        html: '<img id="autocalibrationTagMarker_' + autocalibrationTag.macAddress + '" class="autocalibrationMarkerFormat" src="/assets/markers/tag_out_of_coverage.png" />',
                        iconUrl: "/assets/markers/tag_out_of_coverage.png"
                    };
                } else if (beaconHealth.coverage.length == 1 || beaconHealth.coverage.length == 2) {
                    iconObj = {
                        shadowUrl: null,
                        className: "leaflet-autocalibrationtag-location-mouse-marker",
                        iconAnchor: new L.Point(35, 35),
                        iconSize: new L.Point(35, 35),
                        html: '<img id="autocalibrationTagMarker_' + autocalibrationTag.macAddress + '" class="autocalibrationMarkerFormat" src="/assets/markers/tag_bad.png" />',
                        iconUrl: "/assets/markers/tag_bad.png"
                    };
                } else {
                    iconObj = {
                        shadowUrl: null,
                        className: "leaflet-autocalibrationtag-location-mouse-marker",
                        iconAnchor: new L.Point(35, 35),
                        iconSize: new L.Point(35, 35),
                        html: '<img id="autocalibrationTagMarker_' + autocalibrationTag.macAddress + '" class="autocalibrationMarkerFormat" src="/assets/markers/tag_acceptable_and_excellent.png" />',
                        iconUrl: "/assets/markers/tag_acceptable_and_excellent.png"
                    };
                }

            } else {
                iconObj = {
                    shadowUrl: null,
                    className: "leaflet-autocalibrationtag-location-mouse-marker",
                    iconAnchor: new L.Point(35, 35),
                    iconSize: new L.Point(35, 35),
                    html: '<img id="autocalibrationTagMarker_' + autocalibrationTag.macAddress + '" class="autocalibrationMarkerFormat" src="/assets/markers/tag_out_of_coverage.png" />',
                    iconUrl: "/assets/markers/tag_out_of_coverage.png"
                };
            }

        } else {

            // To display autocalibration tag on the map
            iconObj = {
                shadowUrl: null,
                className: "leaflet-autocalibrationtag-location-mouse-marker",
                iconAnchor: new L.Point(35, 35),
                iconSize: new L.Point(35, 35),
                html: '<img id="autocalibrationTagMarker_' + autocalibrationTag.macAddress + '" class="autocalibrationMarkerFormat" src="/assets/markers/tag.png" />',
                iconUrl: "/assets/markers/tag.png"
            };

        }

        return {
            icon: L.divIcon(iconObj)
        };

    } else {
        // To define location of the autocalibration tag on the map in Autocalibration location page
        return DEFAULT_AUTO_CALIBRATION_TAG_ICON.options;
    }
}