<template>
    <div class="menuContainer">
        <div class="sideBarTriangle"></div>
        <div class="row addNewFilterDiv">
            <div class="col-sm-12">
                <div class="addNewFilterLabel">{{ $t("map_configuration") }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5">
                <div class="whiteLabel">{{ $t("map_style") }}</div>
            </div>
            <div class="col-sm-7">
                <div class="btn-group pull-right">
                    <button class="btn btn-secondary btn-sm dropdown-toggle selectMapTypeDropdownBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{ mapTileLayerName }}
                    </button>
                    <div class="dropdown-menu sideBarCustomDropDown" x-placement="bottom-start">
                        <a @click="onSelectMapTileLayerChanged" class="dropdown-item" href="#">OpenStreetMap</a>
                        <a @click="onSelectMapTileLayerChanged" class="dropdown-item" href="#">Mapbox</a>
                        <a @click="onSelectMapTileLayerChanged" class="dropdown-item" href="#">Dark</a>
                        <a @click="onSelectMapTileLayerChanged" class="dropdown-item" href="#">Grayscale</a>
                        <a @click="onSelectMapTileLayerChanged" class="dropdown-item" href="#">WorldStreetMap</a>
                        <a @click="onSelectMapTileLayerChanged" class="dropdown-item" href="#">WorldTopoMap</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="separator"></div>
        <div class="row">
            <div class="col-sm-5">
                <div class="whiteLabelRangeSlider">{{ $t("map_brightness") }}</div>
            </div>
            <div class="col-sm-7">
                <div class="btn-group pull-right">
                    <input type="range" v-model="localCurrentMapTilesLayerOpacity" @input="onMapBrightnessValueChanged" class="custom-range mapBrightnessSelector" min="0" max="100" />
                </div>
            </div>
        </div>
        <div class="separator" v-if="this.mode !=='assetlocationhistory'"></div>
        <div class="row" v-if="this.mode !=='assetlocationhistory'">
            <div class="col-sm-5">
                <div class="whiteLabel">{{ $t("map_autorefresh") }}</div>
            </div>
            <div class="col-sm-7">
                <span id="config_mapRefreshSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="config_mapRefreshSwitchInput" @change="onSwitchMapRefreshing" v-model="mapRefreshingEnabled" type="checkbox" checked="checked" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
        </div>
        <div class="row" style="margin-top: 5px;" v-if="this.mode !=='assetlocationhistory'">
            <div class="col-sm-5">
                <div class="whiteLabelRangeSlider">{{ $t("map_refreshInterval", { interval: mapRefreshingInterval }) }}</div>
            </div>
            <div class="col-sm-7">
                <div class="btn-group pull-right">
                    <input type="range" :disabled="!mapRefreshingEnabled" v-model="mapRefreshingInterval" @input="onMapRefreshIntervalChanged" class="custom-range mapBrightnessSelector" min="5" max="100" />
                </div>
            </div>
        </div>
        <div class="separator"></div>
        <div class="row">
            <div class="col-sm-5">
                <div class="whiteLabel">{{ $t("map_assetlabels") }}</div>
            </div>
            <div class="col-sm-7">
                <span id="config_assetLabelsSwitchSpan" class="kt-switch kt-switch--outline kt-switch--sm kt-switch--icon kt-switch--brand sidebarSwitchBtn">
                    <label>
                        <input id="config_assetLabelsSwitchInput" @change="onSwitchAssetsLabel" v-model="assetsLabelsDisplayed" type="checkbox" checked="checked" name="" />
                        <span></span>
                    </label>
                </span>
            </div>
        </div>
        <div class="row marginT8">
            <div class="col-sm-5">
                <div class="kt-pull-left assetLabelsPreview">
                    <div class="assetLabelsPreview_listlabels" v-bind:class="assetsListDynamicClass">
                        <span class="assetLabelsPreview_labels" v-if="assetsLabelsDisplayed" id="assetLabelsPreview_labels_row1">{{ sampleRow1 }}</span>
                        <span class="assetLabelsPreview_labels" v-if="assetsLabelsDisplayed && labelRow2 != 'MAP_NONE'" id="assetLabelsPreview_labels_row2">{{ sampleRow2 }}</span>
                        <span class="assetLabelsPreview_labels" v-if="assetsLabelsDisplayed && labelRow3 != 'MAP_NONE'" id="assetLabelsPreview_labels_row3">{{ sampleRow3 }}</span>
                        <span class="assetLabelsPreview_labels" v-if="assetsLabelsDisplayed && labelRow4 != 'MAP_NONE'" id="assetLabelsPreview_labels_row4">{{ sampleRow4 }}</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-7">
                <div>
                    <div class="btn-group pull-right">
                        <button class="btn btn-secondary btn-sm dropdown-toggle selectMapTypeDropdownBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ $t(labelRow1) }}
                        </button>
                        <div class="dropdown-menu sideBarCustomDropDown" x-placement="bottom-start">
                            <a @click="onSelectAssetLabelFirstRow" class="dropdown-item" data-labelvalue="MAP_ASSET_NAME" href="#">{{ $t("MAP_ASSET_NAME") }}</a>
                            <a @click="onSelectAssetLabelFirstRow" class="dropdown-item" data-labelvalue="MAP_CATEGORY" href="#">{{ $t("MAP_CATEGORY") }}</a>
                            <a @click="onSelectAssetLabelFirstRow" class="dropdown-item" data-labelvalue="MAP_SUB_CATEGORY" href="#">{{ $t("MAP_SUB_CATEGORY") }}</a>
                            <a @click="onSelectAssetLabelFirstRow" class="dropdown-item" data-labelvalue="MAP_UNIT" href="#">{{ $t("MAP_UNIT") }}</a>
                            <a @click="onSelectAssetLabelFirstRow" class="dropdown-item" data-labelvalue="MAP_TAG_TYPE" href="#">{{ $t("MAP_TAG_TYPE") }}</a>
                            <a @click="onSelectAssetLabelFirstRow" class="dropdown-item" data-labelvalue="MAP_TAG_SERIAL_NUMBER" href="#">{{ $t("MAP_TAG_SERIAL_NUMBER") }}</a>
                            <a @click="onSelectAssetLabelFirstRow" class="dropdown-item" data-labelvalue="MAP_TAG_BATTERY_STATUS" href="#">{{ $t("MAP_TAG_BATTERY_STATUS") }}</a>
                        </div>
                    </div>
                    <div class="btn-group pull-right marginT10">
                        <button class="btn btn-secondary btn-sm dropdown-toggle selectMapTypeDropdownBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ $t(labelRow2) }}
                        </button>
                        <div class="dropdown-menu sideBarCustomDropDown" x-placement="bottom-start">
                            <a @click="onSelectAssetLabelSecondRow" class="dropdown-item" data-labelvalue="MAP_NONE" href="#">{{ $t("MAP_NONE") }}</a>
                            <a @click="onSelectAssetLabelSecondRow" class="dropdown-item" data-labelvalue="MAP_ASSET_NAME" href="#">{{ $t("MAP_ASSET_NAME") }}</a>
                            <a @click="onSelectAssetLabelSecondRow" class="dropdown-item" data-labelvalue="MAP_CATEGORY" href="#">{{ $t("MAP_CATEGORY") }}</a>
                            <a @click="onSelectAssetLabelSecondRow" class="dropdown-item" data-labelvalue="MAP_SUB_CATEGORY" href="#">{{ $t("MAP_SUB_CATEGORY") }}</a>
                            <a @click="onSelectAssetLabelSecondRow" class="dropdown-item" data-labelvalue="MAP_UNIT" href="#">{{ $t("MAP_UNIT") }}</a>
                            <a @click="onSelectAssetLabelSecondRow" class="dropdown-item" data-labelvalue="MAP_TAG_TYPE" href="#">{{ $t("MAP_TAG_TYPE") }}</a>
                            <a @click="onSelectAssetLabelSecondRow" class="dropdown-item" data-labelvalue="MAP_TAG_SERIAL_NUMBER" href="#">{{ $t("MAP_TAG_SERIAL_NUMBER") }}</a>
                            <a @click="onSelectAssetLabelSecondRow" class="dropdown-item" data-labelvalue="MAP_TAG_BATTERY_STATUS" href="#">{{ $t("MAP_TAG_BATTERY_STATUS") }}</a>
                        </div>
                    </div>
                    <div class="btn-group pull-right marginT10">
                        <button class="btn btn-secondary btn-sm dropdown-toggle selectMapTypeDropdownBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ $t(labelRow3) }}
                        </button>
                        <div class="dropdown-menu sideBarCustomDropDown" x-placement="bottom-start">
                            <a @click="onSelectAssetLabelThirdRow" class="dropdown-item" data-labelvalue="MAP_NONE" href="#">{{ $t("MAP_NONE") }}</a>
                            <a @click="onSelectAssetLabelThirdRow" class="dropdown-item" data-labelvalue="MAP_ASSET_NAME" href="#">{{ $t("MAP_ASSET_NAME") }}</a>
                            <a @click="onSelectAssetLabelThirdRow" class="dropdown-item" data-labelvalue="MAP_CATEGORY" href="#">{{ $t("MAP_CATEGORY") }}</a>
                            <a @click="onSelectAssetLabelThirdRow" class="dropdown-item" data-labelvalue="MAP_SUB_CATEGORY" href="#">{{ $t("MAP_SUB_CATEGORY") }}</a>
                            <a @click="onSelectAssetLabelThirdRow" class="dropdown-item" data-labelvalue="MAP_UNIT" href="#">{{ $t("MAP_UNIT") }}</a>
                            <a @click="onSelectAssetLabelThirdRow" class="dropdown-item" data-labelvalue="MAP_TAG_TYPE" href="#">{{ $t("MAP_TAG_TYPE") }}</a>
                            <a @click="onSelectAssetLabelThirdRow" class="dropdown-item" data-labelvalue="MAP_TAG_SERIAL_NUMBER" href="#">{{ $t("MAP_TAG_SERIAL_NUMBER") }}</a>
                            <a @click="onSelectAssetLabelThirdRow" class="dropdown-item" data-labelvalue="MAP_TAG_BATTERY_STATUS" href="#">{{ $t("MAP_TAG_BATTERY_STATUS") }}</a>
                        </div>
                    </div>
                    <div class="btn-group pull-right marginT10">
                        <button class="btn btn-secondary btn-sm dropdown-toggle selectMapTypeDropdownBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ $t(labelRow4) }}
                        </button>
                        <div class="dropdown-menu sideBarCustomDropDown" x-placement="bottom-start">
                            <a @click="onSelectAssetLabelFourthRow" class="dropdown-item" data-labelvalue="MAP_NONE" href="#">{{ $t("MAP_NONE") }}</a>
                            <a @click="onSelectAssetLabelFourthRow" class="dropdown-item" data-labelvalue="MAP_ASSET_NAME" href="#">{{ $t("MAP_ASSET_NAME") }}</a>
                            <a @click="onSelectAssetLabelFourthRow" class="dropdown-item" data-labelvalue="MAP_CATEGORY" href="#">{{ $t("MAP_CATEGORY") }}</a>
                            <a @click="onSelectAssetLabelFourthRow" class="dropdown-item" data-labelvalue="MAP_SUB_CATEGORY" href="#">{{ $t("MAP_SUB_CATEGORY") }}</a>
                            <a @click="onSelectAssetLabelFourthRow" class="dropdown-item" data-labelvalue="MAP_UNIT" href="#">{{ $t("MAP_UNIT") }}</a>
                            <a @click="onSelectAssetLabelFourthRow" class="dropdown-item" data-labelvalue="MAP_TAG_TYPE" href="#">{{ $t("MAP_TAG_TYPE") }}</a>
                            <a @click="onSelectAssetLabelFourthRow" class="dropdown-item" data-labelvalue="MAP_TAG_SERIAL_NUMBER" href="#">{{ $t("MAP_TAG_SERIAL_NUMBER") }}</a>
                            <a @click="onSelectAssetLabelFourthRow" class="dropdown-item" data-labelvalue="MAP_TAG_BATTERY_STATUS" href="#">{{ $t("MAP_TAG_BATTERY_STATUS") }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            localCurrentMapTilesLayerOpacity: 30,
            mapTileLayerName: "OpenStreetMap",
            assetsLabelsDisplayed: true,
            mapRefreshingEnabled: true,
            mapRefreshingInterval: 5,
            labelRow1: "MAP_ASSET_NAME",
            labelRow2: "MAP_NONE",
            labelRow3: "MAP_NONE",
            labelRow4: "MAP_NONE",
            sampleRow1: this.getSampleByLabel("MAP_ASSET_NAME"),
            sampleRow2: "",
            sampleRow3: "",
            sampleRow4: ""
        };
    },
    created: function() {
        console.log("Component(SidebarConfigurationContent)::created() - called");

        // When sidebar is opened, get default values from store.
        this.mapTileLayerName = this.$store.getters.currentMapTileLayerName;
        this.localCurrentMapTilesLayerOpacity = this.$store.getters.currentMapTilesLayerOpacity;
        this.assetsLabelsDisplayed = this.$store.getters.isAssetsLabelsDisplayed;
        this.mapRefreshingEnabled = this.$store.getters.isMapRefreshingEnabled;
        this.mapRefreshingInterval = this.$store.getters.mapRefreshingIntervalInSeconds;
        this.labelRow1 = this.$store.getters.assetsLabelsDisplayedValues.row1;
        this.sampleRow1 = this.getSampleByLabel(this.labelRow1);
        this.labelRow2 = this.$store.getters.assetsLabelsDisplayedValues.row2;
        this.sampleRow2 = this.getSampleByLabel(this.labelRow2);
        this.labelRow3 = this.$store.getters.assetsLabelsDisplayedValues.row3;
        this.sampleRow3 = this.getSampleByLabel(this.labelRow3);
        this.labelRow4 = this.$store.getters.assetsLabelsDisplayedValues.row4;
        this.sampleRow4 = this.getSampleByLabel(this.labelRow4);
    },
    mounted: function() {
        console.log("Component(SidebarConfigurationContent)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(SidebarConfigurationContent)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    props: {
        mode: String
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentMapTilesLayerOpacity: function() {
            this.localCurrentMapTilesLayerOpacity = this.currentMapTilesLayerOpacity;
        },

        isAssetsLabelsDisplayed: function(value) {
            this.assetsLabelsDisplayed = value;
        },

        mapRefreshingEnabled: function(value) {
            this.mapRefreshingEnabled = value;
        },

        mapRefreshingIntervalInSeconds: function(value) {
            this.mapRefreshingInterval = value;
        },

        currentMapTileLayerName: function(value) {
            this.mapTileLayerName = value;
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentMapTilesLayerOpacity", "currentMapTileLayerName", "isAssetsLabelsDisplayed", "isMapRefreshingEnabled", "mapRefreshingIntervalInSeconds"]),

        assetsListDynamicClass: function() {
            return {
                assetsLabelOneElement: this.labelRow2 == "MAP_NONE" && this.labelRow3 == "MAP_NONE" && this.labelRow4 == "MAP_NONE",
                assetsLabelTwoElements: (this.labelRow2 == "MAP_NONE" && (this.labelRow3 != "MAP_NONE" || this.labelRow4 != "MAP_NONE")) || (this.labelRow3 == "MAP_NONE" && (this.labelRow2 != "MAP_NONE" || this.labelRow4 != "MAP_NONE")) || (this.labelRow4 == "MAP_NONE" && (this.labelRow2 != "MAP_NONE" || this.labelRow3 != "MAP_NONE")),
                assetsLabelThreeElements: (this.labelRow2 != "MAP_NONE" && (this.labelRow3 != "MAP_NONE" || this.labelRow4 != "MAP_NONE")) || (this.labelRow3 != "MAP_NONE" && (this.labelRow2 != "MAP_NONE" || this.labelRow4 != "MAP_NONE")) || (this.labelRow4 != "MAP_NONE" && (this.labelRow2 != "MAP_NONE" || this.labelRow3 != "MAP_NONE")),
                assetsLabelFourthElements: this.labelRow2 != "MAP_NONE" && this.labelRow3 != "MAP_NONE" && this.labelRow4 != "MAP_NONE"
            };
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["setCurrentMapTilesLayerOpacity", "setCurrentMapTilesLayerName", "setMapRefreshingEnabled", "setMapRefreshingInterval", "setAssetsLabelsDisplayed", "setAssetsLabelsDisplayedValues"]),

        // Called when the map brightness is changed
        onMapBrightnessValueChanged(e) {
            var value = Number(e.target.value);
            this.localCurrentMapTilesLayerOpacity = value;
            this.setCurrentMapTilesLayerOpacity(value);
        },

        // Called when a new map tiles layer is selected
        onSelectMapTileLayerChanged(e) {
            var selectedTileName = e.target.text;
            this.mapTileLayerName = selectedTileName;
            this.setCurrentMapTilesLayerName(selectedTileName);
        },

        // Called when user enable or disable refreshing using Switch selector
        onSwitchMapRefreshing() {
            this.setMapRefreshingEnabled(this.mapRefreshingEnabled);
        },

        // Called when user select refreshing interval using selector
        onMapRefreshIntervalChanged() {
            this.setMapRefreshingInterval(this.mapRefreshingInterval);
        },

        // Called when user activate or desactive display of labels using Switch selector
        onSwitchAssetsLabel() {
            this.setAssetsLabelsDisplayed(this.assetsLabelsDisplayed);
        },

        // Called when user select the label to display for the first row
        onSelectAssetLabelFirstRow(e) {
            this.labelRow1 = $(e.target).data().labelvalue;
            this.sampleRow1 = this.getSampleByLabel(this.labelRow1);

            let jsonLabels = { row1: this.labelRow1, row2: this.labelRow2, row3: this.labelRow3, row4: this.labelRow4 };
            this.setAssetsLabelsDisplayedValues(jsonLabels);
        },

        // Called when user select the label to display for the second row
        onSelectAssetLabelSecondRow(e) {
            this.labelRow2 = $(e.target).data().labelvalue;
            this.sampleRow2 = this.getSampleByLabel(this.labelRow2);

            let jsonLabels = { row1: this.labelRow1, row2: this.labelRow2, row3: this.labelRow3, row4: this.labelRow4 };
            this.setAssetsLabelsDisplayedValues(jsonLabels);
        },

        // Called when user select the label to display for the third row
        onSelectAssetLabelThirdRow(e) {
            this.labelRow3 = $(e.target).data().labelvalue;
            this.sampleRow3 = this.getSampleByLabel(this.labelRow3);

            let jsonLabels = { row1: this.labelRow1, row2: this.labelRow2, row3: this.labelRow3, row4: this.labelRow4 };
            this.setAssetsLabelsDisplayedValues(jsonLabels);
        },

        // Called when user select the label to display for the fourth row
        onSelectAssetLabelFourthRow(e) {
            this.labelRow4 = $(e.target).data().labelvalue;
            this.sampleRow4 = this.getSampleByLabel(this.labelRow4);

            let jsonLabels = { row1: this.labelRow1, row2: this.labelRow2, row3: this.labelRow3, row4: this.labelRow4 };
            this.setAssetsLabelsDisplayedValues(jsonLabels);
        },

        getSampleByLabel(labelName) {
            let sample = "";
            switch (labelName) {
                case "MAP_ASSET_NAME":
                    sample = "Dr Moix";
                    break;
                case "MAP_CATEGORY":
                    sample = "Staff";
                    break;
                case "MAP_SUB_CATEGORY":
                    sample = "Nurse";
                    break;
                case "MAP_UNIT":
                    sample = "Maternity";
                    break;
                case "MAP_TAG_TYPE":
                    sample = "Asset Tag";
                    break;
                case "MAP_TAG_SERIAL_NUMBER":
                    sample = "uXgtfR";
                    break;
                case "MAP_TAG_BATTERY_STATUS":
                    sample = "Low";
                    break;
                default:
                    sample = "";
            }
            return sample;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.mapBrightnessSelector {
    width: 170px;
    margin-top: 2px;
    margin-left: 0px;
}
.assetLabelsPreview {
    position: absolute;
    width: 130px;
    height: 130px;
    background: url(/assets/search/assetlabelspreview.png) no-repeat scroll -15px 0px #464145;
    border-radius: 0;
    border: 0;
}
.marginT8 {
    margin-top: 8px;
}
.marginT10 {
    margin-top: 10px;
}
.assetLabelsPreview_listlabels {
    display: inline-grid;
    margin-left: 23px;
    margin-top: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.assetLabelsPreview_listlabels span {
    background: #ffffff;
    border: none;
    color: black;
    padding: 0px 5px 0px 5px;
    font-size: 10px;
    text-align: left;
    font-weight: 400;
}
.assetLabelsPreview_listlabels span:first-child {
    font-weight: 500;
    font-size: 11px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 1px solid #fff;
}
.assetLabelsPreview_listlabels span:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid #fff;
}
.assetsLabelOneElement {
    margin-top: 57px;
}
.assetsLabelTwoElements {
    margin-top: 33px;
}
.assetsLabelThreeElements {
    margin-top: 19px;
}
.assetsLabelFourthElements {
    margin-top: 6px;
}
.sideBarTriangle {
    content: "";
    position: absolute;
    left: -8px;
    top: 10px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid rgba(0, 0, 0, 0.7);
    clear: both;
}
</style>
